import promiseAllProperties from 'promise-all-properties';
import {
  getData,
} from '../../app/data';
import {dividend, formatValue} from '../../app/utils';
import moment from 'moment';

const getDataQualityData = (selectedFilters) => {
  const promises = {
    blankChannel: getBlankChannelPercentage(selectedFilters),
    unknownAge: getUnknownAgePercentage(selectedFilters),
    youngDonors: getYoungDonorsPercentage(selectedFilters),
    lowestGift: getLowestGiftAmount(selectedFilters),
    dataAsOf: getDataAsOfDate(selectedFilters),
    youngestDonorAge: getYoungestDonorAge(selectedFilters),
    highestGiftAmount: getHighestGiftAmount(selectedFilters),
    oldestDonorAge: getOldestDonorAge(selectedFilters),
    uniqueDonors: getUniqueDonorCount(selectedFilters),
    uniqueDonorsWithTrans: getUniqueDonorWithTransactionsCount(selectedFilters),
    uniqueCampaigns: getUniqueCampaignCount(selectedFilters),
    uniqueAppeals: getUniqueAppealCount(selectedFilters),
    uniqueChannels: getUniqueChannelCount(selectedFilters),
    uniqueSuppliers: getUniqueSupplierCount(selectedFilters),
    uniqueMasterGifts: getUniqueMasterGiftCount(selectedFilters),
  };
  return promiseAllProperties(promises);
};

// Data Quality Page
// Completeness - blank channel card
const getBlankChannelPercentage = async (selectedFilters = {}) => {
  let res = await getData('get_blank_channels', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  let result = 0;
  try {
    result = dividend(res.blankTransactions, res.numTransactions);
  } catch (Error) {
    result = 0;
  }
  return result;
};

// Completeness - unknown age card
const getUnknownAgePercentage = async (selectedFilters = {}) => {
  let res = await getData('get_unknown_age', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  let result = 0;
  try {
    result = dividend(res.blankDonors, res.numDonors);
  } catch (Error) {
    result = 0;
  }
  return result;
};

// Quality - donors under 21 card
const getYoungDonorsPercentage = async (selectedFilters = {}) => {
  let res = await getData('get_donors_under_21', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  let result = 0;
  try {
    result = dividend(res.donorsUnder21, res.numDonors);
  } catch (Error) {
    result = 0;
  }
  return formatValue(result.toString(), '##0.00%');
};

// Quality - data as of card
const getDataAsOfDate = async (selectedFilters = {}) => {
  let res = await getData('get_data_as_of', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return moment(res.latestDate).format('DD MMM YYYY');
};

// Minimum - lowest gift amount card
const getLowestGiftAmount = async (selectedFilters = {}) => {
  let res = await getData('get_lowest_gift', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.minGift;
};

// Minimum - youngest donor age card
const getYoungestDonorAge = async (selectedFilters = {}) => {
  let res = await getData('get_youngest_donor_age', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.minAge;
};

// Maximum - lowest gift amount card
const getHighestGiftAmount = async (selectedFilters = {}) => {
  let res = await getData('get_highest_gift', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.maxGift;
};


// Maximum - youngest donor age card
const getOldestDonorAge = async (selectedFilters = {}) => {
  let res = await getData('get_oldest_donor_age', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.maxAge;
};

// Uniqueness - donors card
const getUniqueDonorCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_donors', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

// Uniqueness - donors card
const getUniqueDonorWithTransactionsCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_donors_with_trans', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

// Uniqueness - donors card
const getUniqueMasterGiftCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_master_gifts', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

// Uniqueness - campaign card
const getUniqueCampaignCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_campaigns', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

// Uniqueness - appeal card
const getUniqueAppealCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_appeals', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

// Uniqueness - channel card
const getUniqueChannelCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_channels', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

// Uniqueness - supplier card
const getUniqueSupplierCount = async (selectedFilters = {}) => {
  let res = await getData('get_unique_suppliers', selectedFilters);
  if (Array.isArray(res)) {
    res = res[0];
  }
  if (res === null || res.length === 0) {
    return 'No data';
  }
  return res.count;
};

export default getDataQualityData;
