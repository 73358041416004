import React, {Component} from 'react';
import './PasswordStrengthMeter.css';
import zxcvbn from 'zxcvbn';
import {PropTypes} from 'prop-types';

/**
 * This component presents a password strength bar for the passed field. Uses
 * the zxcvbn library to determine the strength of the password.
 */
class PasswordStrengthMeter extends Component {
  /**
   * Decode the password assessment into a text score.
   * @param {Object} result The score of the password complexity
   * @return {String} The score as a noun.
   */
  createPasswordLabel(result) {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }
  /**
   * Used to pass the score assessed by the function back to the parent
   * component.
   * @param {Object} event The event triggering the function.
   */
  handleChange(event) {
    this.props.selectedValueHandler(event);
  }
  /**
   * The render function for the component, returns the password strength bar.
   * @return {JSX}
   */
  render() {
    const {password} = this.props;
    const testedResult = zxcvbn(password);
    this.handleChange(testedResult.score);
    return (
      <div className='password-strength-meter'>
        <progress
          className={`password-strength-meter-progress strength-${
            this.createPasswordLabel(testedResult)}`
          }
          value={testedResult.score}
          max='4'
          onChange={this.handleChange}
        />
        <br />
        <label className='password-strength-meter-label black-label'>
          {password && (
            <>
              <strong>Password strength:</strong>
              {this.createPasswordLabel(testedResult)}
            </>
          )}
        </label>
      </div>
    );
  }
}
PasswordStrengthMeter.propTypes = {
  password: PropTypes.string,
  selectedValueHandler: PropTypes.func,
};
export default PasswordStrengthMeter;

