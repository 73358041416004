import React, { useState, useEffect, useCallback } from 'react';
import { UncontrolledTooltip, Card, Button } from 'reactstrap';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar } from 'recharts';

import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import PropTypes from 'prop-types';
import ComparisonCard from '../../components/ComparisonCard';
import { getSelectedFilters, analyticsFilters } from '../../app/filters';
import getAnalyticsKPIData from './data';
import {
  formatValue,
  convertDateToMonthYearFull,
  downloadSpreadsheet,
  selectedFilters,
} from '../../app/utils';
import './AnalyticsKPI.css';
import { bindActionCreators } from 'redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from '../../redux/actions/filters.action';
import { connect } from 'react-redux';
import ContextBar from '../../components/V2/ContextBar/ContextBar';

/**
 * Analytics KPI Component
 * @param {Object} props - the passed props
 * @return {JSX}
 */
function AnalyticsKPI(props) {
  const { filterSelections, filterValues } = props;
  const [isLoading, setLoading] = useState(true);

  const base = {
    currentYear: null,
    vsPreviousYear: null,
    isComparison: false,
  };
  const [data, setData] = useState({
    activeDonors: { ...base },
    latestSignups: null,
    totalRevenue: { ...base },
    latestRevenue: null,
    retention: { ...base },
    latestRetention: null,
    averageSignups: { ...base },
    latestAverageSignups: null,
    averageGift: { ...base },
    latestAverageGift: null,
    averageDecline: { ...base },
    latestDecline: null,
    averageCPA: { ...base },
    ROI: { ...base },
    latestROI: null,
    latestAverageCPA: null,
    noFirstGift: { ...base },
    attrition: [],
    attritionYears: [{ attrition: null }, { attrition: null }],
  });

  const handleDownloadSpreadsheet = useCallback(() => {
    const attritionData = [
      {
        tabName: 'Average Attrition',
        tabData: data.attrition,
        header: ['transactionMonth'],
      },
    ];

    downloadSpreadsheet('Regular_Giving_KPIs', selectedFilters(filterSelections), attritionData);
  }, [data, filterSelections]);

  /**
   * Hook to load the initial data for the page and on filter change
   */
  useEffect(() => {
    setLoading(true);
    const filters = getSelectedFilters(filterSelections, filterValues, analyticsFilters);
    getAnalyticsKPIData(filters).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, [filterSelections, filterValues]);

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar />
      </header>
      <div className="main-content">
        <SecondaryBar />
        <ContextBar
          title={'Regular Giving KPIs'}
          parentLoading={isLoading}
          footer={"Key Performance Indicators of the organisation's Regular Giving."}
          shortcutFilters={[
            'channel',
            'campaign',
            'supplier',
            'gift-start-year',
            'transaction-month',
          ]}
          sidebarFilters={analyticsFilters}
        />
        <div className="content_section">
          <div className="page-content no-pad-top">
            <div className="top_content row1">
              <ComparisonCard
                componentSelectorName="kpi-card-total_donors"
                title="Active Donors"
                toolTipText="The total number of donors whose gifts are active and had at least 1 payment transaction in the relevant period."
                trend={data.activeDonors.vsPreviousYear}
                figure={formatValue(data.activeDonors.currentYear, '###,##0')}
                footerFigure={formatValue(Math.abs(data.activeDonors.vsPreviousYear), '0%')}
                defaultText={
                  data.latestSignups !== null
                    ? formatValue(data.latestSignups.donorCount, '###,##0') +
                      ' in ' +
                      convertDateToMonthYearFull(data.latestSignups.cohort)
                    : 'No Data'
                }
                isComparison={data.activeDonors.isComparison}
                isLoading={isLoading}
              />
              <ComparisonCard
                componentSelectorName="kpi-card-total_revenue"
                title="Total Lifetime Revenue"
                toolTipText="The total revenue from all donors across their entire donation history."
                trend={data.totalRevenue.vsPreviousYear}
                figure={formatValue(data.totalRevenue.currentYear, '$###,##0')}
                footerFigure={formatValue(Math.abs(data.totalRevenue.vsPreviousYear), '0%')}
                defaultText={
                  data.latestRevenue !== null
                    ? formatValue(data.latestRevenue.revenue, '$###,##0') +
                      ' from the ' +
                      convertDateToMonthYearFull(data.latestRevenue.cohort) +
                      ' Cohort'
                    : 'No Data'
                }
                isComparison={data.totalRevenue.isComparison}
                isLoading={isLoading}
              />
              <ComparisonCard
                componentSelectorName="kpi-card-overall_retention"
                title="Overall Retention"
                toolTipText="The percentage of your donors who are yet to cancel their regular giving. "
                trend={data.retention.vsPreviousYear}
                figure={formatValue(data.retention.currentYear, '#0.##%')}
                footerFigure={formatValue(Math.abs(data.retention.vsPreviousYear), '0%')}
                defaultText={
                  data.latestRetention !== null
                    ? formatValue(data.latestRetention.retention, '#0.##%') +
                      ' Retention for the ' +
                      convertDateToMonthYearFull(data.latestRetention.cohort) +
                      ' Cohort'
                    : 'No Data'
                }
                isComparison={data.retention.isComparison}
                isLoading={isLoading}
              />
              <ComparisonCard
                componentSelectorName="kpi-card-avg_signup_month"
                title="Signups Per Month by Gift Start Date"
                trend={data.averageSignups.vsPreviousYear}
                toolTipText="The average number of donors that their new regular giving subscriptions start per month over the relevant time period."
                figure={formatValue(data.averageSignups.currentYear, '###,##0.##')}
                footerFigure={formatValue(Math.abs(data.averageSignups.vsPreviousYear), '0%')}
                defaultText={
                  data.latestAverageSignups !== null
                    ? formatValue(data.latestAverageSignups.averageSignups, '###,##0.##') +
                      ' Signups Per Month in ' +
                      data.latestAverageSignups.year
                    : 'No Data'
                }
                isComparison={data.averageSignups.isComparison}
                isLoading={isLoading}
              />
              <ComparisonCard
                componentSelectorName="kpi-card-avg_gift_donor"
                title="Gift Per Donor"
                trend={data.averageGift.vsPreviousYear}
                figure={formatValue(data.averageGift.currentYear, '$###,##0.##')}
                footerFigure={formatValue(Math.abs(data.averageGift.vsPreviousYear), '0%')}
                toolTipText="The average single gift for all donors. This is measured on a per transaction basis for successful gifts only."
                defaultText={
                  data.latestAverageGift !== null
                    ? formatValue(data.latestAverageGift.averageGift, '$###,##0.##') +
                      ' Per Donor in the ' +
                      convertDateToMonthYearFull(data.latestAverageGift.cohort) +
                      ' Cohort'
                    : 'No Data'
                }
                isComparison={data.averageGift.isComparison}
                isLoading={isLoading}
              />
            </div>
            <div className="aa_section">
              <span>Average Attrition</span>
              <Button className={'download_spreadsheet'} onClick={handleDownloadSpreadsheet}>
                <i className="fas fa-download " />
                Download Spreadsheet
              </Button>
              <div className="top_content row1 aa_wrapper">
                <Card className="chart-section">
                  <div
                    data-cy="kpi-chart-attrition"
                    className="chart-container"
                    id="analytics-kpi-chart"
                  >
                    <ResponsiveContainer>
                      <BarChart
                        data={data.attrition}
                        margin={{
                          top: 50,
                          bottom: 50,
                          left: 50,
                          right: 50,
                        }}
                      >
                        <XAxis
                          dataKey="transactionMonth"
                          label={{
                            value: 'Transaction Month',
                            offset: -10,
                            position: 'insideBottom',
                          }}
                        />
                        <YAxis
                          tickFormatter={(value) => formatValue(value, '0%')}
                          label={{
                            value: 'Average Attrition %',
                            angle: -90,
                            position: 'insideLeft',
                          }}
                        />
                        <Tooltip formatter={(value) => formatValue(value, '#0.##%')} />
                        <Bar dataKey="attrition" name="Average Attrition" fill="#668586" />
                      </BarChart>
                    </ResponsiveContainer>
                    <UncontrolledTooltip
                      target="analytics-kpi-chart"
                      placement={'left'}
                      hideArrow={true}
                    >
                      This chart shows the average attrition rate for the entire donor body in the
                      given reporting month.
                    </UncontrolledTooltip>
                  </div>
                </Card>
                <div className="aa_rt_wrapper">
                  <ComparisonCard
                    componentSelectorName="kpi-card-avg_decline"
                    title="Average Decline"
                    trend={data.averageDecline.vsPreviousYear}
                    figure={formatValue(data.averageDecline.currentYear, '#0.##%')}
                    footerFigure={formatValue(Math.abs(data.averageDecline.vsPreviousYear), '0%')}
                    toolTipText="The average percentage of transactions that were declined."
                    defaultText={
                      data.latestDecline !== null
                        ? formatValue(data.latestDecline.averageDecline, '#0.##%') +
                          ' in ' +
                          convertDateToMonthYearFull(data.latestDecline.transactionMonth)
                        : 'No Data'
                    }
                    isComparison={data.averageDecline.isComparison}
                    reverseTrendColor
                    isLoading={isLoading}
                  />
                  <ComparisonCard
                    componentSelectorName="kpi-card-roi"
                    title="Return on Investment"
                    toolTipText="The ratio between revenue and expense. This data is only available when CPA is supplied."
                    trend={data.ROI.vsPreviousYear}
                    figure={formatValue(data.ROI.currentYear, '###,##0.##')}
                    footerFigure={formatValue(Math.abs(data.ROI.vsPreviousYear), '0%')}
                    defaultText={
                      data.latestROI !== null
                        ? formatValue(data.latestROI.roi, '###,##0.##') +
                          ' in ' +
                          convertDateToMonthYearFull(data.latestROI.transactionMonth)
                        : 'No Data'
                    }
                    isComparison={data.ROI.isComparison}
                    isLoading={isLoading}
                  />
                  <ComparisonCard
                    componentSelectorName="kpi-card-avg_cpa"
                    title="Average Cost Per Acquisition"
                    trend={data.averageCPA.vsPreviousYear}
                    figure={formatValue(data.averageCPA.currentYear, '$###,##0.##')}
                    toolTipText="The average cost of acquiring a single donor."
                    footerFigure={'This data is not yet available.'}
                    defaultText={
                      data.latestAverageCPA !== null
                        ? formatValue(data.latestAverageCPA.averageCPA, '###,##0.##') +
                          ' for the ' +
                          convertDateToMonthYearFull(data.latestAverageCPA.cohort) +
                          ' Cohort'
                        : 'No Data'
                    }
                    isComparison={data.averageCPA.isComparison}
                    reverseTrendColor
                    isLoading={isLoading}
                  />
                  <ComparisonCard
                    componentSelectorName="kpi-card-pre_debit_fail"
                    title="Pre-debit Fail"
                    trend={data.noFirstGift.vsPreviousYear}
                    figure={formatValue(data.noFirstGift.currentYear, '#0.##%')}
                    footerFigure={formatValue(Math.abs(data.noFirstGift.vsPreviousYear), '0%')}
                    defaultText={null}
                    toolTipText="The percentage of donors who never successfully make their first transaction."
                    isComparison={data.noFirstGift.isComparison}
                    reverseTrendColor
                    isLoading={isLoading}
                  />
                  <ComparisonCard
                    componentSelectorName="kpi-card-y1_attrition"
                    title="Year 1 Attrition"
                    figure={formatValue(data.attritionYears[0].attrition, '#0.##%')}
                    defaultText={null}
                    toolTipText="The percentage of donors who cancel their regular giving during their first year as a ratio of all donors."
                    isComparison={false}
                    isLoading={isLoading}
                  />
                  <ComparisonCard
                    componentSelectorName="kpi-card-y2_attrition"
                    title="Year 2 Attrition"
                    figure={formatValue(data.attritionYears[1].attrition, '#0.##%')}
                    toolTipText="The percentage of donors who cancel their regular giving during their second year as a ratio of all donors."
                    defaultText={null}
                    isComparison={false}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch,
  );
};

AnalyticsKPI.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsKPI);
