import React from 'react';
import { NavLink } from 'react-router-dom';
import CookieConsentBar from '../CookieConsentBar';

import './Footer.css';

/**
 * The Footer Bar, contains some basic masthead style information.
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
function Footer() {
  return (
    <div data-cy="footer" className="footer_container">
      <CookieConsentBar />
      <span>GivingInsight © NourishNFP {new Date().getFullYear()}</span>
      <ul className="footer_menu">
        <li>
          <NavLink data-cy="footer-link-home" to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink data-cy="footer-link-help" to="/help">
            Help
          </NavLink>
        </li>
        <li>
          <a data-cy="footer-link-email" href="mailto:info@givinginsight.com">
            Contact
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
