import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

/**
 * The Home Route
 * @param {Object} props - the passed the props to the component
 * @return {JSX}
 */
function NotFound() {
  const { pathname } = useLocation();
  // Navigate the user back to the correct URL if they
  // accidently navigate to one of the following URLs.
  if (pathname.startsWith('/forecast')) {
    return <Navigate to="/forecast/view" />;
  } else if (pathname.startsWith('/analytics')) {
    return <Navigate to="/analytics/kpi" />;
  }
  return <p>404 Not Found</p>;
}

export default NotFound;
