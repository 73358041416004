export const UPDATE_FILTER_SELECTIONS = 'UPDATE_FILTER_SELECTIONS';
export const UPDATE_FILTER_VALUES = 'UPDATE_FILTER_VALUES';

export function modifyFilterSelectionsAction(filterSelections) {
    return {
        type: UPDATE_FILTER_SELECTIONS,
        payload: {filterSelections},
    };
}

export function modifyFilterValuesAction(filterValues) {
    return {
        type: UPDATE_FILTER_VALUES,
        payload: {filterValues},
    };
}
