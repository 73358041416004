import promiseAllProperties from "promise-all-properties";
import { getData, withComparison } from "../../app/data";
import { formatValue } from "../../app/utils";
import moment from "moment";

const getBestDonorSegmentData = (selectedFilters) => {
  const promises = {
    bestAgeGroup: getBestAgeGroup({ ...selectedFilters }),
    bestGiftRange: getBestGiftRange({ ...selectedFilters }),
    averageAge: getAverageAge({ ...selectedFilters }),
    averageLTVPerDonor: getAverageLTVPerDonor({ ...selectedFilters }),
    averageGiftPerDonor: getAverageGiftPerDonor({ ...selectedFilters }),
    averageGiftDiff: getAverageGiftDiff({ ...selectedFilters }),
  };
  return promiseAllProperties(promises);
};

const getBestAgeGroup = (selectedFilters) => {
  return getData("get_best_age_group", selectedFilters).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res === undefined || res.length === 0) {
      return {
        age_range: "No Data",
        ltv: "No Data",
      };
    }
    return {
      age_range: res.age_range,
      ltv: formatValue(res.ltv, "$###,###.00"),
    };
  });
};

const getBestGiftRange = (selectedFilters) => {
  return getData("get_best_gift_range", selectedFilters).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res === undefined || res.length === 0) {
      return {
        gift_range: "No Data",
        ltv: "No Data",
      };
    }
    return {
      gift_range: res.gift_range,
      ltv: formatValue(res.ltv, "$###,###.00"),
    };
  });
};

const getAverageLTVPerDonor = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res === undefined || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item["Average LTV Per Donor $"] = item.avgLTVPerDonor;
      item.transactionMonth = moment(item.transactionMonth).format("MMM YYYY");
    });
    return res;
  };
  return withComparison(
    "get_average_donor_ltv_by_month",
    selectedFilters,
    computeFn
  );
};

const getAverageGiftPerDonor = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res === undefined || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item["Average Gift Per Donor $"] = item.averageGift;
      item.transactionMonth = moment(item.transactionMonth).format("MMM YYYY");
    });
    return res;
  };
  return withComparison(
    "get_average_gift?by-transaction",
    selectedFilters,
    computeFn
  );
};

const getAverageAge = (selectedFilters) => {
  return getData("get_average_gift", selectedFilters).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (
      res === null ||
      res === undefined ||
      res.length === 0 ||
      res["avgAge"] === null ||
      !("avgAge" in res)
    ) {
      return "No Data";
    }
    return formatValue(res["avgAge"], "#0");
  });
};

const getAverageGiftDiff = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res === undefined || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item["Average Upgrade/Downgrade $"] = item.avgGiftAmountDiff;
      item.transactionMonth = moment(item.transactionMonth).format("MMM YYYY");
    });
    return res;
  };
  return withComparison(
    "get_average_gift_diff_by_month",
    selectedFilters,
    computeFn
  );
};

export default getBestDonorSegmentData;
