import {INITIAL_FILTER_SELECTION_STATE, INITIAL_FILTER_VALUE_STATE} from "./redux/reducers/filters.reducer";

export const loadState = () => {
/*  try {
    const serializedState = localStorage.getItem('State');
    if (serializedState === null) {*/
      const init = {
        filterValues: INITIAL_FILTER_VALUE_STATE,
        filterSelections: INITIAL_FILTER_SELECTION_STATE,
      };
/*      const serializedState = JSON.stringify(init);
      localStorage.setItem('State', serializedState);*/
      return init
/*    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }*/
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('State', serializedState);
  } catch (err) {
    // ignore write errors
  }
};
