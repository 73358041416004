// Library Imports
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// Component Imports
import PrimaryBar from '../../components/PrimaryBar';
import Footer from '../../components/Footer';
import HomeCard from '../../components/HomeCard';
import DataCard from '../../components/DataCard';
import { getClientName } from '../../app/auth';
// CSS Imports
import './Home.css';
// Resource imports
import homeCardImage1 from '../../assets/images/HomeCard_first.png';
import homeCardImage2 from '../../assets/images/HomeCard_second.png';
import activeDonors from '../../assets/images/active_donor.svg';
import present from '../../assets/images/average_gift.svg';
import moneyBag from '../../assets/images/lifetime_revenue.svg';
import calendar from '../../assets/images/best_month.svg';
import getHomeData from './data';
import { formatValue } from '../../app/utils';
import { Spinner } from 'reactstrap';

/**
 * The Home Route
 * @return {JSX}
 */
function Home() {
  const [data, setData] = useState({
    activeDonors: '...',
    activeDonorsLm: '...',
    avgGift: { averageGift: '...', minGift: '...', maxGift: '...' },
    revenue: { total: '...', lm: '...', maxMonthLabel: '...' },
    forecastProfit: '...',
  });
  const [isLoading, setLoading] = useState(false);

  /**
   * Fetches Data from required data endpoints and updates the data state
   */
  useEffect(() => {
    setLoading(true);
    getHomeData({}).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, []);

  // Loading icon whilst the endpoints are running
  let loadingDiv = '';
  if (isLoading) {
    loadingDiv = <Spinner color="primary" />;
  }

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar selected="Home" />
      </header>
      <div className="main-content">
        <div className="vi_section">
          <h2>
            <strong>{getClientName()}</strong> at a glance
            {loadingDiv}
          </h2>
          <div className="vi_part">
            <DataCard
              componentSelectorName="home-overview-card-donor"
              image={activeDonors}
              imageAlt={'Female Donor'}
              title={'Active Donors'}
              figure={data.activeDonors}
              footerContext={data.activeDonorsLm}
              footerLink={`/analytics/kpi`}
            />
            <DataCard
              componentSelectorName="home-overview-card-average_gift"
              image={present}
              imageAlt={'Present'}
              title={'Average Gift'}
              figure={
                data.avgGift === 'No Data'
                  ? 'No Data'
                  : formatValue(data.avgGift.averageGift, '$0.00a')
              }
              footerContext={
                '$' + data.avgGift.minGift + ' Lowest - $' + data.avgGift.maxGift + ' Highest'
              }
              footerLink={`/analytics/kpi`}
            />
            <DataCard
              componentSelectorName="home-overview-card-revenue"
              image={moneyBag}
              imageAlt={'Bag filled with money'}
              title={'Lifetime Revenue'}
              figure={data.revenue.total}
              footerContext={data.revenue.lm}
              footerLink={`/analytics/kpi`}
            />
            <DataCard
              componentSelectorName="home-overview-card-best_month"
              image={calendar}
              imageAlt={'Calendar with Date'}
              imageText={data.revenue.maxMonthLabel}
              title={'Best Month'}
              figure={data.revenue.maxMonthLabel}
              footerContext={data.revenue.maxMonthValue}
              footerLink={`/analytics/kpi`}
            />
          </div>
          <div className="vibtn_wrap">
            <NavLink
              data-cy="home-overview-button-analytics"
              to={`/analytics/kpi`}
              className="self_btn lblue_btns vi_btn"
            >
              See all metrics
            </NavLink>
          </div>
        </div>
        <div className="sp4_box">
          <HomeCard
            componentSelectorName="home-shortcuts-card-analytics"
            buttonSelectorName="home-shortcuts-button-analytics"
            iconName={'fas fa-chart-bar'}
            title={'ANALYTICS'}
            bodyContent={'Track your KPIs, measure performance, \n' + 'check data quality and more'}
            bodyContentFigure={''}
            subText={''}
            buttonText={'See all metrics'}
            buttonLinkPath={`/analytics/kpi`}
            image={homeCardImage1}
            imageAltText={'Home Screen background image'}
          />
          <HomeCard
            componentSelectorName="home-shortcuts-card-help"
            buttonSelectorName="home-shortcuts-button-help"
            iconName={'fas fa-life-ring'}
            title={'HELP'}
            bodyContent={'View FAQs, watch training videos\n' + 'or search our knowledge base. '}
            bodyContentFigure={''}
            subText={''}
            buttonText={'Learn Now'}
            buttonLinkPath={'/help'}
            image={homeCardImage2}
            imageAltText={'Man working on service desk'}
          />
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
