import React, { useState } from "react";
import PropTypes from "prop-types";

import "./ComparisonCard.css";
import { Card, CardHeader, UncontrolledTooltip } from "reactstrap";
import { newId } from "../../app/utils";

/**
 * Comparison Card Component
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
function ComparisonCard(props) {
  const [id] = useState(newId());
  const {
    componentSelectorName,
    title,
    figure,
    footerFigure,
    trend,
    defaultText,
    reverseTrendColor,
    isLoading,
    isComparison,
  } = props;
  const direction = trend >= 0 ? "Up" : "Down";
  const icon = trend >= 0 ? "up" : "down";
  // xor
  let negative =
    (trend >= 0 && !reverseTrendColor) || (trend <= 0 && reverseTrendColor)
      ? ""
      : " negative_view";
  if (
    (figure === null && !isLoading) ||
    defaultText === null ||
    !isComparison
  ) {
    negative = " no_data_view";
  }
  // Generate a unique ID for this tooltip based on a random string.
  return (
    <div data-cy={componentSelectorName} className="kpi_container_" id={id}>
      <Card className="kpi_container_content">
        <CardHeader>{title}</CardHeader>
        <div className="kpi_container_part">
          <div className={`kpi_text${negative}`}>
            {figure === null && isLoading && <p>...</p>}
            {figure === null && !isLoading && <p>No Data</p>}
            {figure !== null && <p className={"figure-text"}>{figure}</p>}
          </div>
        </div>
        {isComparison && (
          <div className={`kpi_bottom_part${negative}`}>
            <i className={`fas fa-caret-${icon}`} /> {direction} {footerFigure}{" "}
            vs Last Year
          </div>
        )}
        {figure !== null && !isComparison && defaultText !== null && (
          <div className={`kpi_bottom_part default`}>{defaultText}</div>
        )}
        {figure === null && !isComparison && (
          <div className={`kpi_bottom_part no_data_view`}>
            <i className={`fas fa-caret-${icon}`} /> {direction} {footerFigure}{" "}
            vs Last Year
          </div>
        )}
        {figure !== null && defaultText === null && (
          <div className="kpi_bottom_part no_data_view">
            <i className={`fas fa-caret-${icon}`} />
          </div>
        )}
      </Card>
      {props.toolTipText !== null &&
        props.toolTipText !== "" &&
        props.toolTipText !== undefined && (
          <UncontrolledTooltip placement={"right"} target={id} hideArrow={true}>
            {props.toolTipText}
          </UncontrolledTooltip>
        )}
    </div>
  );
}

ComparisonCard.propTypes = {
  componentSelectorName: PropTypes.string,
  id: PropTypes.string,
  toolTipText: PropTypes.string,
  title: PropTypes.string,
  figure: PropTypes.string,
  footerFigure: PropTypes.string,
  trend: PropTypes.number,
  defaultText: PropTypes.string,
  isLoading: PropTypes.bool,
  isComparison: PropTypes.bool,
  reverseTrendColor: PropTypes.bool,
};

export default ComparisonCard;
