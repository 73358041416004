import React, { useState } from "react";
import PropTypes from "prop-types";

import "./InformationCard.css";
import { Card, UncontrolledTooltip } from "reactstrap";
import { newId } from "../../app/utils";

/**
 * Data Quality Card, a relatively simple component with just a title and a key
 * metric.
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
function InformationCard(props) {
  // Set the ID of the card once through a state hook, this will stop it from
  // changing between partial re-renders.
  const [id] = useState(newId());
  const { title, measure, toolTipText, componentSelectorName } = props;
  return (
    <span data-cy={componentSelectorName}>
      <Card className="dataq_content" id={id}>
        <h5>{title}</h5>
        <h4>{measure}</h4>
      </Card>
      {toolTipText !== null && toolTipText !== undefined && toolTipText !== "" && (
        <UncontrolledTooltip flip={true} target={id} hideArrow={true}>
          {toolTipText}
        </UncontrolledTooltip>
      )}
    </span>
  );
}

InformationCard.propTypes = {
  toolTipText: PropTypes.string,
  title: PropTypes.string,
  measure: PropTypes.string,
  componentSelectorName: PropTypes.string,
};

export default InformationCard;
