import promiseAllProperties from 'promise-all-properties';
import { getData } from '../../app/data';

const getMembersData = () => {
  const promises = {
    users: getAllUsers(),
    userCount: getUserCount(),
  };
  return promiseAllProperties(promises);
};

const getAllUsers = () => {
  return getData('auth/list_users', {}).then((res) => {
    const rtn = [];
    if (res === null || res.length === 0) {
      return rtn;
    }
    return res.users;
  });
};

const getUserCount = () => {
  return getData('auth/list_users', {}).then((res) => {
    if (res === null || res.length === 0) {
      return 0;
    }
    let rtn = 0;
    // eslint-disable-next-line no-unused-vars
    res.users.forEach(function (element) {
      rtn += 1;
    });
    return rtn;
  });
};

export default getMembersData;
