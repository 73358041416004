import promiseAllProperties from "promise-all-properties";
import { getData } from "../../app/data";
import moment from "moment";
import { formatValue } from "../../app/utils";

const getBaselineForecastResults = (filters, forecastVersionId) => {
  const promises = {
    donor: getForecastDonors(filters, forecastVersionId),
    revenue: getForecastRevenueByMonth(filters, forecastVersionId),
  };
  return promiseAllProperties(promises);
};

/*
  getForecastDonors fetches the predicted remaining donors baseline data from the backend, parses the response data and
  return as the object of 2 arrays:
  - chartData is the array data to display in the area chart
  - tableData is the array data to display in the table
 */
const getForecastDonors = async (selectedFilters = {}, versionId) => {
  return getData("forecast/view/remaining-donors", {
    ...selectedFilters,
    "forecast-version-id": [versionId],
  }).then((res) => {
    const results = {
      chartData: [],
      tableData: [],
    };

    // Parsing remaining donors in chart format
    const tmpChartData = new Map();
    const rtnChartData = [];
    if (res === null || res.length === 0) {
      return results;
    }
    res.forEach(function (element) {
      let value = 0;
      if (tmpChartData.has(element.reporting_date)) {
        value = tmpChartData.get(element.reporting_date);
      }
      tmpChartData.set(
        element.reporting_date,
        value + element.remaining_donors
      );
    });
    tmpChartData.forEach(function (element, key) {
      const remainingDonors = tmpChartData.get(key);
      const reportingMonth = moment(key).format("MMM YYYY");
      rtnChartData.push({
        "Reporting Date": reportingMonth,
        Donors: remainingDonors,
      });
    });
    results.chartData = rtnChartData;

    // Parsing remaining donors in table format
    let rtnTableData = [];
    const body = [];
    const header = [{ value: "Cohort/Future Months", readOnly: true }];
    const headerMap = new Map();
    const maxColumns = 24;
    let currentCohort = null;
    let currentRow = [];
    let maxRowLength = -1;
    let firstCohort = null;
    res.forEach(function (row) {
      if (firstCohort === null) {
        firstCohort = row.cohort_start_date;
      }
      if (row.cohort_start_date !== currentCohort) {
        if (currentRow.length > 0) {
          body.push(currentRow.slice(0, maxColumns));
          // Store the maximum length of the row, so that
          // we can determine how many empty cells all the
          // other rows need to be padded by, if their
          // length is less than this value.
          maxRowLength = Math.max(
            maxRowLength,
            Math.min(maxColumns, currentRow.length)
          );
        }
        currentRow = [
          {
            value: moment(row.cohort_start_date).format("MMM YY"),
            readOnly: true,
          },
        ];
        currentCohort = row.cohort_start_date;
      }
      const period = moment(row.reporting_date).format("MMM YY");
      if (!headerMap.has(period)) {
        header.push({ value: period.toString(), readOnly: true });
        headerMap.set(period, true);
      }
      currentRow.push({
        value:
          formatValue(row.remaining_donors, "###,##0") === "NaN"
            ? "0"
            : formatValue(row.remaining_donors, "###,##0"),
        readOnly: true,
      });
    });
    // Add the final row to the body
    body.push(currentRow.slice(0, maxColumns));
    // Account for the last row having the greatest amount of cells.
    // This will probably never happen, but just in case.
    maxRowLength = Math.max(
      maxRowLength,
      Math.min(maxColumns, currentRow.length)
    );

    // Pad the table with empty cells
    for (const row of body) {
      const emptyCells = maxRowLength - row.length;
      for (let i = 0; i < emptyCells; i++) {
        row.push({
          value: null,
          readOnly: true,
        });
      }
    }

    rtnTableData.push(header.slice(0, maxColumns));
    rtnTableData = rtnTableData.concat(body);
    results.tableData = rtnTableData;

    // return parsed data
    return results;
  });
};

const getForecastRevenueByMonth = (selectedFilters = {}, versionId) => {
  return getData("forecast/view/revenue?by-month", {
    ...selectedFilters,
    "forecast-version-id": [versionId],
  }).then((res) => {
    const rtn = [];
    if (res === null || res.length === 0) {
      return rtn;
    }
    res.forEach(function (element) {
      const data = {
        "Reporting Date": moment(element.reporting_date).format("MMM YYYY"),
        Revenue: element.revenue,
      };
      rtn.push(data);
    });
    return rtn;
  });
};

export default getBaselineForecastResults;
