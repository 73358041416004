import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import { API_ENDPOINT } from '../../app/config';
import { getBearerToken } from '../../app/auth';

import './ImportData.css';
import Loading from '../../components/V2/Loading';

const ImportData = () => {
  const [files, setFiles] = useState([
    { name: 'file_donor', file: '' },
    { name: 'file_gift', file: '' },
    { name: 'file_transaction', file: '' },
  ]);
  const [loading, setLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState('');

  const handleDonorFileChange = (event) => {
    files[0].file = event.target.files[0];
    setFiles(files);
  };
  const handleGiftFileChange = (event) => {
    files[1].file = event.target.files[0];
    setFiles(files);
  };
  const handleTransactionFileChange = (event) => {
    files[2].file = event.target.files[0];
    setFiles(files);
  };

  const submitFiles = (event) => {
    event.preventDefault();
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i].file);
    }

    setUploadResult('Uploading files...');
    setLoading(true);
    fetch(API_ENDPOINT + '/thankq/upload', {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getBearerToken()}`,
      }),
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (true === result.success) {
          setUploadResult('Upload successful!');
        } else {
          setUploadResult(JSON.stringify(result));
        }
      })
      .catch((error) => {
        setLoading(false);
        setUploadResult(error);
      });
  };

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar />
      </header>
      <div className="main-content">
        <SecondaryBar />
        <Loading show={loading} />
        <div className="main-content">
          <div className="import-data">
            <div className="import-form-section">
              <div className="importdata_container">
                <h2>Import Data</h2>
                <Form
                  encType="multipart/form-data"
                  onSubmit={submitFiles}
                  className="container-form-group"
                >
                  <div className="import-form-wrapper">
                    <div className="input-left-form-g">
                      <div className="import-left_form">
                        <p>
                          These inputs allow you to upload files exported from ThankQ to
                          GivingInsight.
                        </p>
                        <p>First, select files on the right then click Upload Files.</p>
                      </div>
                      <div>
                        <Button className="self_btn yellow_btn" type="submit">
                          Upload Files
                        </Button>
                      </div>
                    </div>
                    <div className="w-import-right_form">
                      <div className="import-right_form">
                        <FormGroup>
                          <Label for="file_donor" className={'forecast_label'}>
                            Donor File
                          </Label>
                          <Input
                            type="file"
                            name="file_donor"
                            id="file_donor"
                            label=" Choose a file."
                            onChange={handleDonorFileChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="file_gift" className={'forecast_label'}>
                            Recurring Gift File
                          </Label>
                          <Input
                            type="file"
                            name="file_gift"
                            id="file_gift"
                            label=" Choose a file."
                            onChange={handleGiftFileChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="file_transaction" className={'forecast_label'}>
                            Transaction File
                          </Label>
                          <Input
                            type="file"
                            name="file_transaction"
                            id="file_transaction"
                            label=" Choose a file."
                            onChange={handleTransactionFileChange}
                            required
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="etl-section">
              <div className={'etl-container'}>
                {uploadResult !== '' && (
                  <div className={'etl-component'}>
                    <h4>Upload result</h4>
                    <Input
                      type=" textarea"
                      name=" upload_result"
                      id=" upload_result"
                      value={uploadResult}
                      disabled
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer>
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ImportData;
