import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Spinner,
  Collapse,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  ButtonGroup,
  Button,
  Badge,
} from 'reactstrap';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import ToggleButton from '../../components/ToggleButton';
import { getData } from '../../app/data';
import getForecastResults from './data';
import {
  downloadSpreadsheet,
  formatValue,
  getColour,
  renameKeys,
  selectedFilters,
} from '../../app/utils';
import { forecastRecordFilters, getSelectedFilters } from '../../app/filters';
import queryString from 'query-string';
import calendar from '../../assets/images/best_month_white.svg';
import './ForecastResults.css';
import ReactDataSheet from 'react-datasheet';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from '../../redux/actions/filters.action';
import { connect } from 'react-redux';
import ContextBar from '../../components/V2/ContextBar/ContextBar';
import { useLocation } from 'react-router';

/**
 * Forecast Results Component
 * @param {Object} props the properties for the component, namely the ID.
 * @return {JSX}
 */

const getNewKeyNames = (forecastVersions) => {
  let newKeyNames = {};
  Object.keys(forecastVersions).forEach((version) => {
    newKeyNames[forecastVersions[version].forecast_version_id] =
      forecastVersions[version].version_name;
  });

  return newKeyNames;
};

const ForecastResults = (props) => {
  const { filterSelections, filterValues } = props;
  // Get the Forecast Version from the path properties
  const location = useLocation();
  const params = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const [versions] = useState(params.forecast_version_id);
  /*{
      'forecast-version-id': versions,
    }*/
  // Set the data hook
  const [data, setData] = useState({
    data: [],
    revenue: '...',
    expense: '...',
    breakEven: {
      month: '...',
      monthName: '...',
      year: '...',
      number: '...',
    },
    donors: [],
    forecastVersions: {},
  });
  const [isLoading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [profitChart, setProfitChart] = useState({ data: [], accumulate: false });
  const [redirectNew, setRedirectNew] = useState(false);
  const [compare, setCompare] = useState(false);
  const [collapsed, setCollapsed] = useState(versions.length > 1);

  /**
   * Toggle the profit chart selection by switching the data and buttons
   * around.
   */
  function toggleProfitChart() {
    // Conditionally set the data for the attrition chart
    if (profitChart.accumulate) {
      setProfitChart({ data: data.profit, accumulate: false });
    } else {
      setProfitChart({ data: data.profitAcc, accumulate: true });
    }
  }

  /**
   * Fetches Data from required data endpoints and returns them to the screen
   * through a hook.
   */
  useEffect(() => {
    setLoading(true);
    const filters = getSelectedFilters(filterSelections, filterValues, forecastRecordFilters);
    getForecastResults(filters, versions, compare).then((response) => {
      setData(response);
      // Set the loading animation state to false
      setLoading(false);
    });
  }, [filterSelections, filterValues, versions, compare]);

  useEffect(() => {
    // Conditionally set the data for the attrition chart
    if (profitChart.accumulate) {
      setProfitChart({ data: data.profitAcc, accumulate: true });
    } else {
      setProfitChart({ data: data.profit, accumulate: false });
    }
  }, [data, profitChart.accumulate]);

  // Loading Div for the download button
  let downloadLoadingDiv = '';
  if (downloadLoading) {
    downloadLoadingDiv = <Spinner color="primary" />;
  }

  const forecastSelectedFilter = useCallback(() => {
    // remove the Last 12 months filter selection
    let modifiedFilters = selectedFilters(filterSelections).filter((f) =>
      forecastRecordFilters.includes(f.Filter),
    );
    const versions = Object.keys(data.forecastVersions);

    versions.forEach((version, index) => {
      // add selected forecast filter
      modifiedFilters.push({
        Filter: `selected-forecasts-name(${index + 1})`,
        Selection: data.forecastVersions[version].version_name,
      });
      modifiedFilters.push({
        Filter: `selected-forecasts-created(${index + 1})`,
        Selection: moment(data.forecastVersions[version].created_date).format('YYYY-DD-MM HH:MM'),
      });
      modifiedFilters.push({
        Filter: `selected-forecasts-channel(${index + 1})`,
        Selection: data.forecastVersions[version].forecast_channel,
      });
      modifiedFilters.push({
        Filter: `selected-forecasts-supplier(${index + 1})`,
        Selection: data.forecastVersions[version].forecast_supplier,
      });
    });

    return modifiedFilters;
  }, [filterSelections, data]);

  const handleDownloadSpreadsheet = useCallback(() => {
    const newKeyNames = getNewKeyNames(data.forecastVersions);
    const chartData = [
      {
        tabName: 'Accumulated',
        tabData: data.profitAcc.map((i) => renameKeys(newKeyNames, i)),
        header: ['Reporting Date'],
      },
      {
        tabName: 'Month on Month',
        tabData: data.profit.map((i) => renameKeys(newKeyNames, i)),
        header: ['Reporting Date'],
      },
    ];

    downloadSpreadsheet('Forecast_Result', forecastSelectedFilter(), chartData);
  }, [filterSelections, data]);

  /**
   * The download function for when the download button is pressed.
   * @return {Promise<void>}
   */
  async function getCSV() {
    // Set loading to true
    setDownloadLoading(true);
    const csv = await getData('forecast/export' + location.search);
    if (csv !== null) {
      window.open(csv.url, '_self');
    }
    setDownloadLoading(false);
  }

  /**
   * Redirect the user to the new forecast page.
   */
  function handleNewForecast() {
    setRedirectNew(true);
  }

  if (redirectNew) {
    return <Navigate replace to={'/forecast/new'} />;
  }

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar />
      </header>
      <div className="main-content">
        <SecondaryBar />
        <ContextBar
          title={'Forecasts Results'}
          parentLoading={isLoading}
          footer={'View the results of forecast.'}
          context={{ 'forecast-version-id': versions }}
          shortcutFilters={forecastRecordFilters}
          sidebarFilters={forecastRecordFilters}
        />
        <div className="content_section">
          <div className="page-content no-pad-top">
            <div className="no-pad-top">
              <div className="page-content no-pad-top">
                <div className="new_toop">
                  <Card>
                    <CardHeader className="selected-forecasts-header">
                      <p>Selected Forecasts</p>
                      <Badge className="forecast-badge">{`${versions.length}`}</Badge>
                      <ButtonGroup className="selected-forecast-group">
                        <Button
                          outline
                          color="secondary"
                          size="sm"
                          onClick={() => setCollapsed(!collapsed)}
                        >
                          View
                        </Button>
                      </ButtonGroup>
                    </CardHeader>
                    <Collapse isOpen={!collapsed}>
                      <ListGroup>
                        {Object.keys(data.forecastVersions).map((versionId) => (
                          <ListGroupItem className="results-list-group" key={versionId}>
                            <div className="results-list-row">
                              <b>Name:</b>
                              <p>{data.forecastVersions[versionId].version_name}</p>
                            </div>
                            <div className="results-list-row">
                              <b>Created:</b>
                              <p>
                                {moment(data.forecastVersions[versionId].created_date).format(
                                  'YYYY-DD-MM HH:MM',
                                )}
                              </p>
                            </div>
                            <div className="results-list-row">
                              <b>Channel:</b>
                              <p>{data.forecastVersions[versionId].forecast_channel}</p>
                            </div>
                            <div className="results-list-row">
                              <b>Supplier:</b>
                              <p>{data.forecastVersions[versionId].forecast_supplier}</p>
                            </div>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Collapse>
                  </Card>
                </div>
                <div className="newforecast_content">
                  <div className="newf_container">
                    <h3>Forecast Profit</h3>
                    <div className="forecast-results-btns graph_btns">
                      <ToggleButton
                        text={'Accumulate'}
                        toolTipText={'Show the running profit of this forecast over time.'}
                        active={profitChart.accumulate}
                        onClick={toggleProfitChart}
                      />
                      <ToggleButton
                        text={'Month on Month'}
                        toolTipText={'Show the profit of this forecast on a month-to-month basis.'}
                        active={!profitChart.accumulate}
                        onClick={toggleProfitChart}
                      />
                      {versions.length > 1 && (
                        <ToggleButton
                          text={'Compare'}
                          active={compare}
                          onClick={() => setCompare(!compare)}
                        />
                      )}
                      <Button
                        className={'download_spreadsheet'}
                        onClick={handleDownloadSpreadsheet}
                      >
                        <i className="fas fa-download " />
                        Download Spreadsheet
                      </Button>
                    </div>
                    <div className="newf_row">
                      <Card className="newfgraph_section">
                        <ResponsiveContainer>
                          <LineChart
                            data={profitChart.data}
                            margin={{
                              top: 30,
                              bottom: 30,
                              left: 35,
                              right: 35,
                            }}
                          >
                            <XAxis
                              dataKey="Reporting Date"
                              label={{
                                value: 'Reporting Month',
                                position: 'insideBottom',
                                offset: -10,
                              }}
                            />
                            <YAxis
                              tickFormatter={(v) => formatValue(v, '$0a')}
                              label={{
                                value: 'Profit',
                                angle: -90,
                                offset: -20,
                                position: 'insideLeft',
                              }}
                            />
                            <Tooltip
                              formatter={(v, key) => {
                                if (compare) {
                                  const version = data.forecastVersions[key];
                                  const date = moment(version.created_date).format('YYYY-MM-DD');

                                  const value = formatValue(v, '$###,##0.00');
                                  const name = `${version.version_name} (${date})`;

                                  return [value, name];
                                } else {
                                  return [v, key];
                                }
                              }}
                            />
                            {Object.keys(data.forecastVersions).length > 0 && compare && (
                              <Legend
                                payload={versions.map((key, index) => {
                                  const version = data.forecastVersions[key];
                                  const date = moment(version.created_date).format('YYYY-MM-DD');
                                  return {
                                    value: `${version.version_name} (${date})`,
                                    type: 'line',
                                    color: getColour(index),
                                  };
                                })}
                                height={50}
                                verticalAlign="top"
                              />
                            )}
                            {!compare ? (
                              <Line
                                type="monotone"
                                dataKey="Profit"
                                dot={false}
                                stroke={getColour(0)}
                              />
                            ) : (
                              Object.keys(data.forecastVersions).length > 0 &&
                              versions.map((v, i) => (
                                <Line
                                  type="monotone"
                                  dataKey={v}
                                  dot={false}
                                  stroke={getColour(i)}
                                  key={v}
                                />
                              ))
                            )}
                          </LineChart>
                        </ResponsiveContainer>
                      </Card>
                      <Card className="newfgraphb_section">
                        <div className="totalp_section">
                          <div className="totalp_top">
                            <h4>
                              <span>
                                {data.revenue}
                                <br />
                                Total Revenue
                              </span>
                            </h4>
                          </div>
                          <Card className="p_red_sec">
                            <h3>
                              <span>{data.expense}</span>
                              <br /> expenses
                            </h3>
                          </Card>
                          <div
                            className={
                              'totalp_bot' + (data.breakEven.number === null ? ' empty' : '')
                            }
                          >
                            <img src={calendar} alt="Calendar" />
                            <span>
                              {data.breakEven.number !== null && (
                                <>
                                  {data.breakEven.month} <br />
                                  {data.breakEven.year}
                                </>
                              )}
                              {data.breakEven.number === null && <>!</>}
                            </span>
                            <h3>
                              {data.breakEven.number !== null && (
                                <>
                                  You will break even
                                  <br />
                                  in{' '}
                                  <strong>
                                    {data.breakEven.monthName} {data.breakEven.year}
                                  </strong>
                                </>
                              )}
                              {data.breakEven.number === null && (
                                <>You won&apos;t break even, revise your campaign!</>
                              )}
                            </h3>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="remain_donor_section">
                      <div className="new_toop">
                        <h3>Remaining donors by Cohort & Period</h3>
                      </div>
                      <div className="donor_table_content remain_donor">
                        <ReactDataSheet data={data.donors} valueRenderer={(cell) => cell.value} />
                      </div>
                      <div className="download_btns">
                        <button className="self_btn darkblue_btn" onClick={getCSV}>
                          <i className="fas fa-download" />
                          Export data to csv
                        </button>
                        {downloadLoadingDiv}
                        <button className="self_btn lblue_btn" onClick={handleNewForecast}>
                          Run a new forecast
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch,
  );
};

ForecastResults.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(ForecastResults);
