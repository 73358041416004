import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Card } from "reactstrap";

import "./DataCard.css";

/**
 * A Data Card  component containing a header, icon, figure and a footer context
 * description. These are primarily used on the homepage.
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
class DataCard extends React.Component {
  /**
   * The render function for the component, returns the Data Card for the given
   * properties.
   * @return {JSX}
   */
  render() {
    // Extract state variables for rendering.
    const {
      componentSelectorName,
      image,
      imageAlt,
      imageText,
      title,
      figure,
      footerContext,
      footerLink,
    } = this.props;
    // Determine if we need an image text, this is generally used for calenders
    let imageTag;
    if (imageText !== undefined) {
      imageTag = (
        <div>
          <img src={image} alt={imageAlt} />
          <span>{imageText}</span>
        </div>
      );
    } else {
      imageTag = <img src={image} alt={imageAlt} />;
    }
    return (
      <Card data-cy={componentSelectorName} className="vi_start">
        <div className="vieach_box">
          <div className="vi_content">
            <figure className="vi_logo">{imageTag}</figure>
            <div className="vi_ltext">
              <h3>{title}</h3>
              <h2>{figure}</h2>
            </div>
          </div>
          <NavLink to={footerLink} className="vi_botcontent">
            {footerContext}
          </NavLink>
        </div>
      </Card>
    );
  }
}

/**
 * Define the types of each property passed to the component.
 */
DataCard.propTypes = {
  componentSelectorName: PropTypes.string,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  figure: PropTypes.string,
  imageText: PropTypes.string,
  footerContext: PropTypes.string,
  footerLink: PropTypes.string,
};
export default DataCard;
