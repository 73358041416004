import React, { Component } from "react";
import PrimaryBar from "../../components/PrimaryBar";
import Footer from "../../components/Footer";

import searchImage from "../../assets/images/icon_search.svg";

import "./Help.css";
import { getHelpData, getHelpTypeData } from "./data";
import Select from "react-select";
import HelpContent from "../../components/HelpContent";
/**
 * Help Component
 */
class Help extends Component {
  /**
   * Define the types of each property passed to the component.
   */
  static propTypes() {}
  /**
   * Default constructor for the component, calls the super component as well
   * as any required other functions or state setup.
   * @param {Object} props the data for the navigation element
   * @props {JSX}
   */
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      helpTypes: [],
      selectedHelpTypes: [],
      helpContent: [],
    };
  }
  /**
   * Called immediately after the component mounts. Fetches the default
   * values for the select options.
   * @return {void}
   */
  async componentDidMount() {
    this.updateData();
  }
  /**
   * Updates the state data of the page by calling the back end APIs
   * @return {void}
   */
  async updateData() {
    const helpTypes = await getHelpTypeData();
    const helpContent = await getHelpData(
      this.state.searchText,
      this.state.selectedHelpTypes
    );
    this.setState({
      helpTypes: helpTypes,
      helpContent: helpContent,
    });
  }
  /**
   * Function executed when the help type field is modified
   * @param {Object} e The event triggering the function.
   */
  handleHelpTypeChange(e) {
    this.setState({
      selectedHelpTypes: e,
    });
    this.updateData();
  }
  /**
   * Function executed when the help search field is modified
   * @param {Object} e The event triggering the function.
   */
  handleSearchTextChange(e) {
    this.setState({
      searchText: e.target.value,
    });
    this.updateData();
  }
  /**
   * The Help Route
   * @return {JSX}
   */
  render() {
    const { helpTypes, selectedHelpTypes, helpContent } = this.state;
    // Create a bunch of rows for each help content item
    // eslint-disable-next-line no-unused-vars
    const helpRows = helpContent.map((item, key) => (
      <HelpContent
        key={item.id}
        title={item.title}
        description={item.description}
        helpType={item.helpType}
      />
    ));
    return (
      <div className={"wholepage"}>
        <header>
          <PrimaryBar />
        </header>
        <div className={"main-content"}>
          <div className="help_container">
            <h2>Help</h2>
            <div className="help_wrapper">
              <div className="search_bar">
                <input
                  type="search"
                  name="search"
                  placeholder="What can we help you with?"
                  onChange={this.handleSearchTextChange.bind(this)}
                />
                <img src={searchImage} alt="search" />
              </div>
              <Select
                className="select-component"
                options={helpTypes}
                value={selectedHelpTypes}
                onChange={this.handleHelpTypeChange.bind(this)}
                placeholder="ContextBar help articles."
                isMulti
              />
              <div className="q_and_a">{helpRows}</div>
            </div>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default Help;
