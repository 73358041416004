import {
    getData,
} from '../../app/data';
import promiseAllProperties from "promise-all-properties";

const getSettingsData = () => {
  const promises = {
    form: getSettingsFormData()
  };
  return promiseAllProperties(promises);
};

const getSettingsFormData = () => {
    return getData("settings/form", {}).then((res) => {
        return res;
    });
};

export default getSettingsData;