import React, { useEffect, useState } from "react";
import PrimaryBar from "../../components/PrimaryBar";
import SecondaryBar from "../../components/SecondaryBar";
import Footer from "../../components/Footer";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import getSettingsData from "./data";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { getBearerToken } from "../../app/auth";
import { API_ENDPOINT } from "../../app/config";

// eslint-disable-next-line no-unused-vars
const Data = (props) => {
  const [data, setData] = useState({
    form: {
      cpa: {},
    },
  });

  const [selectedSupplier, setSelectedSupplier] = useState("All Suppliers");
  const [yearType, setYearType] = useState("Year");
  const [supplierMenu, setSupplierMenu] = useState(["All Suppliers"]);

  /* grid format example
    [
        [{value:  "Channel/Year", readOnly: true}, {value:  2019, readOnly: true}, {value:  2020, readOnly: true}],
        [{value:  "F2F", readOnly: true}, {value:  null}, {value:  null}],
        [{value:  "Telemarketing", readOnly: true}, {value:  null}, {value:  null}]
    ]
    */
  const [gridState, setGridState] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [status, setStatus] = useState("");

  const formatCPAGrid = (formCPA, year_type, supplier) => {
    const grid = [];
    let temp_row = [];
    let channel = "";
    let financial_year = "";
    let year = "";
    let cell_value = null;
    for (let row_id = -1; row_id < formCPA.channel.length; row_id++) {
      temp_row = [];
      channel = formCPA.channel[row_id];
      if (year_type === "Financial Year (AU)") {
        for (
          let col_id = -1;
          col_id < formCPA.cohort_financial_year_au.length;
          col_id++
        ) {
          financial_year = formCPA.cohort_financial_year_au[col_id];
          // Table header stores years/financial years
          if (row_id === -1) {
            if (col_id === -1) {
              temp_row.push({
                value: "Chanel/Financial Year (AU)",
                readOnly: true,
              });
            } else {
              temp_row.push({
                value: financial_year,
                readOnly: true,
              });
            }
          } else {
            // Leftmost column of the table stores channels
            if (col_id === -1) {
              temp_row.push({
                value: channel,
                readOnly: true,
              });
            } else {
              cell_value = null;
              if (
                Object.prototype.hasOwnProperty.call(
                  formCPA.current_data,
                  supplier
                ) &&
                Object.prototype.hasOwnProperty.call(
                  formCPA.current_data[supplier],
                  channel
                ) &&
                Object.prototype.hasOwnProperty.call(
                  formCPA.current_data[supplier][channel],
                  financial_year
                )
              ) {
                cell_value =
                  formCPA.current_data[supplier][channel][financial_year];
              }
              temp_row.push({
                value: cell_value,
              });
            }
          }
        }
        grid.push(temp_row);
      } else {
        for (let col_id = -1; col_id < formCPA.cohort_year.length; col_id++) {
          year = formCPA.cohort_year[col_id];
          if (row_id === -1) {
            if (col_id === -1) {
              temp_row.push({
                value: "Chanel/Year",
                readOnly: true,
              });
            } else {
              temp_row.push({
                value: year,
                readOnly: true,
              });
            }
          } else {
            if (col_id === -1) {
              temp_row.push({
                value: channel,
                readOnly: true,
              });
            } else {
              cell_value = null;
              if (
                Object.prototype.hasOwnProperty.call(
                  formCPA.current_data,
                  supplier
                ) &&
                Object.prototype.hasOwnProperty.call(
                  formCPA.current_data[supplier],
                  channel
                ) &&
                Object.prototype.hasOwnProperty.call(
                  formCPA.current_data[supplier][channel],
                  year
                )
              ) {
                cell_value = formCPA.current_data[supplier][channel][year];
              }
              temp_row.push({
                value: cell_value,
              });
            }
          }
        }
        grid.push(temp_row);
      }
    }
    return grid;
  };

  // Hooks to load and apply the starting CPA input template from the database
  useEffect(() => {
    getSettingsData().then((res) => {
      const newSupplierMenu = ["All Suppliers"];
      newSupplierMenu.push(...res.form.cpa.supplier);
      setData(res);
      setSupplierMenu(newSupplierMenu);
      setGridState(formatCPAGrid(res.form.cpa, "year", selectedSupplier));
    });
    // eslint-disable-next-line
  }, [updateCount]);

  // Switch grid data between each supplier
  const handleSupplierChange = (supplier) => {
    setSelectedSupplier(supplier);
    setGridState(formatCPAGrid(data.form.cpa, yearType, supplier));
  };

  // Switch grid mode between year and financial year
  const handleYearTypeChange = (cohort_year_type) => {
    setYearType(cohort_year_type);
    setGridState(formatCPAGrid(data.form.cpa, cohort_year_type));
  };

  // Handle CPA update
  const onCPAFormSubmit = (event) => {
    event.preventDefault();
    const payload = {
      supplier: selectedSupplier,
      cohort_year_type: yearType,
      cpa: gridState,
    };
    setStatus("Updating CPA...");

    fetch(API_ENDPOINT + "settings/update_cpa", {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${getBearerToken()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setStatus(result.message);
          setUpdateCount(updateCount + 1);
        } else {
          setStatus(JSON.stringify(result));
        }
      });
  };

  return (
    <div className="wholepage">
      <header>
        <PrimaryBar />
        <SecondaryBar />
      </header>
      <div className="main-content">
        <div className="content_section">
          <div className="page-content">
            <div className={"view_container"}>
              <h2>Update Cost Per Acquisition (CPA)</h2>
              <Form onSubmit={onCPAFormSubmit}>
                <FormGroup>
                  <Label for="supplier">Supplier</Label>
                  <Input
                    type="select"
                    name="select_supplier"
                    id="supplier"
                    onChange={(event) =>
                      handleSupplierChange(event.target.value)
                    }
                  >
                    {supplierMenu.map((supplier) => {
                      return <option key={supplier}>{supplier}</option>;
                    })}
                  </Input>
                  <Label for="cohort_year_type">Cohort Year Type</Label>
                  <Input
                    type="select"
                    name="select_cohort_year_type"
                    id="cohort_year_type"
                    onChange={(event) =>
                      handleYearTypeChange(event.target.value)
                    }
                  >
                    <option>Year</option>
                    <option>Financial Year (AU)</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <ReactDataSheet
                    data={gridState}
                    valueRenderer={(cell) => cell.value}
                    onCellsChanged={(changes) => {
                      const grid = gridState.map((row) => [...row]);
                      // eslint-disable-next-line no-unused-vars
                      changes.forEach(({ cell, row, col, value }) => {
                        grid[row][col] = { ...grid[row][col], value };
                      });
                      setGridState(grid);
                    }}
                  />
                </FormGroup>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Form>
              <p>{status}</p>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Data;
