import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CookieConsentBar from "../../components/CookieConsentBar";
import { useQuery, validateEmail } from "../../app/utils";
import { verifyUser } from "../../app/auth";
import { Navigate } from "react-router-dom";

const UserVerification = () => {
  let query = useQuery();

  const [username, setUsername] = useState(query.get("username"));
  const [verificationCode, setVerificationCode] = useState(
    query.get("verification_code")
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
  };

  const handleVerificationCodeChange = (e) => {
    const value = e.target.value;
    setVerificationCode(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // reset error message
    setErrorMsg("");
    // Check the email format
    if (!validateEmail(username)) {
      setErrorMsg("Invalid email format");
      return;
    }
    // get client IP address
    setIsLoading(true);

    // login process
    const res = await verifyUser(username, verificationCode);
    if (res.status_code !== 200) {
      setErrorMsg(res.message);
    } else {
      setVerifySuccess(true);
    }
  };

  if (verifySuccess) {
    return (
      <Navigate
        to={{
          pathname: "/login",
          state: {
            registerMsg:
              "Successfully verified, redirected to login page in 5 seconds.",
          },
        }}
      />
    );
  }

  // The Spinner icon if the isLoading is true
  let loadingSpinner;
  if (isLoading) {
    loadingSpinner = <Spinner color="primary" />;
  }

  return (
    <div className="container">
      <div className="free-trial-parent">
        <Card className="free-trial-card">
          <CardBody>
            <h5>User Verification</h5>
            <br />
            <Form action="#" onSubmit={(e) => handleSubmit(e)}>
              <FormGroup>
                <Label for="username" className={"black-label"}>
                  Email
                </Label>
                <Input
                  type="text"
                  name="Email"
                  id="Email"
                  autoComplete="on"
                  defaultValue={username}
                  onChange={handleUsernameChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="verification-code" className={"black-label"}>
                  Verification Code
                </Label>
                <Input
                  type="text"
                  name="verification-code"
                  id="verification-code"
                  defaultValue={verificationCode}
                  onChange={handleVerificationCodeChange}
                />
              </FormGroup>
              <FormGroup>
                If user verification is successful, the browser will be
                redirected to the login page automatically.
              </FormGroup>
              <FormGroup>
                {verifySuccess ? (
                  <p>
                    User verification success, please{" "}
                    <a style={{ color: "#2980d9" }} href={"/login"}>
                      login
                    </a>{" "}
                    to access Giving Insight.
                  </p>
                ) : (
                  errorMsg.length > 0 && <p className="error-msg">{errorMsg}</p>
                )}
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    {isLoading ? (
                      <Button type="submit" disabled={true}>
                        Verifying...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        disabled={verifySuccess}
                      >
                        {verifySuccess ? "Verified" : "Verify"}
                      </Button>
                    )}
                  </Col>
                  <Col>{loadingSpinner}</Col>
                </Row>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
      <CookieConsentBar />
    </div>
  );
};

export default UserVerification;
