import promiseAllProperties from "promise-all-properties";
import {
  getData,
  postPayloadData,
  withComparison,
  withYearComparison,
} from "../../app/data";
import moment from "moment";

const getCalendarData = (selectedFilters, offset) => {
  const promises = {
    totalRevenue: getTotalLifetimeRevenue({ ...selectedFilters }),
    signups: getSignupsAtFirstTransaction({ ...selectedFilters }),
    active: getActiveGifts({ ...selectedFilters }),
    latestSignups: getLatestSignupsAtFirstTransaction(selectedFilters),
    dropCount: getDropCount({ ...selectedFilters }),
    latestDropCount: getLatestDropCount(selectedFilters),
    decline: getAverageDecline({ ...selectedFilters }),
    latestDecline: getLatestDecline(selectedFilters),
    revenueByMonth: getRevenueByMonth({ ...selectedFilters }),
    latestRevenue: getLatestTransactionRevenue(selectedFilters),
    donorsPerMonth: getDonorsPerMonth({ ...selectedFilters }),
    signupsByMonth: getSignupsPerMonth({ ...selectedFilters }),
    dropCountByMonth: getDropCountByMonth({ ...selectedFilters }),
    canceledDonors: getCanceledDonors({ ...selectedFilters }, offset),
    totalGiftCount: getTotalGiftCount({ ...selectedFilters }),
    latestTotalGiftCount: getLatestTotalGiftCount(selectedFilters),
    totalGiftCountByMonth: getTotalGiftCountByMonth({ ...selectedFilters }),
    activeGiftCount: getActiveGiftCount({ ...selectedFilters }),
    latestActiveGiftCount: getLatestActiveGiftCount(selectedFilters),
    activeGiftCountByMonth: getActiveGiftCountByMonth({ ...selectedFilters }),
    terminatedGiftCount: getTerminatedGiftCount({ ...selectedFilters }),
    latestTerminatedGiftCount: getLatestTerminatedGiftCount(selectedFilters),
    terminatedGiftCountByMonth: getTerminatedGiftCountByMonth({
      ...selectedFilters,
    }),
  };
  return promiseAllProperties(promises);
};

const getTotalLifetimeRevenue = (selectedFilters = {}) => {
  return withYearComparison("get_revenue", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.revenue;
  });
};

const getSignupsAtFirstTransaction = (selectedFilters) => {
  return withYearComparison("get_total_signups", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.donorCount;
  });
};

const getActiveGifts = (selectedFilters) => {
  return withYearComparison(
    "get_donor_count?active",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return res.donorCount;
    }
  );
};

const getLatestSignupsAtFirstTransaction = (selectedFilters) => {
  return getData("get_total_signups?by-month", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      donorCount: res[res.length - 1].donorCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getDropCount = (selectedFilters) => {
  return withYearComparison("get_canceled_count", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.dropCount;
  });
};

const getLatestDropCount = (selectedFilters) => {
  return getData("get_canceled_count?by-month", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    if (res[res.length - 1].month === null && res.length > 1) {
      return {
        dropCount: res[res.length - 2].dropCount,
        transactionMonth: res[res.length - 2].month,
      };
    }
    return {
      dropCount: res[res.length - 1].dropCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getAverageDecline = (selectedFilters = {}) => {
  return withYearComparison(
    "get_average_decline?condense",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return res.notAccepted / res.transactionCount;
    }
  );
};

const getLatestDecline = (selectedFilters = {}) => {
  return getData("get_average_decline", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      averageDecline:
        res[res.length - 1].notAccepted / res[res.length - 1].transactionCount,
      transactionMonth: res[res.length - 1].transactionMonth,
    };
  });
};

const getRevenueByMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth = moment(item.transactionMonth).format("MMM YYYY");
    });
    return res;
  };
  return withComparison(
    "get_revenue?by-transaction",
    selectedFilters,
    computeFn
  );
};

const getLatestTransactionRevenue = (selectedFilters = {}) => {
  return getData("get_revenue?by-transaction", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      revenue: res[res.length - 1].revenue,
      transactionMonth: res[res.length - 1].transactionMonth,
    };
  });
};

const getDonorsPerMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth = moment(item.transactionMonth).format("MMM YYYY");
    });
    return res;
  };
  return withComparison(
    "get_active_donor_count?by-transaction",
    selectedFilters,
    computeFn
  );
};

const getSignupsPerMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth = moment(item.month).format("MMM YYYY");
    });
    return res;
  };
  return withComparison(
    "get_total_signups?by-month",
    selectedFilters,
    computeFn
  );
};

const getDropCountByMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null
          ? moment(item.month).format("MMM YYYY")
          : "Not supplied";
    });
    return res;
  };
  return withComparison(
    "get_canceled_count?by-month",
    selectedFilters,
    computeFn
  );
};

const getCanceledDonors = (selectedFilters, offset) => {
  return postPayloadData("get_canceled_donors", {
    ...selectedFilters,
    offset: offset,
  }).then((res) => {
    if (res === null || res.length === 0) {
      return { data: [], size: 0 };
    }
    const size = res.size;
    const rtn = [
      [
        { value: "Donor ID", readOnly: true, className: "table-cell-header" },
        {
          value: "Master Gift ID",
          readOnly: true,
          className: "table-cell-header",
        },
        {
          value: "Gift End Date",
          readOnly: true,
          className: "table-cell-header",
        },
        {
          value: "Cancel Code",
          readOnly: true,
          className: "table-cell-header",
        },
        {
          value: "Cancel Reason",
          readOnly: true,
          className: "table-cell-header",
        },
        {
          value: "Cancel Comment",
          readOnly: true,
          className: "table-cell-header",
        },
      ],
    ];
    res.data.forEach((row) => {
      rtn.push([
        { value: row.source_donor_id, readOnly: true, className: "table-cell" },
        {
          value: row.source_master_gift_id,
          readOnly: true,
          className: "table-cell",
        },
        {
          value:
            row.gift_end_date !== null
              ? moment(row.gift_end_date).format("MMM YYYY")
              : "Not supplied",
          readOnly: true,
          className: "table-cell",
        },
        { value: row.code, readOnly: true, className: "table-cell" },
        { value: row.reason, readOnly: true, className: "table-cell" },
        { value: row.comment, readOnly: true, className: "table-cell" },
      ]);
    });
    return { data: rtn, size: size };
  });
};

const getTotalGiftCount = (selectedFilters) => {
  return withYearComparison("get_gift_count", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.giftCount;
  });
};

const getLatestTotalGiftCount = (selectedFilters) => {
  return getData("get_gift_count?by-month", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      giftCount: res[res.length - 1].giftCount,
      transactionMonth: res[res.length - 1].month,
    };
  });
};

const getTotalGiftCountByMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null
          ? moment(item.month).format("MMM YYYY")
          : "Not supplied";
    });
    return res;
  };
  return withComparison("get_gift_count?by-month", selectedFilters, computeFn);
};

const getActiveGiftCount = (selectedFilters) => {
  return withYearComparison("get_active_gift_count", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.giftCount;
  });
};

const getLatestActiveGiftCount = (selectedFilters) => {
  return getData("get_active_gift_count?by-month", selectedFilters).then(
    (res) => {
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return {
        giftCount: res[res.length - 1].giftCount,
        transactionMonth: res[res.length - 1].month,
      };
    }
  );
};

const getActiveGiftCountByMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null
          ? moment(item.month).format("MMM YYYY")
          : "Not supplied";
    });
    return res;
  };
  return withComparison(
    "get_active_gift_count?by-month",
    selectedFilters,
    computeFn
  );
};

const getTerminatedGiftCount = (selectedFilters) => {
  return withYearComparison(
    "get_terminated_gift_count",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return res.giftCount;
    }
  );
};

const getLatestTerminatedGiftCount = (selectedFilters) => {
  return getData("get_terminated_gift_count?by-month", selectedFilters).then(
    (res) => {
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return {
        giftCount: res[res.length - 1].giftCount,
        transactionMonth: res[res.length - 1].month,
      };
    }
  );
};

const getTerminatedGiftCountByMonth = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    res.forEach((item) => {
      item.transactionMonth =
        item.month !== null
          ? moment(item.month).format("MMM YYYY")
          : "Not supplied";
    });
    return res;
  };
  return withComparison(
    "get_terminated_gift_count?by-month",
    selectedFilters,
    computeFn
  );
};

export { getCalendarData, getCanceledDonors };
