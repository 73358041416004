import React from 'react'
import Dropdown from '../Dropdown'
import PropTypes from 'prop-types'
import './ContextFilter.css'
import { Checkbox } from '@blueprintjs/core'

/**
 * Filter
 * A atomic filter object that stores state of each value it has as well as the selected values it maintains.
 * @param {Object} props the data for the filter.
 * @return {JSX} The filter component rendered.
 */
function ContextFilter (props) {
    const { values, onChange, label, name, loading, handleFilterClear} = props

    function handleCheckboxToggle (filterKey, value, event) {
        onChange(filterKey, value, event.target.checked)
    }

    const valueDivs = values.map((value) => {
        return {
            key: value.key,
            node:
              <div className={'dropdown-content'} key={value.key}>
                  <Checkbox
                    key={value.key}
                    loading={loading ? 1 : 0}
                    checked={value.selected}
                    label={value.key}
                    onChange={(e) => handleCheckboxToggle(name, value.key, e)}/>
              </div>,

        }
    })

    return (
      <Dropdown
        handleFilterClear={handleFilterClear}
        loading={loading}
        className={'dropdown'}
        name={name}
        label={label}
        values={valueDivs}/>
    )
}

ContextFilter.propTypes = {
    values: PropTypes.arrayOf(
      PropTypes.shape({ key: PropTypes.any, selected: PropTypes.bool })),
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    label: PropTypes.string,
    handleFilterClear: PropTypes.func,
    name: PropTypes.string,
}

export default ContextFilter
