import promiseAllProperties from 'promise-all-properties';
import {
  getData,
} from '../../app/data';
import moment from 'moment';
import {formatValue} from '../../app/utils';

const getForecastResults = (filters, forecastVersions, compare) => {
  const promises = {
    profitAcc: getForecastProfitAcc(filters, forecastVersions, compare),
    profit: getForecastProfit(filters, forecastVersions, compare),
    revenue: getForecastRevenue(filters, forecastVersions),
    expense: getForecastExpense(filters, forecastVersions),
    breakEven: getBreakEvenMonth(filters, forecastVersions),
    donors: getForecastDonorsTable(filters, forecastVersions),
    forecastVersions: getForecastVersions(forecastVersions),
  };
  return promiseAllProperties(promises);
};

const getForecastProfitAcc = (selectedFilters, forecastVersions, compare) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    return res.map((element) => ({
      'Reporting Date': moment(element.reporting_date).format('MMM YYYY'),
      'Profit': element.profit,
    }));
  };
  const compareFn = (a, b) => {
    const aSplit = a.split(' ');
    const bSplit = b.split(' ');

    const aYear = Number(aSplit[1]);
    const bYear = Number(bSplit[1]);

    if (aYear !== bYear) {
      return aYear - bYear;
    }

    const aMonth = moment().month(aSplit[0]).format('M');
    const bMonth = moment().month(bSplit[0]).format('M');

    return aMonth - bMonth;
  };
  return withForecastComparison('forecast/view/profit?by-month&accumulate',
      selectedFilters,
      forecastVersions,
      compare,
      computeFn,
      compareFn,
      'Profit',
      'Reporting Date');
};

const withForecastComparison = (
    endpoint,
    selectedFilters,
    forecastVersions,
    compare,
    computeFn,
    compareFn,
    key,
    mergeKey) => {
  const queryName = compare ?
    'forecast-version-id[cmp]' :
    'forecast-version-id';
  return getData(endpoint,
      {...selectedFilters, [queryName]: forecastVersions}).then((res) => {
    if (!compare) {
      return computeFn(res);
    }
    if (res === null || res.length === 0) {
      return {};
    }
    const versions = Object.keys(res);
    const keyResults = {};
    for (const version of versions) {
      const versionResults = computeFn(res[version]);
      for (const row of versionResults) {
        const mergeValue = row[mergeKey];
        if (!(mergeValue in keyResults)) {
          keyResults[mergeValue] = {
            [mergeKey]: mergeValue,
          };
        }
        keyResults[mergeValue][version] = row[key];
      }
    }
    const rtn = [];
    const mergeKeys = Object.keys(keyResults).sort((a, b) => compareFn(a, b));
    for (const k of mergeKeys) {
      for (const version of forecastVersions) {
        if (!(version in keyResults[k])) {
          keyResults[k][version] = null;
        }
      }
      rtn.push(keyResults[k]);
    }
    return rtn;
  });
};

const getForecastProfit = async (selectedFilters = {}, forecastVersions = null, compare) => {
  if (forecastVersions === null) {
    return getData('forecast/profit', selectedFilters).then((res) => {
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return formatValue(res.profit, '$###,0###');
    });
  }

  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    return res.map((element) => ({
      'Reporting Date': moment(element.reporting_date).format('MMM YYYY'),
      'Profit': element.profit,
    }));
  };
  const compareFn = (a, b) => {
    const aSplit = a.split(' ');
    const bSplit = b.split(' ');

    const aYear = Number(aSplit[1]);
    const bYear = Number(bSplit[1]);

    if (aYear !== bYear) {
      return aYear - bYear;
    }

    const aMonth = moment().month(aSplit[0]).format('M');
    const bMonth = moment().month(bSplit[0]).format('M');

    return aMonth - bMonth;
  };
  return withForecastComparison('forecast/view/profit?by-month',
      selectedFilters,
      forecastVersions,
      compare,
      computeFn,
      compareFn,
      'Profit',
      'Reporting Date');
};

const getForecastRevenue = async (selectedFilters = {}, forecastVersions) => {
  return getData('forecast/view/revenue',
      {...selectedFilters, 'forecast-version-id': forecastVersions}).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return formatValue(res.revenue, '$###.#a');
  });
};

const getForecastExpense = async (selectedFilters = {}, forecastVersions) => {
  return getData('forecast/view/expenses', {...selectedFilters, 'forecast-version-id': forecastVersions}).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return formatValue(res.expenses, '$###.#a');
  });
};

const getBreakEvenMonth = async (selectedFilters = {}, forecastVersions) => {
  return getData('forecast/view/break-even-month',
      {...selectedFilters, 'forecast-version-id': forecastVersions}).then((res) => {
    if (res === null || res.length === 0) {
      return {
        month: 'No Data',
        monthName: 'No Data',
        year: 'No Data',
        number: 'No Data',
      };
    }
    return {
      month: moment(res.breakEvenMonth).format('MMM'),
      monthName: moment(res.breakEvenMonth).format('MMMM'),
      year: moment(res.breakEvenMonth).format('YYYY'),
      number: res.breakEvenMonthNumber,
    };
  });
};

const getForecastDonorsTable = async (selectedFilters = {}, forecastVersions) => {
  return getData('forecast/view/remaining-donors',
      {...selectedFilters, 'forecast-version-id': forecastVersions}).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    let rtn = [];
    const body = [];
    const header = [{value: 'Cohort', readOnly: true, className: 'table-cell-header'}];
    const headerMap = new Map();
    const maxColumns = 20;
    let currentCohort = null;
    let currentRow = [];
    let maxRowLength = -1;
    let firstCohort = null;
    res.forEach(function(row) {
      if (firstCohort === null) {
        firstCohort = row.cohort_start_date;
      }
      if (row.cohort_start_date !== currentCohort) {
        if (currentRow.length > 0) {
          body.push(currentRow.slice(0, maxColumns));
          // Store the maximum length of the row, so that
          // we can determine how many empty cells all the
          // other rows need to be padded by, if their
          // length is less than this value.
          maxRowLength = Math.max(maxRowLength,
              Math.min(maxColumns, currentRow.length));
        }
        currentRow = [{
          value: moment(row.cohort_start_date).format('MMM YY'),
          readOnly: true,
          className: 'table-cell-header',
        }];
        currentCohort = row.cohort_start_date;
      }
      const d1 = moment(row.reporting_date);
      const d2 = moment(firstCohort);
      // moment(..).diff(...) was returning weird values,
      // so switched to using manual approach
      // to calculate the period
      const period = (d1.year() - d2.year()) * 12 + d1.month() - d2.month();
      if (!headerMap.has(period)) {
        header.push({
          value: period.toString(),
          readOnly: true,
          className: 'table-cell-header'});
        headerMap.set(period, true);
      }
      currentRow.push({
        value: formatValue(row.remaining_donors, '###,##0'),
        readOnly: true,
        className: 'table-cell',
      });
    });
    // Add the final row to the body
    body.push(currentRow.slice(0, maxColumns));
    // Account for the last row having the greatest amount of cells.
    // This will probably never happen, but just in case.
    maxRowLength = Math.max(maxRowLength,
        Math.min(maxColumns, currentRow.length));

    // Pad the table with empty cells
    for (const row of body) {
      const emptyCells = maxRowLength - row.length;
      for (let i = 0; i < emptyCells; i++) {
        row.push({
          value: null,
          readOnly: true,
        });
      }
    }

    rtn.push(header.slice(0, maxColumns));
    rtn = rtn.concat(body);
    return rtn;
  });
};

const getForecastVersions = (forecastVersions) => {
  return getData('forecast/view', {'forecast-version-id': forecastVersions})
      .then((res) => {
        if (res === null || res.length === 0) {
          return {};
        }
        const rtn = {};
        res.forEach((row) => {
          rtn[row.forecast_version_id] = row;
        });
        return rtn;
      });
};

export default getForecastResults;
