import React, { useRef, useState } from 'react';
import { Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './Dropdown.css';
import { useOutsideClick } from '../../../app/hooks';

/**
 * Dropdown
 * A atomic dropdown that takes a list of objects to display as options in the dropdown. Each option should
 * handle its own on-click/hover events.
 * @param {Object} props the data for the dropdown.
 * @return {JSX} The dropdown component rendered.
 */
function Dropdown(props) {
  const { label, values, loading, handleFilterClear, name } = props;
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  function toggleDropdown() {
    setOpen(!open);
  }

  return (
    <div data-cy={`filter-dropdown-${name}`} className="dd-parent" ref={ref}>
      <Button
        minimal
        className={'dd-text'}
        disabled={loading}
        text={label}
        onClick={() => toggleDropdown()}
        icon={open ? 'chevron-up' : 'chevron-down'}
        style={{ outline: 'none' }}
      />
      {open && (
        <div className={'dd-list'}>
          {values.map((item) => item.node)}
          <Button
            text={'Clear'}
            onClick={() => {
              handleFilterClear(name);
            }}
            className={'dropdown-content clear-button'}
            minimal={true}
          />
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleFilterClear: PropTypes.func,
  loading: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      node: PropTypes.node,
    }),
  ),
};

export default Dropdown;
