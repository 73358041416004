/* eslint-disable max-len */
import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Home from '../routes/Home';
import Login from '../routes/Login';
import Register from '../routes/Register';
import NotFound from '../routes/NotFound';
import AnalyticsCalendar from '../routes/AnalyticsCalendar';
import AnalyticsHallOfFame from '../routes/AnalyticsHallOfFame';
import AnalyticsDataQuality from '../routes/AnalyticsDataQuality';
import AnalyticsInstallment from '../routes/AnalyticsInstallment';
import AnalyticsKPI from '../routes/AnalyticsKPI';
import ForecastNew from '../routes/ForecastNew';
import ForecastResults from '../routes/ForecastResults';
import ForecastView from '../routes/ForecastView';
import Help from '../routes/Help';
import PasswordReset from '../routes/PasswordReset';
import { getRole, isAuthenticated } from './auth';
import BaselineForecastResults from '../routes/BaselineForecastResults';
import Members from '../routes/Members';
import Data from '../routes/Data';
import Invitation from '../routes/Invitation/Invitation';
import AnalyticsDeclines from '../routes/AnalyticsDeclines';
import AnalyticsBestDonorSegment from '../routes/AnalyticsBestDonorSegment';
import ErrorBoundary from '../components/ErrorBoundary';
import Logout from '../routes/Logout';
import ImportData from '../routes/ImportData/ImportData';
import PropTypes from 'prop-types';
import Policies from '../routes/Policies';
import UserVerification from '../routes/UserVerification';

/**
 * Contains all the routes required for the application
 * @return {JSX.Element} - JSX containing all the routes
 */
function AppRoutes() {
  // eslint-disable-next-line react/prop-types
  const PrivateRoute = () => {
    return isAuthenticated() === true ? (
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    ) : (
      <Navigate to={`/login?redirect=${window.location.pathname}${window.location.search}`} />
    );
  };

  // eslint-disable-next-line react/prop-types
  const AdminRoute = () => {
    return isAuthenticated() === true && getRole() === 'Administrator' ? (
      <Outlet />
    ) : (
      <Navigate to={isAuthenticated() ? '/home' : '/login'} />
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/forgot" element={<PasswordReset />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/verify" element={<UserVerification />} />

        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<Home />} />
          <Route path="/analytics/calendar" element={<AnalyticsCalendar />} />
          <Route path="/analytics/hall_of_fame" element={<AnalyticsHallOfFame />} />
          <Route path="/analytics/data_quality" element={<AnalyticsDataQuality />} />
          <Route path="/analytics/installment" element={<AnalyticsInstallment />} />
          <Route path="/analytics/kpi" element={<AnalyticsKPI />} />
          <Route path="/analytics/declines" element={<AnalyticsDeclines />} />
          <Route path="/analytics/best_donor_segment" element={<AnalyticsBestDonorSegment />} />

          <Route path="/forecast">
            <Route path="new" element={<ForecastNew />} />
            <Route path="new/:forecast_version_id" element={<ForecastNew />} />

            <Route path="view" element={<ForecastView />} />
            <Route path="results" element={<ForecastResults providerUrl={false} />} />
            <Route
              path="results/baseline/:forecast_version_id"
              element={<BaselineForecastResults providerUrl={false} />}
            />
          </Route>

          <Route path="/help" element={<Help />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route path="/settings/members" element={<Members />} />
          <Route path="/settings/import" element={<ImportData />} />
          <Route path="/settings/data" element={<Data />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

AppRoutes.propTypes = {
  location: PropTypes.object,
};

export default AppRoutes;
