import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBar = () => {
  return (
    <CookieConsent
      id="cookie-consent-bar"
      location="bottom"
      style={{
        textAlign: "center",
      }}
    >
      This website uses cookie to enhance the user experience. For more
      information on the cookie we are using and how to configure your browser
      if you do not wish to be tracked, please read{" "}
      <a
        href={
          "https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information"
        }
      >
        here
      </a>
      .
    </CookieConsent>
  );
};

export default CookieConsentBar;
