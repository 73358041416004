import React from 'react';

import AppRoutes from './appRoutes';

/**
 * Handles the rendering for the main application
 * @return {JSX}
 */
function App() {
  return <AppRoutes />;
}

export default App;
