import React from "react";
import PropTypes from "prop-types";

import "./HelpContent.css";
import ReactMarkdown from "react-markdown";

/**
 * A Help content item
 * @param {Object} props of the help content item
 * @return {JSX}
 */
function HelpContent(props) {
  const { title, description, helpType } = props;
  return (
    <div className="qa_section">
      <div className="question_q">
        <i>{title}</i>
        <div className="self_btn grey_btn">{helpType}</div>
      </div>
      <div className="answer_a">
        <ReactMarkdown>{description}</ReactMarkdown>
      </div>
    </div>
  );
}

HelpContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  helpType: PropTypes.string,
};

export default HelpContent;
