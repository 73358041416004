import React from "react";
import PropTypes from "prop-types";
import "./ContextTag.css";
import { Tag } from "@blueprintjs/core";
import { filterDefinition } from "../../../app/filters";

/**
 * ContextTag
 * A atomic filter tag that shows which filters have been applied. Can call the parent component to notify that a
 * clear event has been triggered for the filter.
 * @param {Object} props the data for the filter.
 * @return {JSX} The filter component rendered.
 */
function ContextTag(props) {
  const { values, name, handleFilterClear } = props;

  const rtn = [];
  values.forEach((value) => {
    if (value.selected) {
      rtn.push(value.key);
    }
  });
  if (rtn.length > 0) {
    return (
      <Tag
        className={"context-filter-tag"}
        interactive
        minimal
        icon={"small-cross"}
        onClick={() => {
          handleFilterClear(name);
        }}
      >
        {filterDefinition[name].disambiguator(rtn)}
      </Tag>
    );
  }
  return <></>;
}

ContextTag.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.any, selected: PropTypes.bool })
  ),
  handleClearFilter: PropTypes.func,
  handleFilterClear: PropTypes.func,
  name: PropTypes.string,
};

export default ContextTag;
