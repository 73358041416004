import promiseAllProperties from 'promise-all-properties';
import {
  getData,
} from '../../app/data';
import {formatValue} from '../../app/utils';
import moment from 'moment';

const getHomeData = (filters) => {
  const promises = {
    activeDonors: getActiveDonors(filters),
    activeDonorsLm: getActiveDonorsLatestMonth(filters),
    avgGift: getDonorAverageGift(filters),
    revenue: getRevenue(filters),
    forecastProfit: getForecastProfit(filters),
  };
  return promiseAllProperties(promises);
};

const getActiveDonors = async (selectedFilters = {}) => {
  return getData('get_donor_count', selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return formatValue(res[0].donorCount, '###,##0');
  });
};

const getActiveDonorsLatestMonth = async (selectedFilters = {}) => {
  return getData('get_donor_count?by-cohort', selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      if (res === null || res.length === 0) {
        return 'No Data';
      }
    }
    const cohort = moment(res[res.length - 1].cohort).format('MMMM YYYY');
    const count = res[res.length - 1].donorCount;
    return '' + count + ' in ' + cohort;
  });
};

// Donor details - average gift size
const getDonorAverageGift = async (selectedFilters = {}) => {
  return getData('get_average_gift', selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return 'No Data';
    }
    return res[0];
  });
};

const getRevenue = async (selectedFilters = {}) => {
  return getData('get_revenue?by-transaction', selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return {
        total: 'No Data',
        lm: 'No Data',
        maxMonthLabel: 'No Data',
        maxMonthValue: 'No Data',
      };
    }
    let total = 0;
    let max = 0;
    let maxMonth = '';
    res.forEach(function(element) {
      total = total + element.revenue;
      if (element.revenue > max) {
        max = element.revenue;
        maxMonth = element.transactionMonth;
      }
    });
    // Format the max months data
    const maxMonthLabel = moment(maxMonth).format('MMM \'YY');
    // Get the latest month's data
    const lm = res[res.length - 1];
    const lmRtn = formatValue(lm.revenue, '$###,##0.00') + ' in ' +
      moment(lm.transactionMonth).format('MMMM YYYY');
    return {
      total: formatValue(total, '$###,##0'),
      lm: lmRtn,
      maxMonthLabel: maxMonthLabel,
      maxMonthValue: formatValue(max, '$###,##0') + ' in revenue',
    };
  });
};

const getForecastProfit = async (selectedFilters = {}, versionId = null) => {
  if (versionId === null) {
    return getData('forecast/profit', selectedFilters).then((res) => {
      if (res === null || res.length === 0) {
        return 'No Data';
      }
      return formatValue(res.profit, '$###,0###');
    });
  }
  return getData('forecast/view/' + versionId +
    '/profit?by-month', selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    const rtn = [];
    res.forEach(function(element) {
      const data = {
        'Reporting Date': moment(element.reporting_date).format('MMM YYYY'),
        'Profit': element.profit,
      };
      rtn.push(data);
    });
    return rtn;
  });
};

export default getHomeData;
