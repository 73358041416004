import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './Pages.css';

/**
 * Returns pagination control for a data set by determining the number
 * of pages to show, whether to show the first and last buttons and
 * controlling the currently selected item. Its up to the parent component
 * to handle the actual pagination of the data set, this will merely control
 * the pages that the user can see, as well as behaviour around first, last,
 * next and prev.
 *
 * The only required property is dataSetLength, which determines how many
 * pages there are. There are several other optional properties including
 * if a function should be executed each time the page is changed. This is
 * the typical behaviour of passing the page change back to the parent.
 * @param {Object} props the data for the button
 * @return {JSX}
 */
function Pages(props) {
  // Hook to manage the current page of the component, defaulting to 1
  const [pageNumber, setPageNumber] = useState(1);
  // Hook to manage the semaphore-like control of parent to child page
  // propagation.

  // Setup the properties of the components with defaults
  const showFirst = props.showFirst || true;
  const showPrev = props.showPrev || true;
  const showNext = props.showPrev || true;
  const showLast = props.showLast || true;

  // Calculate the number of pages to show
  const dataSetLength = props.dataSetLength || 0;
  const itemsPerPage = props.itemsPerPage || 20;
  const maxPages = Math.ceil(dataSetLength / itemsPerPage);
  const totalPages = props.totalPages || 7;

  // Handle filtering out the current selected page
  if (pageNumber > maxPages && maxPages !== 0) {
    setPageNumber(maxPages);
    if (props.onChange !== undefined) {
      props.onChange(maxPages);
    }
  }

  // Calculate the page numbers to show
  // First, how many go each side of the current page?
  const perSide = Math.floor(totalPages / 2);
  let lowerBound = Math.max(1, pageNumber - perSide);
  // Any remainder from the LHS is put on the RHS side.
  let rhs = perSide;
  if (lowerBound === 1) {
    rhs = perSide + (perSide - pageNumber);
  }
  const upperBound = Math.min(pageNumber + rhs, maxPages);

  // Conversely, if the RHS is at the max, use any remainder in the number of
  // pages to append to the left hand side.
  if (upperBound === maxPages && lowerBound !== 1) {
    const remainder = totalPages - (upperBound - lowerBound);
    lowerBound = Math.max(1, lowerBound - remainder);
  }

  /**
   * Function to handle the changing of the page number based on user input.
   * @param {object} selectedPage The page to set as the new page.
   */
  function setPage(selectedPage) {
    if (selectedPage < 1) {
      selectedPage = 1;
    }
    if (selectedPage > maxPages) {
      selectedPage = maxPages;
    }
    // Update the hook
    setPageNumber(selectedPage);
    // Run the onChange function provided if it was specified.
    if (props.onChange !== undefined) {
      props.onChange(selectedPage);
    }
  }

  // Generate a div to return
  const paginationDiv = [];
  for (let i = lowerBound; i <= upperBound; i++) {
    if (pageNumber === i) {
      paginationDiv.push(
        <PaginationItem active key={i}>
          <PaginationLink key={'link-' + i} onClick={() => setPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    } else {
      paginationDiv.push(
        <PaginationItem key={i}>
          <PaginationLink key={'link-' + i} onClick={() => setPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }
  }

  // Return an empty nothing if there is no data.
  if (dataSetLength === 0) {
    return <></>;
  }

  return (
    <div className={'pages-container'}>
      <Pagination className={'pages'}>
        {showFirst && maxPages > 1 && (
          <PaginationItem key={'first'}>
            <PaginationLink key={'link-first'} first onClick={() => setPage(1)} />
          </PaginationItem>
        )}
        {showPrev && maxPages > 1 && (
          <PaginationItem key={'prev'}>
            <PaginationLink key={'link-prev'} previous onClick={() => setPage(pageNumber - 1)} />
          </PaginationItem>
        )}
        {paginationDiv}
        {showNext && maxPages > 1 && (
          <PaginationItem key={'next'}>
            <PaginationLink key={'link-next'} next onClick={() => setPage(pageNumber + 1)} />
          </PaginationItem>
        )}
        {showLast && maxPages > 1 && (
          <PaginationItem key={'last'}>
            <PaginationLink key={'link-last'} last onClick={() => setPage(maxPages)} />
          </PaginationItem>
        )}
      </Pagination>
    </div>
  );
}

Pages.propTypes = {
  // Optional: The total length of the data set.
  dataSetLength: PropTypes.number,
  // Optional: If the first page button should be shown, default true.
  showFirst: PropTypes.bool,
  // Optional: If the last page button should be shown, default true.
  showLast: PropTypes.bool,
  // Optional: If the next page button should be shown, default true.
  showNext: PropTypes.bool,
  // Optional: If the previous page button should be shown, default true.
  showPrev: PropTypes.bool,
  // Optional: The number of items to display per page, default 10
  itemsPerPage: PropTypes.number,
  // Optional: The maximum number of pages that can be shown at once. Default
  // is 7, leading to 3 each side of the selected.
  totalPages: PropTypes.number,
  // Suggested: The function to run on selection change.
  onChange: PropTypes.func,
};

export default Pages;
