import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryBar from '../PrimaryBar';
import { Button } from 'reactstrap';
import './ErrorBoundary.css';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserId, isAuthenticated } from '../../app/auth';
import { logError } from './data';

const currentDate = () => {
  const d = new Date();
  return d.toUTCString();
};

const ErrorBoundary = ({ children }) => {
  const [errorInfo, setErrorInfo] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (errorInfo) {
      setErrorInfo(null);
    }
  }, [location]);

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      event.preventDefault();
      console.log(`Error:`, event);
      setErrorInfo(event.reason);
      storeError(event.reason);
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  const storeError = (errorInfo) => {
    const payload = {
      user_id: getUserId(),
      log_date: currentDate(),
      event_type: 'App Crash',
      event_description: `${errorInfo} ${location.pathname} ${location.search}`,
    };
    logError('log', payload);
  };

  if (errorInfo) {
    return (
      <div>
        <ErrorInterface />
      </div>
    );
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;

const ErrorInterface = () => {
  const [redirectRoute, setRedirectRoute] = useState(false);

  if (redirectRoute) {
    return <Navigate to={isAuthenticated() ? '/home' : '/login'} />;
  }

  return (
    <div>
      <div className="wholepage">
        <header>
          <PrimaryBar selected="Home" />
        </header>
        <div className="errorBoundary main-content">
          <div className="vi_section error">
            <h2>An Error has occurred</h2>
            <br />
            <h5>
              The error has been logged and reported. We apologize for the inconvenience this may
              have caused.
            </h5>
            <div className="vibtn_wrap">
              <br />
              <Button className="self_btn vi_btn" onClick={() => setRedirectRoute(true)}>
                Go back to Home page
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
