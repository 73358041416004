import React from 'react';
import PropTypes from 'prop-types';
import {Classes, Drawer} from "@blueprintjs/core";
import './FilterSidebar.css'
import FilterSidebarFilter from "../FilterSidebarFilter";


/**
 * Filter Sidebar
 * A sidebar component used by the filter context bar that has a list of all filters a page supports.
 * @param {Object} props the data for the dropdown.
 * @return {JSX} The dropdown component rendered.
 */
function FilterSidebar(props) {
    const {title, footer, icon, handleClose, isOpen, filterKeys, getLabel, getValues, handleClearFilter, handleFilterChange} = props;

    return (
        <Drawer
            className={'filter-sidebar'}
            icon={icon}
            size={Drawer.SIZE_SMALL}
            onClose={handleClose}
            title={title}
            isOpen={isOpen}
        >
            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>
                        {filterKeys.map((filter) => {
                            return <FilterSidebarFilter
                                label={getLabel(filter)}
                                name={filter}
                                handleClear={PropTypes.func}
                                key={filter}
                                handleClearFilter={handleClearFilter}
                                values={getValues(filter)}
                                onChange={handleFilterChange}
                            />
                        })}
                </div>
            </div>
            <div className={Classes.DRAWER_FOOTER}>{footer}</div>
        </Drawer>
    );
}

FilterSidebar.propTypes = {
    title: PropTypes.string,
    footer: PropTypes.node,
    handleClose: PropTypes.func,
    icon: PropTypes.string,
    isOpen: PropTypes.bool,
    filterKeys: PropTypes.arrayOf(PropTypes.string),
    getLabel: PropTypes.func,
    getValues: PropTypes.func,
    handleFilterChange: PropTypes.func,
    handleClearFilter: PropTypes.func,
};

export default FilterSidebar;
