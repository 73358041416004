import './Loading.css';
import PropTypes from "prop-types";
import React from "react";
import {Spinner} from "reactstrap";


function Loading(props) {
    const {show} = props;

    if (show) {
        return (
            <div className={'loading-frame'}>
                <div className={'spinner-frame'}>
                    <Spinner  style={{ width: '15rem', height: '15rem' }} color="primary"/>
                </div>
            </div>
        )
    } else {
        return <div/>
    }
}

Loading.propTypes = {
    show: PropTypes.bool,
};

export default Loading;