import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './app';
import Reducers from './redux/reducers';
import { loadState /*, saveState*/ } from './localstorage';

const persistedState = loadState();
const store = createStore(Reducers, persistedState);

/*store.subscribe(() => {
    saveState({
        filterSelections: store.getState().filterSelections,
        filterValues: store.getState().filterValues,
    });
});*/

// Get the root element
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Render the app using createRoot
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting
//
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", event => {
//         if (event.target.state === "activated") {
//           window.location.reload()
//         }
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   }
// });
