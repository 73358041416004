import moment from "moment";

export function dateValues() {
  const dates = [];
  for (let i = 1; i <= 12; i++) {
    dates.push(
      moment().add(-i, "months").startOf("month").format("YYYY-MM-DD")
    );
  }
  return dates;
}

export function getSelectedFilters(
  filterSelections,
  filterValues,
  interestedFilters
) {
  const tmp = {};
  interestedFilters.forEach((filter) => {
    // Find all the relevant components of the filter
    const selections = filterSelections[filter];
    const values = filterValues[filter];
    const definition = filterDefinition[filter];

    if (definition.type === "Bool") {
      if (selections) {
        tmp[filter] = values;
      }
    }
    if (["ChoiceList", "ChoiceListWithContext"].includes(definition.type)) {
      if (selections.length > 0) {
        tmp[filter] = selections;
      }
    }
  });
  if (
    "comparison-filters" in filterSelections &&
    filterSelections["comparison-filters"].length > 0
  ) {
    tmp["comparison-filters"] = filterSelections["comparison-filters"];
  }
  return tmp;
}

export function getComparisonFilter(filterSelections, availableFilters) {
  if (
    !("comparison-filters" in filterSelections) ||
    filterSelections["comparison-filters"].length === 0
  ) {
    return null;
  }
  const comparisonFilters = filterSelections["comparison-filters"];
  for (let i = 0; i < comparisonFilters.length; i++) {
    if (availableFilters.includes(comparisonFilters[i])) {
      return comparisonFilters[i];
    }
  }
  return null;
}

export const filterDefinition = {
  appeal: {
    key: "appeal",
    label: "Appeal",
    type: "ChoiceList",
    endpoint: "get_filter_values/appeal",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Appeal is ${value.join(" or ")}`;
    },
  },
  channel: {
    key: "channel",
    label: "Channel",
    type: "ChoiceList",
    endpoint: "get_filter_values/channel",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Channel is ${value.join(" or ")}`;
    },
  },
  campaign: {
    key: "campaign",
    label: "Campaign",
    type: "ChoiceList",
    endpoint: "get_filter_values/campaign",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Campaign is ${value.join(" or ")}`;
    },
  },
  "cancel-reason": {
    key: "cancel-reason",
    label: "Cancel Reason",
    type: "ChoiceList",
    endpoint: "get_filter_values/cancel-reason",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Cancel reason is ${value.join(" or ")}`;
    },
  },
  "freebie-status": {
    key: "freebie-status",
    label: "Freebie Status",
    type: "ChoiceList",
    endpoint: "get_filter_values/freebie-status",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Freebie status is ${value.join(" or ")}`;
    },
  },
  fund: {
    key: "fund",
    label: "Fund",
    type: "ChoiceList",
    endpoint: "get_filter_values/fund",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Fund is ${value.join(" or ")}`;
    },
  },
  fundraiser: {
    key: "fundraiser",
    label: "Fundraiser",
    type: "ChoiceList",
    endpoint: "get_filter_values/fundraiser",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Fundraiser is ${value.join(" or ")}`;
    },
  },
  supplier: {
    key: "supplier",
    label: "Supplier",
    type: "ChoiceList",
    endpoint: "get_filter_values/supplier",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Supplier is ${value.join(" or ")}`;
    },
  },
  "gift-status": {
    key: "gift-status",
    label: "Gift Status",
    type: "ChoiceList",
    endpoint: "get_filter_values/gift-status",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Gift Status is ${value.join(" or ")}`;
    },
  },
  "gift-range": {
    key: "gift-range",
    label: "Gift Range",
    type: "ChoiceList",
    endpoint: "get_filter_values/gift-range",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Gift Range is ${value.join(" or ")}`;
    },
  },
  "age-range": {
    key: "age-range",
    label: "Age Range",
    type: "ChoiceList",
    endpoint: "get_filter_values/age-range",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Donor age range is ${value.join(" or ")}`;
    },
  },
  gender: {
    key: "gender",
    label: "Gender",
    type: "ChoiceList",
    endpoint: "get_filter_values/gender",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Donor gender is ${value.join(" or ")}`;
    },
  },
  "payment-method": {
    key: "payment-method",
    label: "Payment Method",
    type: "ChoiceList",
    endpoint: "get_filter_values/payment-method",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Payment Method is ${value.join(" or ")}`;
    },
  },
  frequency: {
    key: "frequency",
    label: "Frequency",
    type: "ChoiceList",
    endpoint: "get_filter_values/frequency",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Payment frequency is ${value.join(" or ")}`;
    },
  },
  "gift-start-year": {
    key: "gift-start-year",
    label: "Cohort Year",
    type: "ChoiceList",
    endpoint: "get_filter_values/gift-start-year",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Cohort Years are ${value.join(" or ")}`;
    },
  },
  "gift-start-financial-year": {
    key: "gift-start-financial-year",
    label: "Cohort Fiscal Year",
    type: "ChoiceList",
    endpoint: "get_filter_values/gift-start-financial-year",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Cohort Fiscal Years are ${value.join(" or ")}`;
    },
  },
  "transaction-year": {
    key: "transaction-year",
    label: "Transaction Year",
    type: "ChoiceList",
    endpoint: "get_filter_values/transaction-year",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Transaction Years are ${value.join(" or ")}`;
    },
  },
  "transaction-financial-year": {
    key: "transaction-financial-year",
    label: "Transaction Fiscal Year",
    type: "ChoiceList",
    endpoint: "get_filter_values/transaction-financial-year",
    fixedValues: null,
    shortcut: false,
    disambiguator: function (value) {
      return `Transaction Fiscal Years are ${value.join(" or ")}`;
    },
  },
  "gift-start-month": {
    key: "gift-start-month",
    label: "Last 12 Cohorts",
    type: "Bool",
    endpoint: null,
    fixedValues: dateValues(),
    shortcut: false,
    disambiguator: function () {
      return `Last 12 cohorts`;
    },
  },
  "transaction-month": {
    key: "transaction-month",
    label: "Last 12 Transaction Months",
    type: "Bool",
    endpoint: "null",
    fixedValues: dateValues(),
    shortcut: true,
    disambiguator: function () {
      return `Last 12 months of transactions`;
    },
  },
  "forecast-reporting-year": {
    key: "forecast-reporting-year",
    label: "Transaction Calendar Year",
    type: "ChoiceListWithContext",
    endpoint: "get_filter_values/forecast-reporting-year",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Transaction Calendar Years are ${value.join(" or ")}`;
    },
  },
  "forecast-supplier": {
    key: "forecast-supplier",
    label: "Supplier",
    type: "ChoiceList",
    endpoint: "get_filter_values/forecast-supplier",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Suppliers are ${value.join(" or ")}`;
    },
  },
  "forecast-channel": {
    key: "forecast-channel",
    label: "Channel",
    type: "ChoiceList",
    endpoint: "get_filter_values/forecast-channel",
    fixedValues: null,
    shortcut: true,
    disambiguator: function (value) {
      return `Channels are ${value.join(" or ")}`;
    },
  },
};

export const analyticsFilters = [
  "appeal",
  "campaign",
  "cancel-reason",
  "channel",
  "gift-status",
  "supplier",
  "fund",
  "fundraiser",
  "gift-range",
  "age-range",
  "gender",
  "payment-method",
  "frequency",
  "transaction-month",
  "gift-start-month",
  "transaction-financial-year",
  "transaction-year",
  "gift-start-financial-year",
  "gift-start-year",
  "freebie-status",
];
export const forecastFilters = [
  "forecast-reporting-year",
  "forecast-supplier",
  "forecast-channel",
];
export const forecastViewFilters = ["forecast-channel", "forecast-supplier"];
export const forecastRecordFilters = ["forecast-reporting-year"];
