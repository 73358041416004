import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import ContextFilter from '../ContextFilter'
import { filterDefinition } from '../../../app/filters'
import { bindActionCreators } from 'redux'
import {
    modifyFilterSelectionsAction,
    modifyFilterValuesAction,
} from '../../../redux/actions/filters.action'
import { connect } from 'react-redux'
import './ContextFilterBar.css'
import FilterSidebar from '../FilterSidebar'
import { useDebounce } from '../../../app/hooks'
import * as deepEqual from 'deep-equal'

/**
 * Context Filter Bar
 * The filter Bar at the top of the page that has a highlight of the most important filters for an organisation.
 * @param {Object} props the data for the context filter bar.
 * @return {JSX} The context filter bar component rendered.
 */
function ContextFilterBar (props) {
    const { filterSelections, filterValues, parentLoading, shortcutFilters, sidebarFilters } = props
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [tmpFilterSelections, setTmpFilterSelections] = useState(
      filterSelections)
    const debouncedFilter = useDebounce(tmpFilterSelections, 3500)

    /**
     * Effect run whenever the debounced filter updates. The debounced filter controls how often the filter bar
     * propagates updates to its parent component. When the useDebounce hook notifies that a delay of x ms has passed
     * since the last change, this effect runs.
     *
     * Note that there is a secondary filter too that stops the effect from executing if the component is in a loading
     * state. This shouldn't occur in reality, but its a good bit of extra checking.
     */
    useEffect(() => {
        if (debouncedFilter && !(parentLoading)) {
            props.modifyFilterSelectionsAction(tmpFilterSelections)
        }
        // eslint-disable-next-line
    }, [debouncedFilter])

    useEffect(() => {
        if (!deepEqual(filterSelections, tmpFilterSelections)) {
            setTmpFilterSelections(filterSelections)
        }
        // eslint-disable-next-line
    }, [filterSelections])

    /**
     * This function is fired when a single filters value is changed. When the value changes, an event is fired with
     * the filter's key, the filter value being modified and the new value of the filter.
     * @param filterKey The filter to update the value of.
     * @param valueKey The value of the filter to toggle the value of.
     */
    function handleFilterChange (filterKey, valueKey) {
        const selections = { ...tmpFilterSelections }
        const filterSelection = selections[filterKey] || []
        const filter = filterDefinition[filterKey]
        if (filter.type === 'Bool') {
            selections[filterKey] = !selections[filterKey]
        }
        if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
            const valueIndex = filterSelection.indexOf(valueKey)
            if (valueIndex !== -1) {
                filterSelection.splice(valueIndex, 1)
            } else {
                filterSelection.push(valueKey)
            }
            selections[filterKey] = filterSelection
            // If the filter is eligible for comparison (more than 1 value)
            if (filterSelection.length > 1) {
                // This is the first time the filter has been applied.
                if (!(selections['comparison-filters'].includes(filterKey))) {
                    selections['comparison-filters'].push(filterKey)
                }
            } else {
                // The filter is not eligible for comparison, remove it from the list if it exists
                const comparisonIndex = selections['comparison-filters'].indexOf(
                  filterKey)
                if (comparisonIndex !== -1) {
                    selections['comparison-filters'].splice(comparisonIndex, 1)
                }
            }
        }
        setTmpFilterSelections(selections)
    }

    /**
     * Clear an individual filter of its values.
     * @param filterKey The filter to clear all selections from.
     */
    function handleFilterClear (filterKey) {
        const selections = { ...tmpFilterSelections }
        const filter = filterDefinition[filterKey]
        if (filter.type === 'Bool') {
            selections[filterKey] = false
            setTmpFilterSelections(selections)
        }
        if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
            if (selections[filterKey].length > 0) {
                selections[filterKey] = []
            }
            const comparisonFilterIndex = selections['comparison-filters'].indexOf(
              filterKey)
            if (comparisonFilterIndex > -1) {
                selections['comparison-filters'].splice(comparisonFilterIndex,
                  1)
            }
        }
        setTmpFilterSelections(selections)
    }

    /**
     * Clear all filters of all values.
     */
    function handleClearAll () {
        const selections = { ...tmpFilterSelections }
        Object.keys(selections).forEach((key) => {
            const filter = filterDefinition[key]
            if (filter === undefined){
                return
            }
            if (filter.type === 'Bool') {
                selections[key] = false
            }
            if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
                if (selections[key].length > 0) {
                    selections[key] = []
                }
            }
            setTmpFilterSelections(selections)
        })
    }

    /**
     * Return the label of a filter key.
     * @param filterKey The filter to lookup.
     * @returns {string} The label for the filter.
     */
    function getLabel (filterKey) {
        return filterDefinition[filterKey].label
    }

    /**
     * For a given filter, fetch the dropdown values that child components expect in an array. When queried, the
     * function will return an array with 1 entry per filter value, with the key set to the filter's name.
     * Each object will also have a selected attribute showing if this value is currently selected.
     * @param filterKey
     * @returns {[]}
     */
    function getValues (filterKey) {
        const rtn = []
        const filter = filterDefinition[filterKey]
        if (filter.type === 'Bool') {
            if (filterKey in filterValues) {
                rtn.push({
                    key: 'Enabled',
                    selected: tmpFilterSelections[filterKey] === true,
                })
            }
        }
        if (['ChoiceList', 'ChoiceListWithContext'].includes(filter.type)) {
            if (filterKey in filterValues) {
                Object.keys(filterValues[filterKey]).forEach((index) => {
                    rtn.push({
                        key: filterValues[filterKey][index],
                        selected: tmpFilterSelections[filterKey].includes(
                          filterValues[filterKey][index]),
                    })
                })
            }
        }
        return rtn
    }

    return (
      <div className={'context-filters'}>
          <div className={'filters-row'}>
              {shortcutFilters.map((filter) => {
                  return <ContextFilter
                    label={getLabel(filter)}
                    name={filter}
                    key={filter}
                    fill={true}
                    handleFilterClear={handleFilterClear}
                    loading={parentLoading}
                    values={getValues(filter)}
                    onChange={handleFilterChange}
                  />
              })}
          </div>
          {!deepEqual(shortcutFilters,sidebarFilters) &&
          <Button
            className={'more-filters'}
            disabled={parentLoading}
            icon={'more'}
            fill={true}
            /*text={'More Filters'}*/
            onClick={() => {
                setSidebarOpen(!sidebarOpen)
            }}
            minimal/>
          }
          <FilterSidebar
            title={'More Filters'}
            isOpen={sidebarOpen}
            handleClose={() => setSidebarOpen(false)}
            icon={'search'}
            handleClearFilter={handleFilterClear}
            filterKeys={sidebarFilters}
            getLabel={getLabel}
            getValues={getValues}
            handleFilterChange={handleFilterChange}
            footer={<div
              style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                <Button minimal onClick={() => setSidebarOpen(false)}
                        text={'Close'}/>
                <Button minimal onClick={() => handleClearAll()}
                        text={'Clear All Filters'}/>
            </div>}
          />
      </div>

    )
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
    return {
        filterSelections,
        filterValues,
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        modifyFilterSelectionsAction: modifyFilterSelectionsAction,
        modifyFilterValuesAction: modifyFilterValuesAction,
    }, dispatch)
}

ContextFilterBar.propTypes = {
    shortcutFilters: PropTypes.arrayOf(PropTypes.string),
    sidebarFilters: PropTypes.arrayOf(PropTypes.string),
    modifyFilterSelectionsAction: PropTypes.func,
    parentLoading: PropTypes.bool,
    filterSelections: PropTypes.object,
    filterValues: PropTypes.object,
}

export default connect(mapStateToProps, mapActionsToProps)(ContextFilterBar)
