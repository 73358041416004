import {
  getData,
} from '../../app/data';

// Help page FAQ/Help content
export const getHelpData = async (searchString, helpTypes) => {
  const payload = {searchField: searchString};
  const payloadHelpTypes = [];
  if (helpTypes !== null && helpTypes.length >0) {
    helpTypes.map((item) => {
      payloadHelpTypes.push(item.value);
      return null;
    });
    payload.helpType = payloadHelpTypes;
  }
  return getData('get_help', payload).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    return res;
  });
};

// Help Page Radio Button Types
export const getHelpTypeData = async () => {
  const res = await getData('get_help_type');
  const rtn = [];
  if (res === null || res.length === 0) {
    return rtn;
  }
  res.forEach(function(element) {
    rtn.push({value: element, label: element});
  });
  return rtn;
};
