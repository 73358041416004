import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Navigate } from 'react-router-dom';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import ReactDataSheet from 'react-datasheet';
import Datetime from 'react-datetime';
import { Button, Spinner, Alert, UncontrolledTooltip, Input } from 'reactstrap';
import 'react-datasheet/lib/react-datasheet.css';
import 'react-datetime/css/react-datetime.css';
import './ForecastNew.css';
import {
  getDefaultAttritionGrid,
  getDefaultForecastDonorGrid,
  getForecastAttritionGrid,
  getForecastDonorGrid,
  isNan,
  isPercentage,
  parsePercentage,
} from '../../app/utils';
import { getData, postPayloadData } from '../../app/data';
import { getForecastFormData } from './data';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modifyFilterValuesAction } from '../../redux/actions/filters.action';
import { filterDefinition } from '../../app/filters';

/**
 * Forecast New Component
 */
class ForecastNew extends Component {
  /**
   * Default constructor for the component, calls the super component as well
   * as any required other functions or state setup.
   * @param {Object} props the data for the navigation element
   * @props {JSX}
   */
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.state = {
      alertVisible: true,
      isLoading: false,
      errorMsg: '',
      donorGrid: getDefaultForecastDonorGrid(),
      attritionGrid: getDefaultAttritionGrid(),
      avgGift: '0.00',
      preDebitFail: '0.00',
      cpa: '0.00',
      dcc: '0.00',
      transactionCost: '0.00',
      declinePct: '0.00',
      startingMonth: '',
      versionName: '',
      pfra: false,
      selectedForecastChannel: null,
      forecastChannels: [],
      selectedForecastSupplier: null,
      forecastSuppliers: [],
      postSuccess: false,
      newForecastVersionId: null,
      hasChanged: false,
    };
  }

  /**
   * Dismisses an alert by making it invisible. This resets the error message
   * to allow for future alerts to take its place.
   */
  onDismiss() {
    this.setState({
      alertVisible: false,
      errorMsg: '',
    });
  }

  /**
   * Shows a new alert. If an alert is already showing it overwrites it with
   * this one.
   * @param {String} message The message to show on the alert.
   */
  showAlert(message) {
    this.setState({
      isLoading: false,
      alertVisible: true,
      errorMsg: message,
    });
  }

  /**
   * Resets all alerts so that they don't distract the user whilst the screen
   * does something else.
   */
  hideAlerts() {
    this.setState({
      alertVisible: false,
      errorMsg: '',
    });
  }

  /**
   * Called immediately after the component mounts. Fetches the default
   * values for the select options.
   * @return {void}
   */
  async componentDidMount() {
    const promises = [];
    const tmpFilterValues = this.props.filterValues;
    // Check if the filters need refreshing - force a refresh if the parent
    // component supplied a context
    if (
      moment(this.props.filterValues['last-refreshed']).diff(moment(), 'minutes') > -60 &&
      this.props.context === undefined
    ) {
      this.setState({
        forecastChannels: this.props.filterValues['forecast-channel'].map((element) => {
          return { value: element, label: element };
        }),
        forecastSuppliers: this.props.filterValues['forecast-supplier'].map((element) => {
          return { value: element, label: element };
        }),
      });
      return;
    }
    Object.keys(tmpFilterValues).forEach((key) => {
      const filter = filterDefinition[key];
      if (filter === undefined) {
        return;
      }
      if (filter.type === 'Bool') {
        tmpFilterValues[key] = filter.fixedValues;
      }
      if (filter.type === 'ChoiceList') {
        promises.push(
          getData([filter.endpoint], {}).then((res) => {
            tmpFilterValues[key] = res.map((e) => {
              return e;
            });
          }),
        );
      }
      if (filter.type === 'ChoiceListWithContext') {
        if (this.props.context !== undefined) {
          promises.push(
            getData([filter.endpoint], this.props.context).then((res) => {
              tmpFilterValues[key] = res.map((e) => {
                return e;
              });
            }),
          );
        }
      }
    });
    // Only set loading to false once all promises have finished fetching their data.
    Promise.all(promises).then(() => {
      tmpFilterValues['last-refreshed'] = moment();
      this.props.modifyFilterValuesAction(tmpFilterValues);
      this.setState({
        forecastChannels: this.props.filterValues['forecast-channel'].map((element) => {
          return { value: element, label: element };
        }),
        forecastSuppliers: this.props.filterValues['forecast-supplier'].map((element) => {
          return { value: element, label: element };
        }),
      });
    });

    if (this.props.match && 'forecast_version_id' in this.props.match.params) {
      const forecastVersionId = this.props.match.params.forecast_version_id;
      getForecastFormData(forecastVersionId).then((res) => {
        // Don't load the form data if nothing was received from the backend
        if (res === null || res.message) {
          return;
        }
        this.setState({
          selectedForecastChannel: { value: res.channel, label: res.channel },
          selectedForecastSupplier: {
            value: res.supplier,
            label: res.supplier,
          },
          avgGift: res['expectedAverageGift'].toFixed(2),
          preDebitFail: (res['noFirstGiftPercentage'] * 100).toFixed(2),
          cpa: res.costPerAcquisition.toFixed(2),
          dcc: res.donorCareCost.toFixed(2),
          pfra: res.pfra,
          transactionCost: (res.transactionCostPercentage * 100).toFixed(2),
          declinePct: (res.declinePercentage * 100).toFixed(2),
          startingMonth: res.startingForecastMonth,
          versionName: res.versionName,
          donorGrid: getForecastDonorGrid(res.donorsPerMonth),
          attritionGrid: getForecastAttritionGrid(res.attritionPerMonth),
        });
      });
    }
  }

  /**
   * Function executed when the Average Gift field is modified
   * @param {Object} e The event triggering the function.
   */
  handleAvgGiftChange(e) {
    const value = e.target.value;
    this.setState({
      avgGift: value,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the Pre Debit Fail % field is modified
   * @param {Object} e The event triggering the function.
   */
  handlePreDebitFailChange(e) {
    const value = e.target.value;
    this.setState({
      preDebitFail: value,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the CPA field is modified
   * @param {Object} e The event triggering the function.
   */
  handleCPAChange(e) {
    const value = e.target.value;
    this.setState({
      cpa: value,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the donor care cost field is modified
   * @param {Object} e The event triggering the function.
   */
  handleDCCChange(e) {
    const value = e.target.value;
    this.setState({
      dcc: value,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the transaction cost percentage field is modified
   * @param {Object} e The event triggering the function.
   */
  handleTransactionCostChange(e) {
    const value = e.target.value;
    this.setState({
      transactionCost: value,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the decline percentage field is modified
   * @param {Object} e The event triggering the function.
   */
  handleDeclinePctChange(e) {
    const value = e.target.value;
    this.setState({
      declinePct: value,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the starting month field is modified
   * @param {String} e The event triggering the function.
   */
  handleStaringMonthChange(e) {
    const d = new Date(e);
    const date = moment(d).format('YYYY-MM').toString();
    this.setState({
      startingMonth: date,
      hasChanged: true,
    });
  }

  /**
   * Handle the PFRA checkbox changing value, update the state accordingly.
   * @param {Object} e The event triggering the PFRA change.
   */
  handlePFRAChange(e) {
    this.setState({
      pfra: e.target.checked,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the forecast supplier field is modified
   * @param {Object} e The event triggering the function.
   */
  handleForecastSupplierChange(e) {
    this.setState({
      selectedForecastSupplier: e,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the forecast channel field is modified
   * @param {Object} e The event triggering the function.
   */
  handleForecastChannelChange(e) {
    this.setState({
      selectedForecastChannel: e,
      hasChanged: true,
    });
  }

  /**
   * Function executed when the version name field is modified
   * @param {Object} e The event triggering the function.
   */
  handleVersionNameChange(e) {
    const value = e.target.value;
    this.setState({
      versionName: value,
      hasChanged: true,
    });
  }

  /**
   * Handle the submission of the entire new forecast form. This will first
   * validate all the data as an initial pass. This will make it easier for
   * the backend when it does its processing as well as make responses snappier
   * for the user.
   *
   * Once validation is complete, a payload is built and posted to the server.
   * @param {Object} e The event triggering the function.
   */
  async handleFormSubmit(e) {
    e.preventDefault();
    // Set loading to true so we can have a sick spinner
    this.setState({ isLoading: true });
    // Validate the form
    // First get all the variables from the state of the component
    const {
      donorGrid,
      attritionGrid,
      avgGift,
      preDebitFail,
      cpa,
      dcc,
      pfra,
      transactionCost,
      declinePct,
      versionName,
      startingMonth,
      selectedForecastChannel,
      selectedForecastSupplier,
    } = this.state;
    // These variables hold passed versions of the fields
    const donorPerMonth = [];
    const attritionPerMonth = [];
    // Now parse each variable to ensure they're not null and meet other DQ
    // requirements such as value range.
    // Average Gift
    if (isNaN(avgGift) || avgGift < 0 || avgGift === undefined) {
      this.showAlert('Average Gift must be greater than 0.');
      return;
    }
    // Pre Debit fail
    if (
      isNaN(preDebitFail) ||
      preDebitFail < 0 ||
      preDebitFail > 100 ||
      preDebitFail === undefined
    ) {
      this.showAlert('Pre Debit Fail must be between 0 and 100.');
      return;
    }
    const preDebitFailPct = parsePercentage(preDebitFail) / 100;
    // Cost per Acquisition
    if (isNaN(cpa) || cpa < 0 || cpa === undefined) {
      this.showAlert('Cost per Acquisition must be greater than 0.');
      return;
    }
    // Donor Care Cost
    if (isNaN(dcc) || dcc < 0 || dcc === undefined) {
      this.showAlert('Donor Care cost must be greater than 0.');
      return;
    }
    // Transaction Cost %
    if (
      isNaN(transactionCost) ||
      transactionCost < 0 ||
      transactionCost > 100 ||
      transactionCost === undefined
    ) {
      this.showAlert('Transaction Cost % must be between 0 and 100.');
      return;
    }
    const transactionCostPct = parsePercentage(transactionCost) / 100;
    // Decline %
    if (isNaN(declinePct) || declinePct < 0 || declinePct > 100 || declinePct === undefined) {
      this.showAlert('Decline % must be between 0 and 100.');
      return;
    }
    const declinePctPct = parsePercentage(declinePct) / 100;
    // Starting Month
    if (startingMonth === undefined || startingMonth === '') {
      this.showAlert('Starting Forecast Month must be populated.');
      return;
    }
    // Version Name
    if (versionName === undefined || versionName === '') {
      this.showAlert('Version name is required.');
      return;
    }
    // Forecast Channel
    if (selectedForecastChannel === null || selectedForecastChannel === '') {
      this.showAlert('Please select a channel for this forecast.');
      return;
    }
    // Forecast Supplier
    if (selectedForecastSupplier === null || selectedForecastSupplier === '') {
      this.showAlert('Please select a supplier for this forecast.');
      return;
    }
    // Parse the Donor Grid
    // Define a payload variable that we can construct whilst parsing the input
    // data. This will be posted to the backend later.
    for (let i = 1; i < donorGrid.length; i++) {
      const rtn = [];
      for (let j = 1; j < donorGrid[i].length; j++) {
        // Parse the value, returning an error message if required.
        if (isNan(donorGrid[i][j].value) && donorGrid[i][j].value !== undefined) {
          this.showAlert(
            'Donors per Month table must only contain numbers. ' +
              '(Row ' +
              i +
              ', Column ' +
              j +
              ')',
          );
          return;
        }
        if (i === 1 && donorGrid[i][j].value === undefined) {
          this.showAlert('The first year of Donor data is required.');
          return;
        }
        // Push the value onto the return array, if its undefined as the table
        // cell is blank, set it to 0.
        if (
          donorGrid[i][j].value === undefined ||
          donorGrid[i][j].value === null ||
          donorGrid[i][j].value === ''
        ) {
          rtn.push('0');
        } else {
          rtn.push(donorGrid[i][j].value);
        }
      }
      // Append to the payload variable
      donorPerMonth.push(rtn);
    }
    // Parse the Attrition Grid
    // Define a payload variable that we can construct whilst parsing the input
    // data. This will be posted to the backend later.
    for (let i = 1; i < attritionGrid.length; i++) {
      const rtn = [];
      for (let j = 1; j < attritionGrid[i].length; j++) {
        // Parse the value, returning an error message if required.
        if (!isPercentage(attritionGrid[i][j].value) && attritionGrid[i][j].value !== undefined) {
          this.showAlert(
            'Attrition table must only contain percentages. ' + '(Row ' + i + ', Column ' + j + ')',
          );
          return;
        }
        // Push the value onto the return array, if its undefined as the table
        // cell is blank, set it to 0.
        if (
          attritionGrid[i][j].value === undefined ||
          attritionGrid[i][j].value === null ||
          attritionGrid[i][j].value === ''
        ) {
          rtn.push('0');
        } else {
          rtn.push(parsePercentage(attritionGrid[i][j].value) / 100);
        }
      }
      // Append to the payload variable
      attritionPerMonth.push(rtn);
    }
    // Hide alerts as we've finished all validation tasks
    this.hideAlerts();
    // Define the payload
    const payload = {
      versionName: versionName,
      channel: selectedForecastChannel.value,
      supplier: selectedForecastSupplier.value,
      startingForecastMonth: startingMonth,
      averageGift: avgGift,
      noFirstGift: preDebitFailPct,
      costPerAcquisition: cpa,
      pfra: pfra,
      donorCareCost: dcc,
      transactionCostPercentage: transactionCostPct,
      declinePercentage: declinePctPct,
      donorsPerMonth: donorPerMonth,
      attritionPerMonth: attritionPerMonth,
    };
    // Post the payload to the backend.
    await postPayloadData('forecast/add', payload)
      .then((res) => {
        if (res.success) {
          this.setState({
            postSuccess: true,
            newForecastVersionId: res.forecast_version_id,
            isLoading: false,
          });
        } else {
          const errorMsg = Object.hasOwnProperty.call(res, 'message')
            ? res.message
            : 'An internal error occurred, ' + 'please try again later.';
          this.showAlert(errorMsg);
        }
      })
      .catch(() => {
        this.showAlert('An internal error occurred, please try again later.');
      });
  }

  /**
   * The Forecast Section - New Route
   * @return {JSX}
   */
  render() {
    const {
      selectedForecastChannel,
      selectedForecastSupplier,
      forecastChannels,
      forecastSuppliers,
      postSuccess,
      newForecastVersionId,
      isLoading,
      errorMsg,
      hasChanged,
    } = this.state;
    // The Spinner icon if the isLoading is true
    let loadingSpinner;
    if (isLoading) {
      loadingSpinner = <Spinner color="primary" />;
    }
    let errorFlash;
    if (errorMsg.length > 0) {
      errorFlash = (
        <Alert color="danger" isOpen={this.state.alertVisible} toggle={this.onDismiss}>
          {errorMsg}
        </Alert>
      );
    }
    if (postSuccess) {
      const url = '/forecast/results?forecast_version_id[]=' + newForecastVersionId;
      return <Navigate to={url} />;
    }
    return (
      <div className="wholepage">
        <header>
          <PrimaryBar />
          <SecondaryBar />
        </header>
        <div className="main-content">
          <div className="forecast">
            <div className="runforcastform_secttion">
              <div className="forecast_container">
                {errorFlash}
                <h2>Run a New Forecast</h2>
                <div className="fform_wrapper">
                  <div className="fleft_form">
                    <div className="fleft_tform">
                      <div className="form-group">
                        <UncontrolledTooltip placement={'right'} target="forecast-channel-label">
                          The channel that will supply donors in this scenario.
                        </UncontrolledTooltip>
                        <label className="forecast_label">Channel</label>
                        <div className="selectdiv" data-cy={'new_forecast-dropdown-channel'}>
                          <CreatableSelect
                            id="forecast-channel-label"
                            className="select-component"
                            options={forecastChannels}
                            value={selectedForecastChannel}
                            onChange={this.handleForecastChannelChange.bind(this)}
                            formatCreateLabel={(inputValue) => {
                              return `Add New: "${inputValue}"`;
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <UncontrolledTooltip placement={'right'} target="forecast-supplier-select">
                          The supplier that will supply donors in this scenario.
                        </UncontrolledTooltip>
                        <label className="forecast_label">Supplier</label>
                        <div
                          className="selectdiv"
                          id="forecast-supplier-select"
                          data-cy={'new_forecast-dropdown-supplier'}
                        >
                          <CreatableSelect
                            className="select-component"
                            options={forecastSuppliers}
                            value={selectedForecastSupplier}
                            onChange={this.handleForecastSupplierChange.bind(this)}
                            formatCreateLabel={(inputValue) => {
                              return `Add New: "${inputValue}"`;
                            }}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Include PFRA Cost</label>
                        <UncontrolledTooltip placement={'right'} target="pfra">
                          Include a levy of $1.70 per acquired donor for Public Fundraising
                          Regulatory Association membership.
                        </UncontrolledTooltip>
                        <div className="" id={'pfra'} data-cy={'new_forecast-checkbox-prfa'}>
                          <Input
                            type="checkbox"
                            className={'forecast-new-check-input'}
                            checked={this.state.pfra}
                            onChange={this.handlePFRAChange.bind(this)}
                          />{' '}
                          <label className={'pfra-label'}>$1.70 per acquired donor.</label>
                        </div>
                      </div>
                    </div>
                    <div className="forecast_btn" data-cy={'new_forecast-button-submit'}>
                      <Button
                        className="self_btn yellow_btn"
                        onClick={this.handleFormSubmit.bind(this)}
                        disabled={!hasChanged}
                      >
                        Submit Forecast
                      </Button>
                      {loadingSpinner}
                    </div>
                    <NavLink end to="/forecast/new" className="clear_link">
                      Clear all and start again
                    </NavLink>
                  </div>
                  <div className="f_right_form">
                    <div className="frightt_form">
                      <div className="form-group">
                        <label className="forecast_label">Expected Average Gift</label>
                        <UncontrolledTooltip placement={'right'} target="fc_avg_gift">
                          The average gift you agree with your supplier.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-avg_gift'}>
                          <input
                            type="text"
                            name="avg_gift"
                            id="fc_avg_gift"
                            placeholder="0.00"
                            value={this.state.avgGift}
                            onChange={this.handleAvgGiftChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Pre-Debt Fail %</label>
                        <UncontrolledTooltip placement={'right'} target="fc_pre_debit_fail">
                          The average pre-debit failure percentage you predict.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-pre_debit_fail'}>
                          <input
                            type="text"
                            name="pre-debit-fail"
                            placeholder="0.00"
                            id={'fc_pre_debit_fail'}
                            value={this.state.preDebitFail}
                            onChange={this.handlePreDebitFailChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Cost Per Acquisition</label>
                        <UncontrolledTooltip placement={'right'} target="fc_cpa">
                          The average cost per acquisition across all donors you agree with your
                          supplier.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-cpa'}>
                          <input
                            type="text"
                            name="cpa"
                            id={'fc_cpa'}
                            placeholder="0.00"
                            value={this.state.cpa}
                            onChange={this.handleCPAChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Donor Care Cost</label>
                        <UncontrolledTooltip placement={'right'} target="fc_dcc">
                          The average cost of maintaining your donors on a monthly basis. This is
                          the amount for each individual donor, per month.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-donor_care_cost'}>
                          <input
                            type="text"
                            name="dcc"
                            placeholder="0.00"
                            value={this.state.dcc}
                            id={'fc_dcc'}
                            onChange={this.handleDCCChange.bind(this)}
                          />
                        </div>
                        <label className="f_label">$ per donor per month</label>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Transaction Cost %</label>
                        <UncontrolledTooltip placement={'right'} target="fc-tc">
                          The cost in dollars and cents for each transaction from your payment
                          gateway provider and banking institution.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-transaction_cost'}>
                          <input
                            type="text"
                            name="transaction_cost"
                            value={this.state.transactionCost}
                            id={'fc-tc'}
                            placeholder="0.00"
                            onChange={this.handleTransactionCostChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Decline %</label>
                        <UncontrolledTooltip placement={'right'} target="fc-decl">
                          The average percentage of transactions that are declined when requested by
                          the payment gateway provider.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-decline'}>
                          <input
                            type="text"
                            name="decline_pct"
                            id={'fc-decl'}
                            value={this.state.declinePct}
                            placeholder="0.00"
                            onChange={this.handleDeclinePctChange.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Starting Forecast Month</label>
                        <UncontrolledTooltip placement={'right'} target="startingMonth">
                          The month and year that this campaign will start. This can be in the past
                          or the future.
                        </UncontrolledTooltip>
                        <div
                          className="input_div"
                          id={'startingMonth'}
                          data-cy={'new_forecast-input-start_month'}
                        >
                          <Datetime
                            dateFormat="YYYY-MM"
                            timeFormat={false}
                            type="date"
                            input
                            value={this.state.startingMonth}
                            name={'startingMonth'}
                            onChange={this.handleStaringMonthChange.bind(this)}
                            required={true}
                          />
                        </div>
                        <label className="f_label">Month 1 of Year 1</label>
                      </div>
                      <div className="form-group">
                        <label className="forecast_label">Version Name</label>
                        <UncontrolledTooltip placement={'right'} target="fc-vers">
                          A helpful name for remembering this specific version of this
                          supplier/channel combination. For example, &apos;Higher Decline
                          Forecast&apos;.
                        </UncontrolledTooltip>
                        <div className="input_div" data-cy={'new_forecast-input-version_name'}>
                          <input
                            type="text"
                            name="version_name"
                            id={'fc-vers'}
                            value={this.state.versionName}
                            placeholder=""
                            onChange={this.handleVersionNameChange.bind(this)}
                          />
                        </div>
                        <label className="f_label">Describe this forecast.</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="donor_section">
              <div className="donor_container">
                <UncontrolledTooltip placement={'right'} target="fc-dpm-title">
                  This table contains 1 cell per month-year of the forecast. Enter the number of
                  donors you forecast to acquire in that month so that the total profit & expenses
                  can be calculated. The first year is mandatory, if no data is supplied it is
                  extrapolated.
                </UncontrolledTooltip>
                <h2>Donors per month</h2>
                <div className="donor_content">
                  <div
                    className="donor_table_content"
                    id={'fc-dpm-title'}
                    data-cy={'new_forecast-input-signups'}
                  >
                    <ReactDataSheet
                      data={this.state.donorGrid}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) => {
                        const dg = this.state.donorGrid.map((row) => [...row]);
                        // eslint-disable-next-line no-unused-vars
                        changes.forEach(({ _, row, col, value }) => {
                          dg[row][col] = { ...dg[row][col], value };
                        });
                        this.setState({
                          donorGrid: dg,
                          hasChanged: true,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="attr_section">
                  <h2>Attrition</h2>
                  <div className="attr_table_section">
                    <UncontrolledTooltip placement={'right'} target="fc-att-title">
                      This table contains 1 cell per month-year of the forecast. Enter the estimated
                      attrition rate throughout the forecast period. The values provided are the
                      benchmarked expected attrition values for donors over a 5 year period.
                    </UncontrolledTooltip>
                    <div
                      className="attr_table_data"
                      id="fc-att-title"
                      data-cy={'new_forecast-input-attrition'}
                    >
                      <ReactDataSheet
                        data={this.state.attritionGrid}
                        valueRenderer={(cell) => cell.value}
                        onCellsChanged={(changes) => {
                          const ag = this.state.attritionGrid.map((row) => [...row]);
                          // eslint-disable-next-line no-unused-vars
                          changes.forEach(({ _, row, col, value }) => {
                            // Insert a percentage sign at the end of the value
                            if (!value.endsWith('%')) {
                              value += '%';
                            }
                            if (value === '%') {
                              value = '0.00%';
                            }
                            ag[row][col] = { ...ag[row][col], value };
                          });
                          this.setState({
                            attritionGrid: ag,
                            hasChanged: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <Footer />
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch,
  );
};

ForecastNew.propTypes = {
  match: PropTypes.object,
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
  modifyFilterValuesAction: PropTypes.func,
  context: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(ForecastNew);
