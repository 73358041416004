import React, { useEffect, useState } from "react";
import PrimaryBar from "../../components/PrimaryBar";
import SecondaryBar from "../../components/SecondaryBar";
import Footer from "../../components/Footer";
import "./AnalyticsDataQuality.css";
import PropTypes from "prop-types";
import { analyticsFilters, getSelectedFilters } from "../../app/filters";
import InformationCard from "../../components/DataQualityCard";
import getDataQualityData from "./data";
import { formatValue } from "../../app/utils";
import ContextBar from "../../components/V2/ContextBar/ContextBar";
import { bindActionCreators } from "redux";
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from "../../redux/actions/filters.action";
import { connect } from "react-redux";

/**
 * Analytics Data Quality Component
 * @return {JSX} The component rendered
 */
function AnalyticsDataQuality(props) {
  const { filterSelections, filterValues } = props;
  const [isLoading, setLoading] = useState(true);

  const [data, setData] = useState({
    blankChannel: "...",
    unknownAge: "...",
    youngDonors: "...",
    lowestGift: "...",
    dataAsOf: "...",
    youngestDonorAge: "...",
    highestGiftAmount: "...",
    oldestDonorAge: "...",
    uniqueDonors: "...",
    uniqueDonorsWithTrans: "...",
    uniqueCampaigns: "...",
    uniqueAppeals: "...",
    uniqueChannels: "...",
    uniqueSuppliers: "...",
    uniqueMasterGifts: "...",
  });

  useEffect(() => {
    setLoading(true);
    const filters = getSelectedFilters(
      filterSelections,
      filterValues,
      analyticsFilters
    );
    getDataQualityData(filters).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, [filterSelections, filterValues]);

  return (
    <div className={"wholepage"}>
      <header>
        <PrimaryBar />
      </header>
      <div className={"main-content"}>
        <SecondaryBar />
        <ContextBar
          title={"Data Quality"}
          parentLoading={isLoading}
          footer={
            "This page shows some data quality figures for your data set."
          }
          shortcutFilters={[
            "channel",
            "campaign",
            "supplier",
            "gift-start-year",
            "transaction-month",
          ]}
          sidebarFilters={analyticsFilters}
        />
        <div className="content_section">
          <div className="page-content no-pad-top">
            <div className="dataq_section">
              <div className="dataq_mcontainer">
                <div className="dataq_container">
                  <div className="dataq_row data_q">
                    <div className="dataq_mcontent">
                      <h3>Completeness</h3>
                      <InformationCard
                        componentSelectorName="data_quality-card-blank_channels"
                        title={"Blank Channels"}
                        measure={formatValue(data.blankChannel, "###,##0")}
                        toolTipText={
                          "The number of regular giving subscriptions without a channel."
                        }
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-unknown_age"
                        title={"Unknown Age"}
                        measure={formatValue(data.unknownAge, "##0.0#%")}
                        toolTipText={"The number of donors with no birth date."}
                      />
                    </div>
                    <div className="dataq_mcontent">
                      <h3>Quality</h3>
                      <InformationCard
                        componentSelectorName="data_quality-card-underage_donors"
                        title={"Donors Under 21"}
                        measure={formatValue(data.youngDonors, "##0.0#%")}
                        toolTipText={
                          "The number of donors under the age of 21."
                        }
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-latest_transaction_date"
                        title={"Latest Transaction Date"}
                        measure={data.dataAsOf}
                        toolTipText={
                          "The maximum transaction date present in the supplied dataset."
                        }
                      />
                    </div>
                  </div>
                  <div className="dataq_row data_q">
                    <div className="dataq_mcontent">
                      <h3>Minimum</h3>
                      <InformationCard
                        componentSelectorName="data_quality-card-min_amount"
                        title={"Lowest Gift Amount"}
                        toolTipText={
                          "The minimum gift value across all donors."
                        }
                        measure={formatValue(data.lowestGift, "$##0.00")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-youngest_donor"
                        title={"Youngest Donor Age"}
                        toolTipText={"The youngest donor across supplied data."}
                        measure={formatValue(data.youngestDonorAge, "###,##0")}
                      />
                    </div>
                    <div className="dataq_mcontent">
                      <h3>Maximum</h3>
                      <InformationCard
                        componentSelectorName="data_quality-card-max_amount"
                        title={"Highest Gift Amount"}
                        toolTipText={
                          "The highest recorded gift value across all donors."
                        }
                        measure={formatValue(data.highestGiftAmount, "$##0.00")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-oldest_donor"
                        title={"Oldest Donor Age"}
                        toolTipText={
                          "The oldest donor across all supplied data."
                        }
                        measure={formatValue(data.oldestDonorAge, "###,##0")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="dataq_mcontainer uniqueness">
                <div className="dataq_container">
                  <div className="dataq_row uniqueness">
                    <div className="dataq_mcontent">
                      <h3>Uniqueness</h3>
                      <InformationCard
                        componentSelectorName="data_quality-card-donor_count"
                        title={"Number of Donors"}
                        toolTipText={
                          "The unique number of donors, regardless" +
                          " of any transactions made. Note that if transaction" +
                          " or gift filters are applied, this measure will" +
                          " not change."
                        }
                        measure={formatValue(data.uniqueDonors, "###,##0")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-paid_donors_count"
                        title={"Number of Donors with Transactions"}
                        toolTipText={
                          "The unique number of donors who have" +
                          " had a transaction attempted at any stage in any" +
                          " gift."
                        }
                        measure={formatValue(
                          data.uniqueDonorsWithTrans,
                          "###,##0"
                        )}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-master_gift_count"
                        title={"Number of Master Gifts"}
                        toolTipText={
                          "The unique number of master gifts (subscriptions) across all regular giving."
                        }
                        measure={formatValue(data.uniqueMasterGifts, "###,##0")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-campaign_count"
                        title={"Number of Campaigns"}
                        toolTipText={"The unique number of campaigns supplied."}
                        measure={formatValue(data.uniqueCampaigns, "###,##0")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-appeal_count"
                        title={"Number of Appeals"}
                        toolTipText={"The unique number of appeals supplied."}
                        measure={formatValue(data.uniqueAppeals, "###,##0")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-channel_count"
                        title={"Number of Channels"}
                        toolTipText={"The unique number of channels supplied."}
                        measure={formatValue(data.uniqueChannels, "###,##0")}
                      />
                      <InformationCard
                        componentSelectorName="data_quality-card-supplier_count"
                        title={"Number of Suppliers"}
                        toolTipText={
                          "The unique number of suppliers across all data."
                        }
                        measure={formatValue(data.uniqueSuppliers, "###,##0")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch
  );
};

AnalyticsDataQuality.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AnalyticsDataQuality);
