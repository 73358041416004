import promiseAllProperties from "promise-all-properties";
import { getData, withYearComparison } from "../../app/data";
import moment from "moment";

const getAnalyticsKPIData = (filters) => {
  const promises = {
    activeDonors: getActiveDonors({ ...filters }),
    latestSignups: getLatestSignups(filters),
    totalRevenue: getTotalLifetimeRevenue({ ...filters }),
    latestRevenue: getLatestRevenue(filters),
    averageSignups: getAverageSignups({ ...filters }),
    latestAverageSignups: getLatestAverageSignups(filters),
    averageGift: getAverageGift({ ...filters }),
    latestAverageGift: getLatestGiftPerDonor(filters),
    retention: getRetention({ ...filters }),
    latestRetention: getLatestRetention(filters),
    averageDecline: getAverageDecline({ ...filters }),
    latestDecline: getLatestDecline(filters),
    averageCPA: getAverageCPA({ ...filters }),
    ROI: getROI({ ...filters }),
    latestROI: getLatestROI(filters),
    latestAverageCPA: getLatestAverageCPA(filters),
    noFirstGift: getNoFirstGift({ ...filters }),
    attrition: getAttritionByMonth({ ...filters }),
    testAttrition: getAttritionByMonth({ ...filters }),
    attritionYears: getAttritionYearlyBuckets({ ...filters }),
  };
  return promiseAllProperties(promises);
};

const getActiveDonors = (selectedFilters = {}) => {
  return withYearComparison("get_donor_count", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.donorCount;
  });
};

const getLatestSignups = (selectedFilters = {}) => {
  return getData("get_donor_count?by-cohort", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      donorCount: res[res.length - 1].donorCount,
      cohort: res[res.length - 1].cohort,
    };
  });
};

const getTotalLifetimeRevenue = (selectedFilters = {}) => {
  return withYearComparison("get_revenue", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.revenue;
  });
};

const getLatestRevenue = (selectedFilters = {}) => {
  return getData("get_revenue?by-cohort", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      revenue: res[res.length - 1].revenue,
      cohort: res[res.length - 1].cohort,
    };
  });
};

const getAverageSignups = (selectedFilters = {}) => {
  return withYearComparison(
    "get_total_signups?average",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return res.donorCount / res.signupMonths;
    }
  );
};

const getLatestAverageSignups = (selectedFilters = {}) => {
  return getData("get_donor_count?by-cohort", selectedFilters).then((res) => {
    if (res == null || res.length === 0) {
      return "No Data";
    }
    const latestYear = res[res.length - 1].cohortYear;
    const donorCount = res.reduce((total, row) => {
      if (row.cohortYear === latestYear) {
        return total + row.donorCount;
      }
      return total;
    }, 0);
    const signupMonths = res.reduce((total, row) => {
      if (row.cohortYear === latestYear) {
        return total + 1;
      }
      return total;
    }, 0);
    return {
      averageSignups: donorCount / signupMonths,
      year: latestYear,
    };
  });
};

const getAverageGift = (selectedFilters = {}) => {
  return withYearComparison("get_average_gift", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.averageGift;
  });
};

const getLatestGiftPerDonor = (selectedFilters = {}) => {
  return getData("get_average_gift?by-cohort", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      averageGift: res[res.length - 1].averageGift,
      cohort: res[res.length - 1].cohort,
    };
  });
};

const getRetention = (selectedFilters = {}) => {
  return withYearComparison("get_attrition", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (Array.isArray(res.donors)) {
      res.donors = res.donors[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return (
      (res.donors.donorCount - res.drops.dropCount) / res.donors.donorCount
    );
  });
};

const getLatestRetention = (selectedFilters = {}) => {
  return getData("get_attrition?by-cohort", selectedFilters).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0 || res.donors.length === 0) {
      return "No Data";
    }
    const lastCohort = res.donors[res.donors.length - 1];
    const lastDonors = res.drops.find(
      (row) => row.cohort === lastCohort.cohort
    );
    const dropCount = lastDonors !== undefined ? lastDonors.dropCount : 0;
    return {
      retention: (lastCohort.donorCount - dropCount) / lastCohort.donorCount,
      cohort: lastCohort.cohort,
    };
  });
};

const getAverageDecline = (selectedFilters = {}) => {
  return withYearComparison(
    "get_average_decline?condense",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return res.notAccepted / res.transactionCount;
    }
  );
};

const getLatestDecline = (selectedFilters = {}) => {
  return getData("get_average_decline", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      averageDecline:
        res[res.length - 1].notAccepted / res[res.length - 1].transactionCount,
      transactionMonth: res[res.length - 1].transactionMonth,
    };
  });
};

const getNoFirstGift = (selectedFilters = {}) => {
  return withYearComparison("get_no_first_gift", selectedFilters, (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return res.noFirstGift / res.donorCount;
  });
};

const getAttritionByMonth = (selectedFilters = {}) => {
  return getData("get_attrition?by-transaction", selectedFilters).then(
    (res) => {
      const results = [];
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return results;
      }
      if (Object.prototype.hasOwnProperty.call(res, "donors")) {
        res.donors.forEach((item) => {
          const dropItem = res.drops.find(
            (element) => element.transactionMonth === item.transactionMonth
          );
          if (typeof dropItem !== "undefined") {
            results.push({
              attrition: dropItem.dropCount / item.donorCount,
              transactionMonth: moment(item.transactionMonth).format(
                "MMM YYYY"
              ),
            });
          } else {
            results.push({
              attrition: 0,
              transactionMonth: moment(item.transactionMonth).format(
                "MMM YYYY"
              ),
            });
          }
        });
        return results;
      }
    }
  );
};

const getAverageCPA = (selectedFilters = {}) => {
  return withYearComparison(
    "get_average_cpa",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return "No Data";
      }
      return res.averageCPA;
    },
    false
  );
};

const getLatestAverageCPA = (selectedFilters = {}) => {
  return getData("get_average_cpa?by-cohort", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return "No Data";
    }
    return {
      averageCPA: res[res.length - 1].averageCPA,
      cohort: res[res.length - 1].cohort,
    };
  });
};

const getROI = (selectedFilters = {}) => {
  return withYearComparison(
    "get_roi",
    selectedFilters,
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (
        res === null ||
        res.length === 0 ||
        res.expense.totalExpense === null
      ) {
        return "No Data";
      }
      if (res.expense.totalExpense === 0 || res.expense.totalExpense === null) {
        return "Inf";
      }
      return res.revenue.totalRevenue / res.expense.totalExpense;
    },
    false
  );
};

const getLatestROI = (selectedFilters = {}) => {
  return getData("get_roi?by-transaction", selectedFilters).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0 || res.expense.length === 0) {
      return "No Data";
    }
    const lastMonth = res.revenue[res.revenue.length - 1];
    if (lastMonth === undefined || lastMonth === null) {
      return "No Data";
    }
    const lastRevenue = res.revenue.find(
      (row) => row.transactionMonth === lastMonth.transactionMonth
    );
    const lastExpense = res.expense.find(
      (row) => row.transactionMonth === lastMonth.transactionMonth
    );
    if (lastExpense === undefined || lastExpense.totalExpense === null) {
      return "No Data";
    }
    if (lastExpense.totalExpense === 0) {
      return "Inf (Cannot divide by 0)";
    }
    return {
      roi: lastRevenue.totalRevenue / lastExpense.totalExpense,
      transactionMonth: lastMonth.transactionMonth,
    };
  });
};

// const getAttritionBuckets = (selectedFilters = {}) => {
//   return getData('get_attrition?by-period', selectedFilters).then((res) => {
//     const instalments = ['1', '2-5', '6-12', '13-24', '25-36', '36+'];
//     const periods = [[1, 1], [2, 5], [6, 12], [13, 24], [25, 36], [37, 2147483647]];
//     const buckets = [0, 0, 0, 0, 0, 0];
//     const countedPeriods = [[], [], [], [], [], []];
//
//     periods.forEach((period, index) => {
//       for (const drop of res.drops) {
//         const periodVal = drop.period;
//         if (periodVal >= period[0] && periodVal <= period[1]) {
//           buckets[index] += drop.dropCount;
//           if (!(periodVal in countedPeriods[index])) {
//             countedPeriods[index].push(periodVal);
//           }
//         }
//       }
//     });
//
//     const result = [];
//     buckets.forEach((value, index) => {
//       result.push({
//         attrition: value / (res.donors.donorCount * countedPeriods[index].length),
//         instalment: instalments[index],
//       });
//     });
//
//     return result;
//   });
// };

const getAttritionYearlyBuckets = (selectedFilters = {}) => {
  return getData("get_attrition?by-period", selectedFilters).then((res) => {
    const instalments = ["1-12", "13-24", "25-36", "36+"];
    const periods = [
      [0, 12],
      [13, 24],
      [25, 36],
      [37, 2147483647],
    ];
    const buckets = [0, 0, 0, 0];
    const countedPeriods = [[], [], [], []];
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (Array.isArray(res.donors)) {
      res.donors = res.donors[0];
    }
    if (res === null || res.length === 0) {
      return periods.map((range, index) => ({
        attrition: 0,
        instalment: instalments[index],
      }));
    }

    if (Object.prototype.hasOwnProperty.call(res, "drops")) {
      periods.forEach((period, index) => {
        for (const drop of res.drops) {
          const periodVal = drop.period;
          if (periodVal >= period[0] && periodVal <= period[1]) {
            buckets[index] += drop.dropCount;
            if (!(periodVal in countedPeriods[index])) {
              countedPeriods[index].push(periodVal);
            }
          }
        }
      });
    }
    const result = [];
    buckets.forEach((value, index) => {
      if (Object.prototype.hasOwnProperty.call(res, "donors")) {
        result.push({
          attrition: value / res.donors.donorCount,
          instalment: instalments[index],
        });
      } else {
        result.push({
          attrition: 0,
          instalment: instalments[index],
        });
      }
    });
    return result;
  });
};

export default getAnalyticsKPIData;
