import React, { useCallback, useEffect, useState } from 'react';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import ToggleButton from '../../components/ToggleButton';
import { Button, Card } from 'reactstrap';
import getBestDonorSegmentData from './data';
import { analyticsFilters, getComparisonFilter, getSelectedFilters } from '../../app/filters';
import './AnalyticsBestDonorSegment.css';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  downloadSpreadsheet,
  formatValue,
  getColour,
  selectedFilters,
  shadeColour,
} from '../../app/utils';
import DataCard from '../../components/DataCard';
import activeDonors from '../../assets/images/active_donor.svg';
import present from '../../assets/images/average_gift.svg';
import age from '../../assets/images/age.svg';
import ContextBar from '../../components/V2/ContextBar/ContextBar';
import { bindActionCreators } from 'redux';
import {
  modifyFilterSelectionsAction,
  modifyFilterValuesAction,
} from '../../redux/actions/filters.action';
import { connect } from 'react-redux';
import { mergeComparison } from '../../app/data';

/**
 * Analytics / Best Donor Segment component
 * @return {JSX} The component rendered.
 */
function AnalyticsBestDonorSegment(props) {
  const { filterSelections, filterValues } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    bestAgeGroup: {
      age_range: '...',
      ltv: '...',
    },
    bestGiftRange: {
      gift_range: '...',
      ltv: '...',
    },
    averageAge: '...',
    averageLTVPerDonor: [],
    averageGiftPerDonor: [],
    averageGiftDiff: [],
  });

  const [selectedButtonRow1, setSelectedButtonRow1] = useState(0);
  const [selectedChartRow1Data, setSelectedChartRow1Data] = useState({
    data: [],
    keys: [],
    legendLabel: 'Average Gift Per Donor $',
    yAxisLabel: 'Average Gift Per Donor $',
    formatter: (value) => formatValue(value, '$###,0##.##'),
  });

  const [selectedButtonRow2, setSelectedButtonRow2] = useState(0);
  const [selectedChartRow2Data, setSelectedChartRow2Data] = useState({
    data: [],
    keys: [],
    legendLabel: 'Average Gift Per Donor $',
    yAxisLabel: 'Average Upgrade/Downgrade $',
    formatter: (value) => formatValue(value, '$###,0##.##'),
  });

  /**
   * Hook to load the initial data for the page and on filter change
   */
  useEffect(() => {
    setLoading(true);
    const filters = getSelectedFilters(filterSelections, filterValues, analyticsFilters);
    getBestDonorSegmentData(filters).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, [filterSelections, filterValues, setData, setLoading]);

  const getAvgGiftData = useCallback(() => {
    const avgGiftData = mergeComparison(
      data.averageGiftPerDonor,
      'Average Gift Per Donor $',
      getComparisonFilter(filterSelections, analyticsFilters),
    );
    avgGiftData.yAxisLabel = 'Average Gift Per Donor $';
    avgGiftData.legendLabel = 'Average Gift Per Donor $';
    avgGiftData.formatter = (value) => formatValue(value, '$###,0##.##');
    return avgGiftData;
  }, [data, filterSelections]);

  const getAvgLTVData = useCallback(() => {
    const avgLTVData = mergeComparison(
      data.averageLTVPerDonor,
      'Average LTV Per Donor $',
      getComparisonFilter(filterSelections, analyticsFilters),
    );
    avgLTVData.yAxisLabel = 'Average LTV Per Donor $';
    avgLTVData.legendLabel = 'Average LTV Per Donor $';
    avgLTVData.formatter = (value) => formatValue(value, '$###,0##.##');
    return avgLTVData;
  }, [data, filterSelections]);

  const getAvgGiftDiffData = useCallback(() => {
    const avgGiftDiffData = mergeComparison(
      data.averageGiftDiff,
      'Average Upgrade/Downgrade $',
      getComparisonFilter(filterSelections, analyticsFilters),
    );
    avgGiftDiffData.legendLabel = 'Average Upgrade/Downgrade $';
    avgGiftDiffData.yAxisLabel = 'Average Upgrade/Downgrade $';
    avgGiftDiffData.formatter = (value) => formatValue(value, '$###,0##.##');
    return avgGiftDiffData;
  }, [data, filterSelections]);

  /**
   * Hook that runs when the row 1 chart buttons are modified
   */
  useEffect(() => {
    if (selectedButtonRow1 === 0) {
      setSelectedChartRow1Data(getAvgGiftData());
    } else if (selectedButtonRow1 === 1) {
      setSelectedChartRow1Data(getAvgLTVData());
    }
  }, [selectedButtonRow1, getAvgGiftData, getAvgLTVData]);
  /**
   * Hook that runs when the row 2 chart buttons are modified
   */
  useEffect(() => {
    if (selectedButtonRow2 === 0) {
      setSelectedChartRow2Data(getAvgGiftDiffData());
    }
  }, [selectedButtonRow2, getAvgGiftDiffData]);

  const handleDownloadAverageGiftSpreadsheet = useCallback(() => {
    const chartData = [
      {
        tabName: 'Avg Gift per Donor by Month',
        tabData: getAvgGiftData().data,
        header: ['transactionMonth'],
      },
      {
        tabName: 'Avg LTV per Donor by Month',
        tabData: getAvgLTVData().data,
        header: ['transactionMonth'],
      },
    ];

    downloadSpreadsheet(
      `BestDonorSegment_AverageGift`,
      selectedFilters(filterSelections),
      chartData,
    );
  }, [data, filterSelections, getAvgGiftData, getAvgLTVData]);

  const handleDownloadAverageUpgradeDownGradeSpreadsheet = useCallback(() => {
    const chartData = [
      {
        tabName: 'Avg Upgrade Downgrade by Month',
        tabData: getAvgGiftDiffData().data,
        header: ['transactionMonth'],
      },
    ];

    downloadSpreadsheet(`BestDonorSegment_Upgrade`, selectedFilters(filterSelections), chartData);
  }, [data, filterSelections, getAvgGiftDiffData]);

  return (
    <div className={'wholepage'}>
      <header>
        <PrimaryBar />
      </header>
      <div className={'main-content'}>
        <SecondaryBar />
        <ContextBar
          title={'Best Donor Segment'}
          parentLoading={loading}
          footer={'Understand your best donor segments as opposed to your best donors.'}
          shortcutFilters={[
            'channel',
            'campaign',
            'supplier',
            'gift-start-year',
            'transaction-month',
          ]}
          sidebarFilters={analyticsFilters}
        />
        <div className="content_section">
          <div className="page-content no-pad-top">
            <div className="row1">
              <DataCard
                image={activeDonors}
                imageAlt={'Female Donor'}
                title={'Best Age Group'}
                figure={data.bestAgeGroup.age_range}
                footerContext={'Age group that gives the highest LTV'}
                footerLink={'/analytics/best_donor_segment'}
              />
              <DataCard
                image={present}
                imageAlt={'Present'}
                title={'Best Gift Range'}
                figure={data.bestGiftRange.gift_range}
                footerContext={'Gift range that gives the highest LTV'}
                footerLink={'/analytics/best_donor_segment'}
              />
              <DataCard
                image={age}
                imageAlt={'Male Donor'}
                title={'Average Age'}
                figure={data.averageAge}
                footerContext={'The average age across all donors'}
                footerLink={'/analytics/best_donor_segment'}
              />
            </div>
            <div className="graph_section">
              <div className="ac_graph_section">
                <div className="graph_btns">
                  <ToggleButton
                    componentSelectorName={'best_donor_segment-switch-avg_gift_per_donor_by_month'}
                    text={'Average Gift Per Donor by Month'}
                    toolTipText={'The average gift amount each donor gives per month.'}
                    active={selectedButtonRow1 === 0}
                    onClick={() => setSelectedButtonRow1(0)}
                  />
                  <ToggleButton
                    componentSelectorName={'best_donor_segment-switch-avg_ltv_per_donor_by_month'}
                    text={'Average LTV per Donor by Month'}
                    toolTipText={'The average total lifetime value each donor achieves per month.'}
                    active={selectedButtonRow1 === 1}
                    onClick={() => setSelectedButtonRow1(1)}
                  />
                  <Button
                    className={'download_spreadsheet'}
                    onClick={handleDownloadAverageGiftSpreadsheet}
                  >
                    <i className="fas fa-download " />
                    Download Spreadsheet
                  </Button>
                </div>
                <Card className="ac_chart_section">
                  <ResponsiveContainer>
                    <LineChart
                      data={selectedChartRow1Data.data}
                      margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="transactionMonth"
                        label={{
                          value: 'Transaction Month',
                          position: 'insideBottom',
                          offset: -10,
                        }}
                      />
                      <YAxis
                        tickFormatter={selectedChartRow1Data.formatter}
                        label={{
                          value: selectedChartRow1Data.yAxisLabel,
                          angle: -90,
                          position: 'insideLeft',
                        }}
                      />
                      <Legend height={36} verticalAlign="top" />
                      <Tooltip formatter={selectedChartRow1Data.formatter} />
                      {selectedChartRow1Data.keys.map((key, index) => (
                        <Line
                          key={`chart_${key}`}
                          type="monotone"
                          dataKey={key}
                          stroke={shadeColour(getColour(index), 10)}
                          strokeWidth={2}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </div>
            </div>
            <div className="graph_section">
              <div className="graph_btns">
                <ToggleButton
                  componentSelectorName={'best_donor_segment-switch-avg_diff_per_donor_by_month'}
                  text={'Average Upgrade/Downgrade by Month'}
                  toolTipText={'The average upgrade/downgrade happened per month.'}
                  active={selectedButtonRow2 === 0}
                  onClick={() => setSelectedButtonRow2(0)}
                />
                <Button
                  className={'download_spreadsheet'}
                  onClick={handleDownloadAverageUpgradeDownGradeSpreadsheet}
                >
                  <i className="fas fa-download " />
                  Download Spreadsheet
                </Button>
              </div>
              <Card className="ac_chart_section">
                <ResponsiveContainer>
                  <LineChart
                    data={selectedChartRow2Data.data}
                    margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="transactionMonth"
                      label={{
                        value: 'Transaction Month',
                        position: 'insideBottom',
                        offset: -10,
                      }}
                    />
                    <YAxis
                      tickFormatter={selectedChartRow2Data.formatter}
                      label={{
                        value: selectedChartRow2Data.yAxisLabel,
                        angle: -90,
                        position: 'insideLeft',
                      }}
                    />
                    <Legend height={36} verticalAlign="top" />
                    <Tooltip formatter={selectedChartRow2Data.formatter} />
                    {selectedChartRow2Data.keys.map((key, index) => (
                      <Line
                        key={`chart_${key}`}
                        type="monotone"
                        dataKey={key}
                        stroke={shadeColour(getColour(index), 10)}
                        strokeWidth={2}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

const mapStateToProps = ({ filterSelections, filterValues }) => {
  return {
    filterSelections,
    filterValues,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      modifyFilterSelectionsAction: modifyFilterSelectionsAction,
      modifyFilterValuesAction: modifyFilterValuesAction,
    },
    dispatch,
  );
};

AnalyticsBestDonorSegment.propTypes = {
  filterValues: PropTypes.object,
  filterSelections: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(AnalyticsBestDonorSegment);
