import promiseAllProperties from "promise-all-properties";
import { getData, postPayloadData, withComparison } from "../../app/data";
import moment from "moment";
import { DAY_OF_WEEK } from "../../app/constants";

const getDeclinesData = (selectedFilters, offset) => {
  const promises = {
    declineVolumes: getDeclineVolumes(selectedFilters),
    declinePercentage: getDeclinePercentage({ ...selectedFilters }),
    lostRevenue: getLostRevenue({ ...selectedFilters }),
    averageTransactions: getAverageTransactions(selectedFilters),
    declinedCount: getDonorDeclinedCount({
      ...selectedFilters,
      offset: offset,
    }),
    successiveFailed: getDonorSuccessiveFailed({
      ...selectedFilters,
      offset: offset,
    }),
    declineReasons: getDonorDeclineReasons({
      ...selectedFilters,
      offset: offset,
    }),
  };
  return promiseAllProperties(promises);
};

const getDeclineVolumes = (selectedFilters) => {
  return getData("get_decline_volumes", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    return res.map((row) => ({
      transactionMonth: moment(row.transactionMonth).format("MMM YYYY"),
      Successful: row.accepted,
      Declined: row.notAccepted,
    }));
  });
};

const getDeclinePercentage = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    let tmp = [];
    if (!(res instanceof Array)) {
      tmp = [res];
    } else {
      tmp = res;
    }
    return tmp.map((v) => {
      const decline = v.notAccepted / v.transactionCount;
      return {
        transactionMonth: moment(v.transactionMonth).format("MMM YYYY"),
        "Decline %": decline,
      };
    });
  };
  return withComparison("get_average_decline", selectedFilters, computeFn);
};

const getLostRevenue = (selectedFilters) => {
  const computeFn = (res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    if (Array.isArray(res)) {
      return res.map((v) => {
        return {
          transactionMonth: moment(v.transactionMonth).format("MMM YYYY"),
          "Lost Revenue $": v.lostRevenue,
        };
      });
    } else {
      return [
        {
          transactionMonth: moment(res.transactionMonth).format("MMM YYYY"),
          "Lost Revenue $": res.lostRevenue,
        },
      ];
    }
  };
  return withComparison("get_lost_revenue", selectedFilters, computeFn);
};

const getAverageTransactions = (selectedFilters) => {
  return getData("get_average_transactions", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    return res.map((row) => ({
      dayOfWeek: DAY_OF_WEEK[row.dayOfWeek],
      Successful: row.accepted,
      Declined: row.notAccepted,
    }));
  });
};

const getDonorDeclinedCount = (selectedFilters, offset) => {
  return postPayloadData("get_donor_declined_count", {
    ...selectedFilters,
    offset: offset,
  }).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return { data: [], size: 0 };
    }
    const size = res.size;
    const rtn = [
      [
        { value: "Donor ID", readOnly: true, className: "table-cell-header" },
        {
          value: "No. of Declined Transactions",
          readOnly: true,
          className: "table-cell-header",
        },
      ],
    ];
    res.data.forEach((row) => {
      rtn.push([
        {
          value: row.source_donor_id.toString(),
          readOnly: true,
          className: "table-cell",
        },
        {
          value: row.declined.toString(),
          readOnly: true,
          className: "table-cell",
        },
      ]);
    });
    return { data: rtn, size: size };
  });
};

const getDonorSuccessiveFailed = (selectedFilters, offset) => {
  return postPayloadData("get_successive_failed", {
    ...selectedFilters,
    offset: offset,
  }).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return { data: [], size: 0 };
    }
    const size = res.size;
    const rtn = [
      [
        { value: "Donor ID", readOnly: true, className: "table-cell-header" },
        {
          value: "Longest Successive Transactions Failed",
          readOnly: true,
          className: "table-cell-header",
        },
      ],
    ];
    res.data.forEach((row) => {
      rtn.push([
        {
          value: row.source_donor_id.toString(),
          readOnly: true,
          className: "table-cell",
        },
        {
          value: row.max_successive_failed.toString(),
          readOnly: true,
          className: "table-cell",
        },
      ]);
    });
    return { data: rtn, size: size };
  });
};

const getDonorDeclineReasons = (selectedFilters, offset) => {
  return postPayloadData("get_decline_reasons", {
    ...selectedFilters,
    offset: offset,
  }).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return { data: [], size: 0 };
    }
    const size = res.size;
    const rtn = [
      [
        //{value: 'Code', readOnly: true, className: 'table-cell-header'},
        {
          value: "Description",
          readOnly: true,
          className: "table-cell-header",
        },
        { value: "Count", readOnly: true, className: "table-cell-header" },
      ],
    ];
    // eslint-disable-next-line no-unused-vars
    res.data.forEach((row, index) => {
      rtn.push([
        //{value: row.code, readOnly: true, className: 'table-cell'},
        { value: row.reason, readOnly: true, className: "table-cell" },
        { value: row.count, readOnly: true, className: "table-cell" },
      ]);
    });
    return { data: rtn, size: size };
  });
};

export {
  getDeclinesData,
  getDonorDeclineReasons,
  getDonorDeclinedCount,
  getDonorSuccessiveFailed,
};
