import {API_ENDPOINT} from '../../app/config';
import {getBearerToken} from '../../app/auth';

const logError = (endpoint, data) => {
  return fetch(API_ENDPOINT + endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    },
    body: JSON.stringify(data),
  })
      .then((response) => response.json())
      .catch(() => null);
};

export {
  logError,
};
