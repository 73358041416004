import React, { useState } from "react";
import PropTypes from "prop-types";

import "./ToggleButton.css";
import { UncontrolledTooltip } from "reactstrap";
import { newId } from "../../app/utils";

/**
 * Toggle Button Component, returns a blue button with an arrow if its active,
 * when inactive its a grey depressed button needing a friend. :Poggers:
 * @param {Object} props the data for the button
 * @return {JSX}
 */
function ToggleButton(props) {
  // Set the ID of the Button once through a hook. This is so that on
  // re-renders, react won't regenerate an ID.
  const [id] = useState(newId());
  /**
   * Run the onClick function passed to this component, passing the name of
   * this button as the parameter.
   */
  function handleClick() {
    props.onClick(props.name);
  }

  // Generate a unique ID for this tooltip based on a random string.
  const { text, active } = props;
  return (
    <span>
      <button
        data-cy={props.componentSelectorName}
        className={
          "toggle_btn " + (active ? "activated arrow_down" : "grey_toggle_btn")
        }
        id={id}
        onClick={handleClick}
      >
        {text}
      </button>
      {props.toolTipText !== null &&
        props.toolTipText !== "" &&
        props.toolTipText !== undefined && (
          <UncontrolledTooltip placement={"right"} target={id} hideArrow={true}>
            {props.toolTipText}
          </UncontrolledTooltip>
        )}
    </span>
  );
}

ToggleButton.propTypes = {
  toolTipText: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  componentSelectorName: PropTypes.string,
};

export default ToggleButton;
