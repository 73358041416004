import React, { Component } from 'react';
import PrimaryBar from '../../components/PrimaryBar';
import SecondaryBar from '../../components/SecondaryBar';
import Footer from '../../components/Footer';
import './Members.css';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import Datetime from 'react-datetime';
import * as moment from 'moment';
import { postPayloadData } from '../../app/data';
import getMembersData from './data';
import { getClientName, getUserId } from '../../app/auth';

/**
 * Members component, allows for users to invite their frands to the
 * application.
 */
class Members extends Component {
  /**
   * Default constructor for the component
   * @param {Object} props The properties of the component
   */
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.state = {
      isLoading: false,
      client: getClientName(),
      expirationDate: null,
      invitationEmails: '',
      invitationRole: '',
      data: { users: [], userCount: 0 },
      alertVisible: false,
      errorMsg: '',
      deleteModal: false,
      deleteUser: '',
      deleteUserId: '',
    };
  }

  /**
   * Dismisses an alert by making it invisible. This resets the error message
   * to allow for future alerts to take its place.
   */
  onDismiss() {
    this.setState({
      alertVisible: false,
      errorMsg: '',
    });
  }

  /**
   * Shows a new alert. If an alert is already showing it overwrites it with
   * this one.
   * @param {String} message The message to show on the alert.
   */
  showAlert(message) {
    this.setState({
      isLoading: false,
      alertVisible: true,
      errorMsg: message,
    });
  }

  /**
   * Resets all alerts so that they don't distract the user whilst the screen
   * does something else.
   */
  hideAlerts() {
    this.setState({
      alertVisible: false,
      errorMsg: '',
    });
  }

  /**
   * Load the members from the database to populate the list group.
   * @return {void}
   */
  async componentDidMount() {
    getMembersData().then((res) => {
      this.setState({ data: res });
    });
    this.handleExpirationChange(moment.now());
  }

  /**
   * Set the expiration for user invitations
   * @param {Object} e The event triggering this function
   */
  handleExpirationChange(e) {
    const d = new Date(e);
    const date = moment(d).format('DD MMM YYYY').toString();
    this.setState({
      expirationDate: date,
    });
  }

  /**
   * Handle the modification of the user role field
   * @param {Object} e The event triggering the change
   */
  handleUserRoleChange(e) {
    this.setState({ invitationRole: e.target.value });
  }

  /**
   * Handle the modification of the user invitation list field, note that this
   * field is a user input, semi-column separated field.
   * @param {Object} e The event triggering the change
   */
  handleInvitationEmailChange(e) {
    const value = e.target.value;
    this.setState({ invitationEmails: value });
  }

  /**
   * Toggles the delete window modal view
   * @param {String} userId The user id to delete or null
   * @param {String} username The name of the user to delete (for display)
   */
  toggleDeleteModal(userId, username) {
    if (userId != null) {
      this.setState({ deleteUser: username, deleteUserId: userId });
    } else {
      this.setState({ deleteUser: '', deleteUserId: '' });
    }
    const { deleteModal } = this.state;
    this.setState({ deleteModal: !deleteModal });
  }

  /**
   * Delete a user from the database, admins only y'all!
   */
  async removeUser() {
    this.setState({ isLoading: true });
    // Prepare the payload
    const { deleteUserId } = this.state;
    const payload = { user_id: deleteUserId };
    // Post the payload to the backend.
    await postPayloadData('auth/remove_user', payload)
      .then((res) => {
        if (res.success) {
          getMembersData().then((res) => {
            this.setState({
              data: res,
              deleteModal: false,
              isLoading: false,
            });
          });
        } else {
          const errorMsg =
            'message' in res
              ? res.message
              : 'An internal error occurred, ' + 'please try again later.';
          this.showAlert(errorMsg);
        }
      })
      .catch(() => {
        this.showAlert('An internal error occurred, please try again later.');
      });
  }

  /**
   * Invite the new users to the application based on the users input.
   */
  async inviteUser() {
    this.setState({ isLoading: true });
    // Prepare the payload to send to the backend
    const payload = { users: [], href: window.location.origin.toString() };
    const { expirationDate, invitationEmails, invitationRole } = this.state;
    invitationEmails
      .toString()
      .split(';')
      .forEach(function (element) {
        payload.users.push({
          email: element.toString(),
          role: invitationRole,
          expiry: expirationDate,
        });
      });
    await postPayloadData('auth/invite', payload)
      .then((res) => {
        if (res.success) {
          getMembersData().then((res) => {
            this.setState({
              data: res,
              deleteModal: false,
              isLoading: false,
            });
          });
          if ('message' in res) {
            this.showAlert(res.message);
          }
        } else {
          const errorMsg =
            'message' in res
              ? res.message
              : 'An internal error occurred, ' + 'please try again later.';
          this.showAlert(errorMsg);
        }
      })
      .catch(() => {
        this.showAlert('An internal error occurred, please try again later.');
      });
  }

  /**
   * The render function for the component.
   * @return {JSX} The component rendered as JSX
   */
  render() {
    // Unpack some state variables into function scoped variables
    const { isLoading, client, data, errorMsg, deleteModal, deleteUser } = this.state;
    const thisUserId = getUserId();
    // Loading icon whilst the endpoints are running
    let loadingDiv = '';
    if (isLoading) {
      loadingDiv = <Spinner color="primary" />;
    }
    let errorFlash;
    if (errorMsg.length > 0) {
      errorFlash = (
        <Alert
          color="danger"
          isOpen={this.state.alertVisible}
          toggle={this.onDismiss}
          className={'alertDiv'}
        >
          {errorMsg}
        </Alert>
      );
    }
    // Render a member listgroup for each member in the data
    const memberList = data.users.map((item, key) => (
      <ListGroupItem className={'memberListItem'} key={key}>
        <InputGroup>
          <div>
            <p>
              {item.username} <Badge>{item.role}</Badge>{' '}
              {thisUserId === item.userId && <Badge color={'success'}>Its You!</Badge>}
              {item.invitedUser === 1 && <Badge color={'info'}>Invited!</Badge>}
            </p>
            <p>
              {item.invitedUser === 0 && (
                <span>
                  Registered {moment(item.registered).from(moment.now())},{' '}
                  {item.lastLogin ? (
                    <>Last accessed {moment(item.lastLogin).from(moment.now())}</>
                  ) : (
                    <>Never accessed</>
                  )}
                </span>
              )}
              {item.invitedUser === 1 && (
                <span>Invited {moment(item.registered).from(moment.now())}</span>
              )}
            </p>
          </div>
          {thisUserId !== item.userId && (
            <Button
              color="danger"
              size="sm"
              className={'rightButton'}
              onClick={() => this.toggleDeleteModal(item.userId, item.username)}
            >
              Remove
            </Button>
          )}
        </InputGroup>
      </ListGroupItem>
    ));
    // Create a bunch of rows for each forecast
    // Return the rendered component
    return (
      <div className="wholepage">
        <header>
          <PrimaryBar />
          <SecondaryBar />
        </header>
        <div className="main-content">
          <div className="content_section">
            <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal}>
              <ModalHeader toggle={this.toggleDeleteModal}>Delete Member {loadingDiv}</ModalHeader>
              <ModalBody>
                Are you sure you want to delete {deleteUser}? This cannot be undone.
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.removeUser}>
                  Delete
                </Button>{' '}
                <Button color="secondary" onClick={this.toggleDeleteModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <div className="page-content">
              <div className={'view_container'}>
                <h2>Invite New Members</h2>
              </div>
              <Card className={'inviteCard'}>
                <CardBody>
                  <FormGroup>
                    <Label id={'blackLabel'} for="userEmail">
                      Email
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="userEmail"
                      onChange={this.handleInvitationEmailChange.bind(this)}
                    />
                    <FormText color="muted">Separate multiple emails with a semicolon.</FormText>
                    <Label id={'blackLabel'} for="userRole">
                      Role
                    </Label>
                    <Input
                      type="select"
                      name="select"
                      id="userRole"
                      onChange={this.handleUserRoleChange.bind(this)}
                    >
                      <option>User</option>
                      <option>Administrator</option>
                    </Input>
                    <FormText color="muted">This will apply to all invited users.</FormText>
                    <Label id={'blackLabel'} for="userExpiration">
                      Invitation expiration date.
                    </Label>
                    <Datetime
                      dateFormat="DD MMM YYYY"
                      timeFormat={false}
                      type="date"
                      className={'expirationInput'}
                      input
                      value={this.state.expirationDate}
                      name={'startingMonth'}
                      id={'startingMonth'}
                      onChange={this.handleExpirationChange.bind(this)}
                      required={true}
                    />
                    <FormText color="muted">
                      The invitation link will not work after this date.
                    </FormText>
                    <Button onClick={this.inviteUser.bind(this)}>Submit</Button>
                  </FormGroup>
                </CardBody>
              </Card>
              <div className={'view_container'}>
                <h2>Current Members {loadingDiv}</h2>
              </div>
              {errorFlash}
              <Card className={'inviteCard'}>
                <CardHeader>
                  Members allowed access to {client}
                  <Badge>{data.userCount}</Badge>
                </CardHeader>
                <CardBody>
                  <ListGroup>{memberList}</ListGroup>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default Members;
