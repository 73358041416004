import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./HomeCard.css";

/**
 * A homecard component containing a header text and call to action. The subtext
 * can be provided with a figure, if so conditional formatting is applied to
 * bolden the figure, otherwise the subtext is shown as justa  <p> tag.
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
class HomeCard extends React.Component {
  /**
   * The render function for the component, returns the Homecard for the given
   * properties.
   * @return {JSX}
   */
  render() {
    // Extract state variables for rendering.
    const {
      componentSelectorName,
      title,
      bodyContent,
      iconName,
      bodyContentFigure,
      buttonLinkPath,
      image,
      imageAltText,
      buttonText,
    } = this.props;
    // If the body content figure was provided, conditionally build a new
    // subHeading using that figure, otherwise show the default text.
    let subHeading;
    if (bodyContentFigure.length > 0) {
      subHeading = (
        <p>
          {bodyContent}: <strong>{bodyContentFigure}</strong>
        </p>
      );
    } else {
      subHeading = <p>{bodyContent}</p>;
    }
    // Generate an image tag only if that property was supplied
    let imageTag;
    if (image !== undefined) {
      imageTag = <img src={image} alt={imageAltText} />;
    }
    return (
      <div data-cy={componentSelectorName} className="each_spbox">
        <figure>{imageTag}</figure>
        <div className="each_spbox_wrapper">
          <div className="sp_header">
            <i className={iconName} />
            <NavLink to={buttonLinkPath} className={"self_btn lblue_btns"}>
              {buttonText}
            </NavLink>
          </div>
          <div className="sp_body">
            <h2>{title}</h2>
            {subHeading}
          </div>
        </div>
      </div>
    );
  }
}
/**
 * Define the types of each property passed to the component.
 */
HomeCard.propTypes = {
  componentSelectorName: PropTypes.string,
  iconName: PropTypes.string,
  title: PropTypes.string,
  bodyContent: PropTypes.string,
  bodyContentFigure: PropTypes.string,
  subText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLinkPath: PropTypes.string,
  image: PropTypes.string,
  imageAltText: PropTypes.string,
};
export default HomeCard;
