import promiseAllProperties from "promise-all-properties";
import { getData, withComparison } from "../../app/data";
import { ATTRITION_LENGTH } from "../../app/constants";
import { formatValue } from "../../app/utils";
import moment from "moment";

const getInstallmentData = (selectedFilters) => {
  const promises = {
    attrition: getAttritionByPeriod({ ...selectedFilters }),
    attritionAccumulated: getAccumulatedAttritionByPeriod({
      ...selectedFilters,
    }),
    volume: getVolume(selectedFilters),
    attritionByPeriodTable: getAttritionByPeriodTable(selectedFilters),
    attritionCohortPeriod: getAttritionByCohortAndPeriod(selectedFilters),
    attritionCohortPeriodAccumulated: getAccumulatedAttritionByCohortAndPeriod(
      selectedFilters
    ),
    retention: getRetentionByCohortAndPeriod(selectedFilters),
  };
  return promiseAllProperties(promises);
};

// Attrition By Period
const getAttritionByPeriod = (selectedFilters = {}) => {
  const computeFn = (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (Array.isArray(res.donors)) {
      res.donors = res.donors[0];
    }
    if (res === null || res.length === 0) {
      return [];
    }
    const rtn = Array(res.donors.maxPeriod).fill(0);
    if ("drops" in res && res.drops.length > 0) {
      res.drops.forEach(function (element) {
        rtn[element.period - 1] = {
          Period: element.period,
          Attrition: element.dropCount / res.donors.donorCount,
        };
      });
    }
    const tmp = rtn;
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i] === 0) {
        rtn[i] = { Period: i + 1, Attrition: 0 };
      }
    }
    return rtn;
  };

  return withComparison("get_attrition?by-period", selectedFilters, computeFn);
};

const getAttritionByPeriodTable = (selectedFilters = {}) => {
  return getData("get_attrition?by-period", selectedFilters).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (Array.isArray(res.donors)) {
      res.donors = res.donors[0];
    }
    if (res === null || res.length === 0) {
      return [];
    }

    // Construct table data structure using Array, using number of years to allocate array size
    // const rtn = Array(Math.ceil(res.donors.maxPeriod / 12) + 1).fill([]);
    const rtn = Array(6).fill([]);
    // Produce the header row
    // Start with 'Year/Month' and append all 12 months for the column header
    const headerRow = [
      { value: "Year/Month", readOnly: true, className: "table-cell-header" },
    ];
    for (let i = 1; i <= 12; i++) {
      headerRow.push({
        value: i.toString(),
        readOnly: true,
        className: "table-cell-header",
      });
    }
    rtn[0] = headerRow;

    // Produce data rows
    // Start with year number as row header, then append all 12 months values as 0 for a starting point
    for (let i = 1; i < rtn.length; i++) {
      let dataRow = [
        { value: i, readOnly: true, className: "table-cell-header" },
      ];
      for (let i = 1; i <= 12; i++) {
        dataRow.push({
          value: "0%",
          readOnly: true,
          className: "table-cell",
        });
      }
      rtn[i] = dataRow;
    }
    if ("drops" in res && res.drops.length > 0) {
      let row, column;
      res.drops.forEach(function (element) {
        if (element.period > 0 && element.period <= 60) {
          row = Math.ceil(element.period / 12);
          column = ((element.period - 1) % 12) + 1;
          rtn[row][column] = {
            value: `${(
              (element.dropCount / res.donors.donorCount) *
              100
            ).toFixed(2)}%`,
            readOnly: true,
            className: "table-cell",
            period: element.period,
          };
        }
      });
    }
    return rtn;
  });
};

// Attrition By Period
const getAccumulatedAttritionByPeriod = (selectedFilters = {}) => {
  const computeFn = (res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (Array.isArray(res.donors)) {
      res.donors = res.donors[0];
    }
    if (res === null || res.length === 0) {
      return [];
    }
    const rtn = Array(res.donors.maxPeriod).fill(0);
    if (
      Object.prototype.hasOwnProperty.call(res, "drops") &&
      res.drops.length > 0
    ) {
      res.drops.forEach(function (element) {
        rtn[element.period - 1] = {
          Period: element.period,
          Attrition: element.dropCount / res.donors.donorCount,
        };
      });
    }
    const tmp = rtn;
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i] === 0) {
        rtn[i] = {
          Period: i + 1,
          Attrition: i === 0 ? 0 : tmp[i - 1]["Attrition"],
        };
      }
    }
    return rtn;
  };

  return withComparison(
    "get_attrition?by-period&accumulate",
    selectedFilters,
    computeFn
  );
};

// Volume and Average Gift by Period
const getVolume = (selectedFilters = {}) => {
  return getData("get_average_gift?by-cohort", selectedFilters).then((res) => {
    const rtn = [];
    rtn.push([
      { value: "Month", readOnly: true, className: "table-cell-header" },
      { value: "Donors", readOnly: true, className: "table-cell-header" },
      { value: "Average Gift", readOnly: true, className: "table-cell-header" },
    ]);
    if (res === null || res.length === 0) {
      return rtn;
    }
    res.forEach(function (element) {
      const month = moment(element.cohort).format("MMM YYYY");
      const avgGift = formatValue(element.averageGift, "$0.00");
      rtn.push([
        { value: month, readOnly: true, className: "table-cell" },
        { value: element.numDonors, readOnly: true, className: "table-cell" },
        { value: avgGift, readOnly: true, className: "table-cell" },
      ]);
    });
    return rtn;
  });
};

// Attrition by Cohort and Period
const getAttritionByCohortAndPeriod = (selectedFilters = {}) => {
  return getData("get_attrition?by-period&by-cohort", selectedFilters).then(
    (res) => {
      if (Array.isArray(res)) {
        res = res[0];
      }
      if (res === null || res.length === 0) {
        return [];
      }
      // The maximum number of columns that will be shown by this matrix
      const maxColumns = ATTRITION_LENGTH;
      // Contains the max period and cancelled counts for each cohort
      const cohorts = {};
      // Determine the maximum period to show on the matrix
      let maxPeriod = 0;
      res.donors.forEach((donor) => {
        // Only process non-future cohorts
        if (donor.maxPeriod > 0) {
          // Create a new cohort
          const cohort = moment(donor.cohort).format("MMM YYYY");
          cohorts[cohort] = {
            // The number of drops in each period
            values: Array(donor.maxPeriod).fill(0),
            // The number of people who started in this cohort
            donorCount: donor.donorCount,
          };
          if (donor.maxPeriod > maxPeriod) {
            // Should not exceed the maximum number of columns
            maxPeriod = Math.min(donor.maxPeriod, maxColumns);
          }
        }
      });

      // Produce the header row
      // Start with 'cohort' and append all the periods
      const headerRow = [
        { value: "Cohort", readOnly: true, className: "table-cell-header" },
      ];
      for (let i = 1; i <= maxPeriod; i++) {
        headerRow.push({
          value: i.toString(),
          readOnly: true,
          className: "table-cell-header",
        });
      }

      // Copy over the cancelled counts into the cohorts object
      res.drops.forEach((data) => {
        const cohort = moment(data.cohort).format("MMM YYYY");
        // This is assuming that period never goes below 1 and only process non-future cohorts
        if (Object.prototype.hasOwnProperty.call(cohorts, cohort)) {
          cohorts[cohort].values[data.period - 1] = data.dropCount;
        }
      });

      // Produce the final table
      const rtn = [headerRow];
      Object.entries(cohorts).forEach(([cohort, data]) => {
        // Create a new row with the cohort month/year
        const cohortRow = [
          { value: cohort, readOnly: true, className: "table-cell-header" },
        ];
        data.values.forEach((cancelled) => {
          // Determine the attrition value by dividing the
          // number of cancelled by how many started
          const value = ((cancelled / data.donorCount) * 100).toFixed(2);
          cohortRow.push({
            value: `${value}%`,
            readOnly: true,
            className: "table-cell",
          });
        });
        // Pad cells at the end
        if (cohortRow.length < maxPeriod + 1) {
          const nPaddedCells = maxPeriod - cohortRow.length + 1;
          for (let i = 0; i < nPaddedCells; i++) {
            cohortRow.push({
              value: "",
              readOnly: true,
              className: "table-cell",
            });
          }
        }
        // Ensure that only 20 columns are populated.
        // maxColumns + 1 is used to include the cohort month/year as well.
        rtn.push(cohortRow.slice(0, maxColumns + 1));
      });

      return rtn;
    }
  );
};

// Accumulated Attrition by Cohort and Period
const getAccumulatedAttritionByCohortAndPeriod = (selectedFilters = {}) => {
  return getData(
    "get_attrition?by-period&by-cohort&accumulate",
    selectedFilters
  ).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return [];
    }
    // The maximum number of columns that will be shown by this matrix
    const maxColumns = ATTRITION_LENGTH;
    // Contains the max period and cancelled counts for each cohort
    const cohorts = {};
    // Determine the maximum period to show on the matrix
    let maxPeriod = 0;
    res.donors.forEach((donor) => {
      // Only process non-future cohorts
      if (donor.maxPeriod > 0) {
        // Create a new cohort
        const cohort = moment(donor.cohort).format("MMM YYYY");
        cohorts[cohort] = {
          // The number of drops in each period
          values: Array(donor.maxPeriod).fill(0),
          // The number of people who started in this cohort
          donorCount: donor.donorCount,
        };
        if (donor.maxPeriod > maxPeriod) {
          // Should not exceed the maximum number of columns
          maxPeriod = Math.min(donor.maxPeriod, maxColumns);
        }
      }
    });

    // Produce the header row
    // Start with 'cohort' and append all the periods
    const headerRow = [
      { value: "Cohort", readOnly: true, className: "table-cell-header" },
    ];
    for (let i = 1; i <= maxPeriod; i++) {
      headerRow.push({
        value: i.toString(),
        readOnly: true,
        className: "table-cell-header",
      });
    }

    // Copy over the cancelled counts into the cohorts object
    res.drops.forEach((data) => {
      const cohort = moment(data.cohort).format("MMM YYYY");
      // This is assuming that period never goes below 1 and only process non-future cohorts
      if (Object.prototype.hasOwnProperty.call(cohorts, cohort)) {
        cohorts[cohort].values[data.period - 1] = data.dropCount;
      }
    });

    // Ensure that the accumulated values are carried across for all zero values
    // Changes 1 2 3 0 0 7 to 1 2 3 3 3 7
    // eslint-disable-next-line no-unused-vars
    Object.entries(cohorts).forEach(([cohort, data]) => {
      data.values.forEach((v, i) => {
        if (i > 0 && v === 0) {
          data.values[i] = data.values[i - 1];
        }
      });
    });

    // Produce the final table
    const rtn = [headerRow];
    Object.entries(cohorts).forEach(([cohort, data]) => {
      // Create a new row with the cohort month/year
      const cohortRow = [
        { value: cohort, readOnly: true, className: "table-cell-header" },
      ];
      data.values.forEach((cancelled) => {
        // Determine the attrition value by dividing the
        // number of cancelled by how many started
        const value = ((cancelled / data.donorCount) * 100).toFixed(2);
        cohortRow.push({
          value: `${value}%`,
          readOnly: true,
          className: "table-cell",
        });
      });
      // Pad cells at the end
      if (cohortRow.length < maxPeriod + 1) {
        const nPaddedCells = maxPeriod - cohortRow.length + 1;
        for (let i = 0; i < nPaddedCells; i++) {
          cohortRow.push({
            value: "",
            readOnly: true,
            className: "table-cell",
          });
        }
      }
      // Ensure that only 20 columns are populated.
      // maxColumns + 1 is used to include the cohort month/year as well.
      rtn.push(cohortRow.slice(0, maxColumns + 1));
    });

    return rtn;
  });
};

// Retention by Cohort and period
const getRetentionByCohortAndPeriod = (selectedFilters = {}) => {
  return getData(
    "get_attrition?by-period&by-cohort&accumulate",
    selectedFilters
  ).then((res) => {
    if (Array.isArray(res)) {
      res = res[0];
    }
    if (res === null || res.length === 0) {
      return [];
    }
    // The maximum number of columns that will be shown by this matrix
    const maxColumns = ATTRITION_LENGTH;
    // Contains the max period and cancelled counts for each cohort
    const cohorts = {};
    // Determine the maximum period to show on the matrix
    let maxPeriod = 0;
    res.donors.forEach((donor) => {
      // Only process non-future cohorts
      if (donor.maxPeriod > 0) {
        // Create a new cohort
        const cohort = moment(donor.cohort).format("MMM YYYY");
        cohorts[cohort] = {
          // The number of drops in each period
          values: Array(donor.maxPeriod).fill(0),
          // The number of people who started in this cohort
          donorCount: donor.donorCount,
        };
        if (donor.maxPeriod > maxPeriod) {
          // Should not exceed the maximum number of columns
          maxPeriod = Math.min(donor.maxPeriod, maxColumns);
        }
      }
    });

    // Produce the header row
    // Start with 'cohort' and append all the periods
    const headerRow = [
      { value: "Cohort", readOnly: true, className: "table-cell-header" },
    ];
    for (let i = 1; i <= maxPeriod; i++) {
      headerRow.push({
        value: i.toString(),
        readOnly: true,
        className: "table-cell-header",
      });
    }

    // Copy over the cancelled counts into the cohorts object
    res.drops.forEach((data) => {
      const cohort = moment(data.cohort).format("MMM YYYY");
      // This is assuming that period never goes below 1 and only process non-future cohorts
      if (Object.prototype.hasOwnProperty.call(cohorts, cohort)) {
        cohorts[cohort].values[data.period - 1] = data.dropCount;
      }
    });

    // Ensure that the accumulated values are carried across for all zero values
    // Changes 1 2 3 0 0 7 to 1 2 3 3 3 7
    // eslint-disable-next-line no-unused-vars
    Object.entries(cohorts).forEach(([cohort, data]) => {
      data.values.forEach((v, i) => {
        if (i > 0 && v === 0) {
          data.values[i] = data.values[i - 1];
        }
      });
    });

    // Produce the final table
    const rtn = [headerRow];
    Object.entries(cohorts).forEach(([cohort, data]) => {
      // Create a new row with the cohort month/year
      const cohortRow = [
        { value: cohort, readOnly: true, className: "table-cell-header" },
      ];
      data.values.forEach((cancelled) => {
        // Determine the number of people still donating at this point
        const value = data.donorCount - cancelled;
        cohortRow.push({
          value: value.toString(),
          readOnly: true,
          className: "table-cell",
        });
      });
      // Pad cells at the end
      if (cohortRow.length < maxPeriod + 1) {
        const nPaddedCells = maxPeriod - cohortRow.length + 1;
        for (let i = 0; i < nPaddedCells; i++) {
          cohortRow.push({
            value: "",
            readOnly: true,
            className: "table-cell",
          });
        }
      }
      // Ensure that only 20 columns are populated.
      // maxColumns + 1 is used to include the cohort month/year as well.
      rtn.push(cohortRow.slice(0, maxColumns + 1));
    });

    return rtn;
  });
};

export default getInstallmentData;
