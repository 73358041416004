import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Tag } from "@blueprintjs/core";
import "./FilterSidebarFilter.css";

/**
 * Filter Sidebar
 * A sidebar component used by the filter context bar that has a list of all filters a page supports.
 * @param {Object} props the data for the dropdown.
 * @return {JSX} The dropdown component rendered.
 */
function FilterSidebarFilter(props) {
  const { values, onChange, label, name, handleClearFilter } = props;
  const [open, setOpen] = useState(false);

  function handleCheckboxToggle(filterKey, value, event) {
    onChange(filterKey, value, event.target.checked);
  }

  function getSidebarFilterTag() {
    const rtn = [];
    values.forEach((value) => {
      if (value.selected) {
        rtn.push(value.key);
      }
    });
    if (rtn.length > 0)
      return (
        <Tag
          className={"sidebar-filter-tag"}
          interactive
          minimal
          icon={"small-cross"}
          onClick={() => {
            handleClearFilter(name);
          }}
        >
          {rtn.join(", ")}
        </Tag>
      );
    return <></>;
  }

  return (
    <div
      data-cy={`filter-sidebar-${name}`}
      className={"sidebar-filter-wrapper"}
    >
      <Button
        text={label}
        className={"sidebar-filter-button"}
        minimal
        onClick={() => setOpen(!open)}
        icon={open ? "chevron-up" : "chevron-down"}
        style={{ outline: "none" }}
      />
      {open && (
        <div className={"sidebar-list"}>
          {values.length > 0 &&
            values.map((item) => (
              <div className={"sidebar-content"} key={item.key}>
                <Checkbox
                  key={item.key}
                  checked={item.selected}
                  label={item.key}
                  onChange={(e) => handleCheckboxToggle(name, item.key, e)}
                />
              </div>
            ))}
          {values.length === 0 && <span>No Data</span>}
          {values.length > 0 && (
            <span
              className={"sidebar-filter-clear"}
              onClick={() => handleClearFilter(name)}
            >
              Clear
            </span>
          )}

          <hr className={"sidebar-filter-hr"} />
        </div>
      )}
      {!open && getSidebarFilterTag()}
    </div>
  );
}

FilterSidebarFilter.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.any, selected: PropTypes.bool })
  ),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  label: PropTypes.string,
  handleClearFilter: PropTypes.func,
  name: PropTypes.string,
};

export default FilterSidebarFilter;
