import data from '../data/config.json';

const RECAPTCHA_SITE_KEY = '6LfLNsIUAAAAAHoaqKvcJ6pIWqYmWNKQTOsbYF43';

let endpoint;
switch (data.environment) {
  case 'prd': {
    endpoint = 'https://api.givinginsight.com/';
    break;
  }
  case 'stg': {
    endpoint = 'https://api.stg.givinginsight.com/';
    break;
  }
  case 'dev': {
    endpoint = 'https://api.dev.givinginsight.com/';
    break;
  }
  default: {
    endpoint = 'http://localhost:8080/';
  }
}
console.log('Running in "' + data.environment + '", API: "' + endpoint + '".');

const API_ENDPOINT = endpoint;

export { API_ENDPOINT, RECAPTCHA_SITE_KEY };
