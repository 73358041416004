import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ContextTagSection.css'
import {filterDefinition} from "../../../app/filters";
import {bindActionCreators} from "redux";
import {
    modifyFilterSelectionsAction,
    modifyFilterValuesAction
} from "../../../redux/actions/filters.action";
import {connect} from "react-redux";
import ContextTag from "../ContextTag";
import {useDebounce} from "../../../app/hooks";
import * as deepEqual from "deep-equal";

/**
 * ContextFilterTag
 * A atomic filter tag that shows which filters have been applied. Can call the parent component to notify that a
 * clear event has been triggered for the filter.
 * @param {Object} props the data for the filter.
 * @return {JSX} The filter component rendered.
 */
function ContextTagSection(props) {
    const {sidebarFilters, filterSelections, filterValues, parentLoading} = props;
    const [tmpFilterSelections, setTmpFilterSelections] = useState(filterSelections);
    const debouncedFilter = useDebounce(tmpFilterSelections, 1000);

    /**
     * Effect run whenever the debounced filter updates. The debounced filter controls how often the filter bar
     * propagates updates to its parent component. When the useDebounce hook notifies that a delay of x ms has passed
     * since the last change, this effect runs.
     *
     * Note that there is a secondary filter too that stops the effect from executing if the component is in a loading
     * state. This shouldn't occur in reality, but its a good bit of extra checking.
     */
    useEffect(() => {
        if (debouncedFilter && !(parentLoading)) {
              props.modifyFilterSelectionsAction(tmpFilterSelections);
        }
        // eslint-disable-next-line
    }, [debouncedFilter]);

    useEffect(() => {
        if (!deepEqual(filterSelections, tmpFilterSelections)) {
            setTmpFilterSelections(filterSelections)
        }
        // eslint-disable-next-line
    }, [filterSelections]);

    /**
     * Clear an individual filter of its values.
     * @param filterKey The filter to clear all selections from.
     */
    function handleFilterClear(filterKey) {
        const selections = {...tmpFilterSelections};
        const filter = filterDefinition[filterKey];
        if (filter.type === 'Bool') {
            selections[filterKey] = false;
            setTmpFilterSelections(selections);
        }
        if (['ChoiceList','ChoiceListWithContext'].includes(filter.type)) {
            if (selections[filterKey].length > 0) {
                selections[filterKey] = [];
            }
            const comparisonFilterIndex = selections['comparison-filters'].indexOf(filterKey);
            if (comparisonFilterIndex > -1) {
                selections['comparison-filters'].splice(comparisonFilterIndex, 1);
            }
        }
        setTmpFilterSelections(selections);
    }

    /**
     * For a given filter, fetch the dropdown values that child components expect in an array. When queried, the
     * function will return an array with 1 entry per filter value, with the key set to the filter's name.
     * Each object will also have a selected attribute showing if this value is currently selected.
     * @param filterKey
     * @returns {[]}
     */
    function getValues(filterKey) {
        const rtn = [];
        const filter = filterDefinition[filterKey];
        if (filter.type === 'Bool') {
            if (filterKey in filterValues) {
                rtn.push({
                    key: 'Enabled',
                    selected: tmpFilterSelections[filterKey] === true,
                })
            }
        }
        if (['ChoiceList','ChoiceListWithContext'].includes(filter.type)) {
            if (filterKey in filterValues) {
                Object.keys(filterValues[filterKey]).forEach((index) => {
                    rtn.push({
                        key: filterValues[filterKey][index],
                        selected: tmpFilterSelections[filterKey].includes(filterValues[filterKey][index])
                    })
                })
            }
        }
        return rtn;
    }

    return (
        <div className={'context-tag-section'}>
            {sidebarFilters.map((filter) => {
                return <ContextTag
                    name={filter}
                    handleFilterClear={handleFilterClear}
                    key={filter}
                    values={getValues(filter)}
                />
            })}
        </div>
    )
}

const mapStateToProps = ({filterSelections, filterValues}) => {
    return {
        filterSelections,
        filterValues,
    };
};

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        modifyFilterSelectionsAction: modifyFilterSelectionsAction,
        modifyFilterValuesAction: modifyFilterValuesAction,
    }, dispatch);
};

ContextTagSection.propTypes = {
    shortcutFilters: PropTypes.arrayOf(PropTypes.string),
    sidebarFilters: PropTypes.arrayOf(PropTypes.string),
    parentLoading: PropTypes.bool,
    modifyFilterSelectionsAction: PropTypes.func,
    filterValues: PropTypes.object,
    filterSelections: PropTypes.object,
};

export default connect(mapStateToProps, mapActionsToProps)(ContextTagSection);
