const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MONTH_NUMBERS = [
  'Month 1',
  'Month 2',
  'Month 3',
  'Month 4',
  'Month 5',
  'Month 6',
  'Month 7',
  'Month 8',
  'Month 9',
  'Month 10',
  'Month 11',
  'Month 12',
];

const YEARS = [
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
];

const YEAR_NUMBERS = [
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
];

// this should be retrieved from the database in the future
const ATTRITION_VALUES = [
  [
    '9.00%',
    '7.92%',
    '6.97%',
    '6.13%',
    '5.40%',
    '4.75%',
    '4.18%',
    '3.68%',
    '3.24%',
    '2.85%',
    '2.51%',
    '2.48%'],
  [
    '2.46%',
    '2.43%',
    '2.41%',
    '2.38%',
    '2.36%',
    '2.34%',
    '2.31%',
    '2.29%',
    '2.27%',
    '2.24%',
    '2.13%',
    '2.03%'],
  [
    '1.92%',
    '1.83%',
    '1.74%',
    '1.65%',
    '1.57%',
    '1.49%',
    '1.41%',
    '1.34%',
    '1.28%',
    '1.21%',
    '1.16%',
    '1.12%'],
  [
    '1.07%',
    '1.03%',
    '0.99%',
    '0.95%',
    '0.91%',
    '0.87%',
    '0.84%',
    '0.81%',
    '0.77%',
    '0.74%',
    '0.71%',
    '0.67%'],
  [
    '0.64%',
    '0.61%',
    '0.57%',
    '0.55%',
    '0.52%',
    '0.49%',
    '0.47%',
    '0.44%',
    '0.42%',
    '0.40%',
    '0.38%',
    '0.38%'],
];

const DAY_OF_WEEK = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thurs',
  'Fri',
  'Sat'
];

const ATTRITION_LENGTH = 15;

export {
  MONTHS,
  YEARS,
  MONTH_NUMBERS,
  YEAR_NUMBERS,
  ATTRITION_VALUES,
  ATTRITION_LENGTH,
  DAY_OF_WEEK,
};
