import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './SecondaryBar.css';
import { getClientType } from '../../app/auth';
import { logUsage } from '../../app/utils';
import { useLocation } from 'react-router';

/**
 * The Secondary Bar
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
function SecondaryBar() {
  const { pathname } = useLocation();

  const navElements = [];

  if (pathname.startsWith('/analytics')) {
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-kpi"
        name="Regular Giving KPIs"
        link={`/analytics/kpi`}
        key="regular-giving-kpis"
        onClick={() => logUsage('Browse', 'Analytics', 'KPI')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-instalment"
        name="Attrition View"
        link={`/analytics/installment`}
        key="installment-view"
        onClick={() => logUsage('Browse', 'Analytics', 'Installment View')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-calendar"
        name="Calendar View"
        link={`/analytics/calendar`}
        key="calendar-view"
        onClick={() => logUsage('Browse', 'Analytics', 'Calendar View')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-data_quality"
        name="Data Quality"
        link={`/analytics/data_quality`}
        key="data-quality"
        onClick={() => logUsage('Browse', 'Analytics', 'Data Quality')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-hall_of_fame"
        name="Hall of Fame"
        link={`/analytics/hall_of_fame`}
        key="hall-of-fame"
        onClick={() => logUsage('Browse', 'Analytics', 'Hall of Fame')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-declines"
        name="Declines"
        link={`/analytics/declines`}
        key="declines"
        onClick={() => logUsage('Browse', 'Analytics', 'Declines')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-best_donor_segment"
        name="Best Donor Segment"
        link={`/analytics/best_donor_segment`}
        key="best-donor-segment"
        onClick={() => logUsage('Browse', 'Analytics', 'Best Donor Segment')}
      />,
    );
  } else if (pathname.startsWith('/forecast')) {
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-new_forecast"
        name="New Forecast"
        link="/forecast/new"
        key="new-forecast"
        onClick={() => logUsage('Browse', 'Analytics', 'View Forecasts', 'Attrition Forecast')}
      />,
    );
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-view_forecast"
        name="View Forecasts"
        link="/forecast/view"
        key="view-forecasts"
        onClick={() => logUsage('Browse', 'Analytics', 'View Forecasts', 'Attrition Forecast')}
      />,
    );
    /*navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-attrition_forecast"
        name="Attrition Forecast"
        link="/forecast/attrition-forecast"
        key="attrition-forecast"
        onClick={() =>
          logUsage(
            "Browse",
            "Analytics",
            "View Forecasts",
            "Attrition Forecast"
          )
        }
      />
    );*/
  } else if (pathname.startsWith('/settings')) {
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-members"
        name="Members"
        link="/settings/members"
        key="settings-members"
        onClick={() => logUsage('Browse', 'Settings', 'Members')}
      />,
    );
    if (getClientType() === 'ThankQ') {
      navElements.push(
        <NavigationElement
          componentSelectorName="sub_navbar-item-import_data"
          name="Import Data"
          link="/settings/import"
          key="settings-import"
          onClick={() => logUsage('Browse', 'Settings', 'ThankQ Import Data')}
        />,
      );
    }
    navElements.push(
      <NavigationElement
        componentSelectorName="sub_navbar-item-data"
        name="Data"
        link="/settings/data"
        key="settings-data"
      />,
    );
  }
  return (
    <div className="bothead_container">
      <div className="secondary_menus">
        <ul className="sec_ul">{navElements}</ul>
      </div>
    </div>
  );
}

/**
 * The navigation element
 * @param {Object} props the data for the navigation element
 * @return {JSX}
 */
function NavigationElement(props) {
  const { componentSelectorName, name, link, onClick } = props;
  return (
    <li data-cy={componentSelectorName}>
      <NavLink to={link} onClick={onClick}>
        {name}
      </NavLink>
    </li>
  );
}

NavigationElement.propTypes = {
  componentSelectorName: PropTypes.string,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SecondaryBar;
