import promiseAllProperties from "promise-all-properties";
import { getData } from "../../app/data";
import { API_ENDPOINT } from "../../app/config";
import { getBearerToken } from "../../app/auth";

const getViewForecastData = (filters) => {
  const promises = {
    existingForecasts: getExistingForecasts(filters),
  };
  return promiseAllProperties(promises);
};

const getExistingForecasts = async (selectedFilters = {}) => {
  return getData("forecast/view", selectedFilters).then((res) => {
    if (res === null || res.length === 0) {
      return [];
    }
    // Declare a map of forecasts, this will be 1 entry per forecast
    const forecasts = new Map();
    res.forEach(function (forecastVersion) {
      let forecast;
      if (forecasts.has(forecastVersion.forecast_id)) {
        forecast = forecasts.get(forecastVersion.forecast_id);
      } else {
        forecast = {
          forecastId: forecastVersion.forecast_id,
          channel: forecastVersion.forecast_channel,
          supplier: forecastVersion.forecast_supplier,
          baselineForecastFlag: forecastVersion.baseline_forecast_flag,
          latestVersionNum: 0,
          versions: [],
        };
      }
      forecast.versions.push({
        versionName: forecastVersion.version_name,
        versionNumber: forecastVersion.version_number,
        versionId: forecastVersion.forecast_version_id,
        exportFileUrl: forecastVersion.export_file_url,
        forecastStartDate: forecastVersion.forecast_start_date,
        createdDate: forecastVersion.created_date,
        lastUpdatedDate: forecastVersion.last_updated_date,
      });
      if (forecastVersion.latest_version_flag) {
        forecast.latestVersionNum = forecast.versions.length - 1;
      }
      // Update the map reference
      forecasts.set(forecastVersion.forecast_id, forecast);
    });
    const rtn = [];
    // eslint-disable-next-line no-unused-vars
    forecasts.forEach(function (element, key, map) {
      rtn.push(forecasts.get(key));
    });
    return rtn;
  });
};

export const deleteForecastVersion = async (versionId) => {
  return fetch(API_ENDPOINT + "forecast/remove/" + versionId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getBearerToken()}`,
    },
  }).then((response) => response.json());
};

export default getViewForecastData;
