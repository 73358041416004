import React from 'react';
import './Logout.css';
import { Navigate } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
function Logout(props) {
  // Remove the authorisation token.
  localStorage.clear();

  // Redirect back to the login screen.
  return <Navigate replace to={'/login'} />;
}

export default Logout;
