import moment from 'moment';
import numeral from 'numeral';
import { MONTHS } from './constants';
import { API_ENDPOINT } from './config';
import { getBearerToken, getUserId } from './auth';
import { useLocation } from 'react-router';
import { utils, writeFileXLSX } from 'xlsx';
import { filterDefinition } from './filters';

const globalLocale = numeral.localeData();
globalLocale.abbreviations = {
  billion: 'B',
  million: 'M',
  thousand: 'K',
  trillion: 'T',
};

/**
 * Formats the value in the specified format
 *
 * @param {String} value The Value to format
 * @param {String} valueFormat The format to use
 *
 * @return {String} The formatted value if neither {@link value} nor {@link valueFormat} are null.
 * Otherwise null is returned if {@link value} is null and value if {@link valueFormat} is null.
 */
function formatValue(value, valueFormat) {
  if (isEmpty(value)) {
    return null;
  }
  if (value === '...') {
    return value;
  }
  if (isEmpty(valueFormat)) {
    return value;
  }
  const result = numeral(value).format(valueFormat);

  if (valueFormat === '0.00a' && Math.abs(value) < 1000) {
    const split = result.split('.');
    if (split[1] === '00') {
      return split[0];
    }
  }
  return result;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getQueryParams(selectedFilters, join = '?') {
  let queryParam = join;
  for (const queryName in selectedFilters) {
    const selected = selectedFilters[queryName];
    if (selected.constructor === Array) {
      if (selected.length > 0) {
        queryParam += `${queryName}=${JSON.stringify(selected)}&`;
      }
    } else {
      queryParam += `${queryName}=${selected}&`;
    }
  }
  return queryParam;
}

function compareDates(a, b) {
  const aSplit = a.split(' ');
  const bSplit = b.split(' ');

  if (aSplit[1] !== bSplit[1]) {
    return Number(aSplit[1]) - Number(bSplit[1]);
  }

  const aMonthIndex = MONTHS.indexOf(aSplit[0]);
  const bMonthIndex = MONTHS.indexOf(bSplit[0]);

  return aMonthIndex - bMonthIndex;
}

function isEmpty(value) {
  return value === undefined || value === null;
}

function getNextMonthYear(date) {
  if (typeof date === 'undefined') {
    date = new Date();
  }
  let newYear = '';
  let newMonth = '';
  if (date.getMonth() + 1 > 12) {
    newYear = (date.getFullYear() + 1).toString();
    newMonth = (((date.getMonth() + 1) % 12) + 1).toString();
  } else {
    newYear = date.getFullYear().toString();
    newMonth = (((date.getMonth() + 1) % 12) + 1).toString();
  }
  return newMonth.length < 2 ? newYear + '-0' + newMonth : newYear + '-' + newMonth;
}

function getForecastYearRange(startingMonthYear) {
  let startingYear = 0;
  if (typeof startingMonthYear === 'undefined') {
    startingYear = new Date().getFullYear();
  } else {
    startingYear = parseInt(startingMonthYear.substring(0, 4), 10);
  }
  const forecastYearRange = [];
  for (let i = 0; i < 10; i++) {
    forecastYearRange.push((startingYear + i).toString());
  }
  return forecastYearRange;
}

function getForecastMonthOrder(startingMonthYear) {
  let rotateTimes = 0;
  if (typeof startingMonthYear === 'undefined') {
    rotateTimes = new Date().getMonth();
  } else {
    rotateTimes = parseInt(startingMonthYear.substring(5, 7), 10);
  }
  const forecastMonthOrder = MONTHS;
  while (rotateTimes > 1) {
    forecastMonthOrder.push(forecastMonthOrder.shift());
    rotateTimes--;
  }
  return forecastMonthOrder;
}

/**
 * Convert Date into date string in format: Month Year e.g. Mar 2019
 * @param {Date} dateString: input date string
 */
function convertDateToMonthYear(dateString) {
  return moment(dateString).format('MMM YYYY');
}

/**
 * Convert Date into date string in format: Month Year e.g. Mar 2019
 * @param {Date} dateString: input date string
 */
function convertDateToMonthYearFull(dateString) {
  return moment(dateString).format('MMMM YYYY');
}

/**
 * Convert Date into date string in format: Month Year e.g. Mar 2019
 * @param {Date} dateString: input date string
 */
function convertDateToRelative(dateString) {
  return moment(dateString).fromNow();
}

/**
 * Convert Date into date string in format: Month Year e.g. Mar 2019
 * @param {Date} dateString: input date string
 */
function formatDate(dateString) {
  return moment(dateString).format('LLLL');
}

/**
 * TODO: merge two arrays of objects into one array using specific key
 * @param {Array} array1
 * @param {Array} array2
 * @param {String} mergeKey
 */
// eslint-disable-next-line no-unused-vars
const mergeArrayByKey = (array1, array2, mergeKey) => {
  const result = [];
  return result;
};

// Calculates the dividend (ratio) of two numbers, avoids divide by zero errors.
function dividend(numerator, denominator) {
  const quotient = numerator / denominator;
  // eslint-disable-next-line no-self-compare
  if (quotient !== quotient) {
    throw new Error(numerator + ' / ' + denominator);
  }
  return quotient;
}

// Validates an email by a regex format string
// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Returns the default layout of the Forecast Donor Grid.
 * @return {*[]} A list of list of lists.
 */
function getDefaultForecastDonorGrid() {
  return [
    [
      { readOnly: true, value: '' },
      { value: 'Month 1', readOnly: true },
      { value: 'Month 2', readOnly: true },
      { value: 'Month 3', readOnly: true },
      { value: 'Month 4', readOnly: true },
      { value: 'Month 5', readOnly: true },
      { value: 'Month 6', readOnly: true },
      { value: 'Month 7', readOnly: true },
      { value: 'Month 8', readOnly: true },
      { value: 'Month 9', readOnly: true },
      { value: 'Month 10', readOnly: true },
      { value: 'Month 11', readOnly: true },
      { value: 'Month 12', readOnly: true },
    ],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 1 *' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 2' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 3' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 4' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 5' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 6' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 7' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 8' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 9' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    // eslint-disable-next-line max-len
    [{ readOnly: true, value: 'Year 10' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
  ];
}

/**
 * Returns the layout of the Forecast Donor Count Grid based on the matrix
 * @param {*[]} matrix - a list of lists containing the donor counts
 * @return {*[]} a grid that is compatible with the grid library
 */
function getForecastDonorGrid(matrix) {
  const grid = getDefaultForecastDonorGrid();

  matrix.forEach((row, rowIndex) => {
    row.forEach((value, columnIndex) => {
      grid[rowIndex + 1][columnIndex + 1] = { value: value.toString() };
    });
  });

  return grid;
}

/**
 * Returns the layout of the Forecast Attrition Grid based on the matrix
 * @param {*[]} matrix - a list of lists containing the donor counts
 * @return {*[]} a grid that is compatible with the grid library
 */
function getForecastAttritionGrid(matrix) {
  const grid = getDefaultAttritionGrid();

  matrix.forEach((row, rowIndex) => {
    row.forEach((value, columnIndex) => {
      grid[rowIndex + 1][columnIndex + 1] = {
        value: `${(value * 100).toFixed(2)}%`,
      };
    });
  });

  return grid;
}

/**
 * Returns the default layout of the Forecast Attrition Grid.
 * @return {*[]} A list of list of lists.
 */
function getDefaultAttritionGrid() {
  return [
    [
      { readOnly: true, value: '' },
      { value: 'Month 1', readOnly: true },
      { value: 'Month 2', readOnly: true },
      { value: 'Month 3', readOnly: true },
      { value: 'Month 4', readOnly: true },
      { value: 'Month 5', readOnly: true },
      { value: 'Month 6', readOnly: true },
      { value: 'Month 7', readOnly: true },
      { value: 'Month 8', readOnly: true },
      { value: 'Month 9', readOnly: true },
      { value: 'Month 10', readOnly: true },
      { value: 'Month 11', readOnly: true },
      { value: 'Month 12', readOnly: true },
    ],
    // eslint-disable-next-line max-len
    [
      { value: 'Year 1', readOnly: true },
      { value: '9.00%' },
      { value: '7.92%' },
      { value: '6.97%' },
      { value: '6.13%' },
      { value: '5.40%' },
      { value: '4.75%' },
      { value: '4.18%' },
      { value: '3.68%' },
      { value: '3.24%' },
      { value: '2.85%' },
      { value: '2.51%' },
      { value: '2.48%' },
    ],
    // eslint-disable-next-line max-len
    [
      { value: 'Year 2', readOnly: true },
      { value: '2.46%' },
      { value: '2.43%' },
      { value: '2.41%' },
      { value: '2.38%' },
      { value: '2.36%' },
      { value: '2.34%' },
      { value: '2.31%' },
      { value: '2.29%' },
      { value: '2.27%' },
      { value: '2.24%' },
      { value: '2.13%' },
      { value: '2.03%' },
    ],
    // eslint-disable-next-line max-len
    [
      { value: 'Year 3', readOnly: true },
      { value: '1.92%' },
      { value: '1.83%' },
      { value: '1.74%' },
      { value: '1.65%' },
      { value: '1.57%' },
      { value: '1.49%' },
      { value: '1.41%' },
      { value: '1.34%' },
      { value: '1.28%' },
      { value: '1.21%' },
      { value: '1.16%' },
      { value: '1.12%' },
    ],
    // eslint-disable-next-line max-len
    [
      { value: 'Year 4', readOnly: true },
      { value: '1.07%' },
      { value: '1.03%' },
      { value: '0.99%' },
      { value: '0.95%' },
      { value: '0.91%' },
      { value: '0.87%' },
      { value: '0.84%' },
      { value: '0.81%' },
      { value: '0.77%' },
      { value: '0.74%' },
      { value: '0.71%' },
      { value: '0.67%' },
    ],
    // eslint-disable-next-line max-len
    [
      { value: 'Year 5', readOnly: true },
      { value: '0.64%' },
      { value: '0.61%' },
      { value: '0.57%' },
      { value: '0.55%' },
      { value: '0.52%' },
      { value: '0.49%' },
      { value: '0.47%' },
      { value: '0.44%' },
      { value: '0.42%' },
      { value: '0.40%' },
      { value: '0.38%' },
      { value: '0.38%' },
    ],
  ];
}

function isNan(element) {
  return isNaN(element);
}

function isPercentage(element) {
  const y = parseFloat(element);
  if (!isNan(y)) {
    return true;
  }
  return false;
}

function parsePercentage(element) {
  const y = parseFloat(element);
  if (!isNan(y)) {
    return y;
  }
  return 0;
}

const colourPallete = ['#668586', '#94b780', '#93c6d6', '#a7acd9', '#f7a269', '#ade25d'];

/**
 * Returns a colour selection from the colourPallete array based on
 * the provided index.
 * @param {Number} index - the index to get a colour for
 * @return {String} - the selected colour
 */
function getColour(index) {
  return colourPallete[index % colourPallete.length];
}

function shadeColour(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);
  return '#' + RR + GG + BB;
}

/**
 * Generates a random UUID-like string for a div or similar element to use
 * as an ID. Its stupendously unlikely to conflict with another id in the
 * application.
 * Pretty much if you do happen to get one conflicting, I'll eat my hat.
 * Fun fact, I think the odds are 1 in 2,821,000,000,000, the odds of winning
 * $10k off a $1 scratchie is 1 in 875,000.
 * @return {string} The unique ID for the element prefixed with 'id-'
 */
function newId() {
  return 'id-xxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/*
 * Calls the track_usage backend endpoint to log user Giving Insight activities
 */
const logUsage = async (activity, primaryNavName, secondaryNavName) => {
  const usageData = {
    userId: getUserId(),
    activity: activity,
    functionality: primaryNavName,
    page: secondaryNavName,
  };
  fetch(API_ENDPOINT + 'track_usage', {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${getBearerToken()}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(usageData),
  })
    .then((response) => response.json())
    .then((result) => {
      if (true === result.success) {
        console.log('Usage logged', usageData);
      } else {
        console.log('Cannot log usage');
      }
    });
};

const mod = (n, m) => {
  return ((n % m) + m) % m;
};

const getAUFinancialMonthNumber = (monthString) => {
  return mod(moment().month(monthString).format('M') - 7, 12);
};

const downloadSpreadsheet = (filename, filters, data) => {
  const workBook = utils.book_new();

  // Add Filters Worksheet
  const filterWorksheet = utils.json_to_sheet(filters);
  utils.book_append_sheet(workBook, filterWorksheet, 'Filter Selections');

  // Add Worksheets
  data.forEach((tab) => {
    const { tabName, tabData, header } = tab;
    const workSheet = utils.json_to_sheet(tabData, { header: header });

    utils.book_append_sheet(workBook, workSheet, tabName);
  });

  // Write the Excel file
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const localISODate = new Date(Date.now() - timezoneOffset).toISOString().slice(0, -5);
  writeFileXLSX(workBook, `${filename}_${localISODate}.xlsx`);
};

const selectedFilters = (filterSelections) => {
  const filters = [];
  const filterKeys = Object.keys(filterSelections).filter((key) => key !== 'comparison-filters');

  for (const filterKey of filterKeys) {
    if (Array.isArray(filterSelections[filterKey]) && filterSelections[filterKey].length > 0) {
      filters.push({
        Filter: filterKey,
        Selection: filterDefinition[filterKey].disambiguator(filterSelections[filterKey]),
      });
    } else {
      if (typeof filterSelections[filterKey] === 'boolean' && filterSelections[filterKey]) {
        filters.push({
          Filter: filterKey,
          Selection: filterDefinition[filterKey].disambiguator(filterSelections[filterKey]),
        });
      }
    }
  }

  if (filters.length < 1) {
    filters.push({ Filter: 'No filters applied' });
  }

  return filters;
};

const renameKeys = (keysMap, object) =>
  Object.keys(object).reduce(
    (accumulator, key) => ({
      ...accumulator,
      ...{ [keysMap[key] || key]: object[key] },
    }),
    {},
  );

export {
  newId,
  getColour,
  shadeColour,
  isNan,
  parsePercentage,
  isPercentage,
  getDefaultForecastDonorGrid,
  getDefaultAttritionGrid,
  validateEmail,
  dividend,
  formatDate,
  formatValue,
  getQueryParams,
  compareDates,
  getNextMonthYear,
  getForecastYearRange,
  getForecastMonthOrder,
  convertDateToMonthYear,
  convertDateToMonthYearFull,
  convertDateToRelative,
  mergeArrayByKey,
  getForecastDonorGrid,
  getForecastAttritionGrid,
  logUsage,
  mod,
  getAUFinancialMonthNumber,
  useQuery,
  downloadSpreadsheet,
  selectedFilters,
  renameKeys,
};
