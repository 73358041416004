/**
 * action = filterReducer
 *   type: "STRING",
 *   payload: <DATA>>"
 * }
 */
import {
  UPDATE_FILTER_SELECTIONS,
  UPDATE_FILTER_VALUES,
} from "../actions/filters.action";
import moment from "moment";
import { dateValues } from "../../app/filters";

export const INITIAL_FILTER_VALUE_STATE = {
  "gift-start-year": [],
  "gift-start-financial-year": [],
  "gift-start-month": dateValues(),
  "transaction-year": [],
  "transaction-financial-year": [],
  "transaction-month": dateValues(),
  frequency: [],
  "gift-status": [],
  channel: [],
  "freebie-status": [],
  fund: [],
  fundraiser: [],
  campaign: [],
  "cancel-reason": [],
  appeal: [],
  supplier: [],
  gender: [],
  "gift-range": [],
  "payment-method": [],
  "age-range": [],
  "last-refreshed": moment().add(-10, "day"),
  "forecast-reporting-year": [],
  "forecast-channel": [],
  "forecast-supplier": [],
};

export const INITIAL_FILTER_SELECTION_STATE = {
  "gift-start-year": [],
  "gift-start-financial-year": [],
  "gift-start-month": false,
  "transaction-year": [],
  "transaction-financial-year": [],
  "transaction-month": true,
  frequency: [],
  "gift-status": [],
  channel: [],
  "freebie-status": [],
  fund: [],
  fundraiser: [],
  campaign: [],
  "cancel-reason": [],
  appeal: [],
  supplier: [],
  gender: [],
  "gift-range": [],
  "payment-method": [],
  "age-range": [],
  "comparison-filters": [],
  "forecast-reporting-year": [],
  "forecast-channel": [],
  "forecast-supplier": [],
};

export function filterSelectionReducer(
  state = INITIAL_FILTER_SELECTION_STATE,
  action = {}
) {
  switch (action.type) {
    case UPDATE_FILTER_SELECTIONS: {
      if (state === action.payload.filterSelections) {
        return state;
      }
      return { ...state, ...action.payload.filterSelections };
    }
    default:
      return state;
  }
}

export function filterValueReducer(
  state = INITIAL_FILTER_VALUE_STATE,
  action = {}
) {
  switch (action.type) {
    case UPDATE_FILTER_VALUES: {
      return { ...state, ...action.payload.filterValues };
    }
    default:
      return state;
  }
}
