import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';

import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import { sendResetToken, resetPassword } from '../../app/auth';

import './PasswordReset.css';

/**
 * The reset password screen view, presented without any branding, nav or
 * footers to allow the user to reset their password.
 */
class PasswordReset extends Component {
  /**
   * Default constructor for the component
   * @param {Object} props The properties passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      passwordStrength: 0,
      confirmPassword: '',
      token: '',
      errorMsg: '',
      tokenSuccess: false,
      tokenSuccessMsg: '',
      resetSuccess: false,
      resetSuccessMsg: '',
      clientIP: [],
      isLoading: false,
    };
  }

  /**
   * Function executed when the username field is modified
   * @param {Object} e The event triggering the function.
   */
  handleUsernameChange(e) {
    const value = e.target.value;
    this.setState({
      username: value,
    });
  }
  /**
   * Function executed when the password field is modified
   * @param {Object} e The event triggering the function.
   */
  handlePasswordChange(e) {
    const value = e.target.value;
    this.setState({
      password: value,
    });
  }
  /**
   * Function executed when the confirmation password field is modified
   * @param {Object} e The event triggering the function.
   */
  handleConfirmPasswordChange(e) {
    const value = e.target.value;
    this.setState({
      confirmPassword: value,
    });
  }
  /**
   * Function executed when the reset token field is modified
   * @param {Object} e The event triggering the function.
   */
  handleTokenChange(e) {
    const value = e.target.value;
    this.setState({
      token: value,
    });
  }
  /**
   * Function executed when the organisation (client) field is modified
   * @param {Object} selectedValue The event triggering the function.
   */
  selectedValueHandler(selectedValue) {
    if (selectedValue !== this.state.passwordStrength) {
      this.setState({
        passwordStrength: selectedValue,
      });
    }
  }
  /**
   * Function executed when the submit button is pressed after all fields
   * have been populated.
   * @param {Object} e The event triggering the submission.
   */
  async handleSubmitPasswordReset(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const res = await resetPassword(this.state.username, this.state.token, this.state.password);
    if (res.status_code !== 200) {
      this.setState({
        errorMsg: res.message,
      });
    } else {
      this.setState({
        resetSuccess: true,
        resetSuccessMsg: res.message,
      });
    }
  }
  /**
   * Function executed when the submit button is pressed for requesting a
   * password reset token.
   * @param {Object} e The event triggering the submission.
   */
  async handleSubmit(e) {
    e.preventDefault();
    // get client IP address
    this.setState({
      isLoading: true,
    });

    const res = await sendResetToken(this.state.username);
    if (res.status_code !== 200) {
      this.setState({
        errorMsg: res.message,
      });
    } else {
      this.setState({
        tokenSuccess: true,
        tokenSuccessMsg: res.message,
        errorMsg: '',
        isLoading: false,
      });
    }
  }
  /**
   * The render function for the component, returns the password reset screen.
   * @return {JSX}
   */
  render() {
    const { resetSuccess, password, isLoading } = this.state;
    if (resetSuccess) {
      return (
        <Navigate
          to={{
            pathname: '/login',
            state: {
              registerMsg: 'Successfully reset password, please login.',
            },
          }}
        />
      );
    }
    // The Spinner icon if the isLoading is true
    let loadingSpinner;
    if (isLoading) {
      loadingSpinner = <Spinner color="primary" />;
    }
    return (
      <div className="container">
        <div className="register-parent">
          <Card className="register-card">
            <CardBody className="register-title">
              <h5>Recover Your Password</h5>
              <br />
              <Form action="#" onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                  <Label for="username" className={'black-label'}>
                    Email
                  </Label>
                  <Input
                    type="text"
                    name="Email"
                    id="Email"
                    onChange={this.handleUsernameChange.bind(this)}
                  />
                </FormGroup>
                {this.state.tokenSuccess ? (
                  <div>
                    <FormGroup>
                      <p className="token-msg">{this.state.tokenSuccessMsg}</p>
                    </FormGroup>
                    <FormGroup>
                      <Label for="token" className={'black-label'}>
                        Token from Email
                      </Label>
                      <Input
                        type="text"
                        name="Token"
                        id="token"
                        onChange={this.handleTokenChange.bind(this)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password" className={'black-label'}>
                        New Password (Must be good or better)
                      </Label>
                      <Input
                        type="password"
                        name="New Password"
                        id="password"
                        onChange={this.handlePasswordChange.bind(this)}
                      />
                      <div className="meter" id="strengthMeter">
                        <PasswordStrengthMeter
                          className="password-strength-meter"
                          password={password}
                          selectedValueHandler={this.selectedValueHandler.bind(this)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirm_password" className={'black-label'}>
                        Confirm New Password
                      </Label>
                      <Input
                        type="password"
                        name="Confirm New Password"
                        id="confirm_password"
                        onChange={this.handleConfirmPasswordChange.bind(this)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Button type="button" onClick={this.handleSubmitPasswordReset.bind(this)}>
                            Reset Password
                          </Button>
                        </Col>
                        <Col>{loadingSpinner}</Col>
                      </Row>
                    </FormGroup>
                  </div>
                ) : (
                  <div>
                    <FormGroup>
                      <Row>
                        <Col>
                          <Button type="submit">Recover</Button>
                        </Col>
                        <Col>{loadingSpinner}</Col>
                      </Row>
                    </FormGroup>
                  </div>
                )}
                <FormGroup>
                  {this.state.errorMsg.length > 0 && (
                    <p className="error-msg">{this.state.errorMsg}</p>
                  )}
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
export default PasswordReset;
