import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './ForecastRow.css';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Collapse, ListGroup,
} from 'reactstrap';
import ForecastVersionRow from '../ForecastVersionRow';

/**
 * A Data Card  component containing a header, icon, figure and a footer context
 * description. These are primarily used on the homepage.
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
class ForecastRow extends Component {
  /**
   * Default constructor for the component
   * @param {Object} props The properties passed to the component
   */
  constructor(props) {
    super(props);
    this.state ={
      viewRedirect: false,
      deleteRedirect: false,
      forecastVersionId: props.forecastVersionId,
      isOpen: false,
    };
  }
  /**
   * Toggle if the forecast row is open.
   */
  toggleOpen() {
    const {isOpen} = this.state;
    this.setState({isOpen: !isOpen});
  }

  /**
   * Toggle if this item is in the new collection being created.
   * @param {string} forecastVersionId The forecast version to toggle in and out
   * of the collection.
   */
  toggleCollection(forecastVersionId) {
    const {collection} = this.state;
    if (forecastVersionId in collection) {
      collection.delete(forecastVersionId);
    } else {
      collection[forecastVersionId] = true;
    }
    this.setState({collection: collection});
  }
  /**
   * The render function for the component, returns the Data Card for the given
   * properties.
   * @return {JSX}
   */
  render() {
    const {data} = this.props;
    const {isOpen} = this.state;
    // Get the latest Record Data
    const latestVersion = data.versions[data.latestVersionNum];
    // Generate list items for each row in the forecast versions
    const listDiv = data.versions.map((item, key) =>
      <ForecastVersionRow forecast={item} key={key}
        handleDelete={this.props.handleDelete}
        toggleActive={this.props.toggleActive}/>
    );
    // This is the card for baseline forecasts, they just get a header and a
    // description without much else.
    if (data.baselineForecastFlag) {
      return (
        <Card>
          <CardHeader>
            <b>Baseline Forecast</b>
          </CardHeader>
          <CardBody>
            This is a predictive forecast of your future revenue based on
            existing data.
            <Button outline color="secondary" size="sm"
              className={'rightButton'}
              href={'/forecast/results/baseline/'+
              latestVersion.versionId}>
              View
            </Button>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card className={'forecastCard'}>
          <CardHeader>
            <b>Campaign Forecast: </b>
            Supplied by {data.supplier} through a {data.channel} channel.
            <ButtonGroup className={'forecastActions'}>
              <Button outline color="secondary" size="sm"
                href={'/forecast/results?forecast_version_id[]='+
                latestVersion.versionId}>
                View latest version
              </Button>
              <Button outline color="secondary" size="sm"
                onClick={this.toggleOpen.bind(this)}>
                Show all versions
              </Button>
            </ButtonGroup>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <ListGroup>
                {listDiv}
              </ListGroup>
            </CardBody>
          </Collapse>
        </Card>
      );
    }
  }
}

/**
 * Define the types of each property passed to the component.
 */
ForecastRow.propTypes = {
  data: PropTypes.object,
  forecastVersionId: PropTypes.string,
  handleDelete: PropTypes.func,
  toggleActive: PropTypes.func,
};
export default ForecastRow;
