import { jwtDecode } from 'jwt-decode';
import { API_ENDPOINT } from '../app/config';

/**
 * @param {String} username
 * @param {String} password
 * @return {Promise}
 */
function login(username, password) {
  return new Promise((resolve) => {
    const body = {
      username: username,
      password: password,
    };
    fetch(API_ENDPOINT + 'auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      });
  });
}

/**
 * Attempt to verify and activate a user with the backend using username and verification code.
 * @param {String} username
 * @param {String} verificationCode
 * @return {Promise}
 */
function verifyUser(username, verificationCode) {
  return new Promise((resolve) => {
    const body = {
      username: username,
      verificationCode: verificationCode,
    };
    fetch(API_ENDPOINT + 'auth/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      });
  });
}

/**
 * Attempt to register a user with the backend, the user must then login.
 * @param {String} organisation
 * @param {String} clientSecret
 * @param {String} username
 * @param {String} password
 * @return {Promise}
 */
function register(organisation, clientSecret, username, password) {
  return new Promise((resolve) => {
    const body = {
      client: organisation,
      client_id: clientSecret,
      username: username,
      password: password,
    };
    fetch(API_ENDPOINT + 'auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      });
  });
}

/**
 * Attempt to register a user with the backend, the user must then login.
 * @param {String} invitationCode
 * @param {String} password
 * @return {Promise}
 */
function registerInvitation(invitationCode, password) {
  return new Promise((resolve) => {
    const body = {
      invitationCode: invitationCode,
      password: password,
    };
    fetch(API_ENDPOINT + 'auth/registerInvitation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      });
  });
}

/**
 * Attempts to reset a Users password by sending their username and organisation
 * to the backend. Should the backend accept the request, a token is emailed
 * to the user for typing in to the application
 * @param {String} username
 * @return {Promise}
 */
function sendResetToken(username) {
  return new Promise((resolve) => {
    const body = {
      username: username,
    };
    fetch(API_ENDPOINT + 'auth/get_reset_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      });
  });
}

/**
 * This is the parent function that actually resets the users password given
 * the right token is provided. The token is fetched by first calling
 * sendResetToken()
 * @param {String} username
 * @param {String} token
 * @param {String} password
 * @return {Promise}
 */
function resetPassword(username, token, password) {
  return new Promise((resolve) => {
    const body = {
      username: username,
      token: token,
      password: password,
    };
    fetch(API_ENDPOINT + 'auth/change_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      });
  });
}

/**
 * Log a user out of the application by popping their token from the session
 * storage.
 */
function logout() {
  sessionStorage.removeItem('bearerToken');
  sessionStorage.removeItem('logoURL');
  sessionStorage.removeItem('username');
  sessionStorage.removeItem('client_name');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('role');
}

/**
 * Sets session storage values based on the token passed in.
 * @param {Object} token The Bearer token for the session.
 * @param {Object} logoUrl The logo URL for the client.
 * @return {Boolean} If the decoding was successful.
 */
function setSessionInformation(token, logoUrl) {
  if (token) {
    try {
      const decoded = jwtDecode(token);
      sessionStorage.setItem('username', decoded['username']);
      sessionStorage.setItem('userId', decoded['user_id']);
      sessionStorage.setItem('role', decoded['role']);
      sessionStorage.setItem('client_name', decoded['client_name']);
      sessionStorage.setItem('clientType', decoded['client_type']);
      sessionStorage.setItem('latest_transaction', decoded['latest_transaction']);
      sessionStorage.setItem('bearerToken', token);
      sessionStorage.setItem('logoURL', logoUrl);
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
}

/**
 * Test if a use is authenticated by decoding the token in session storage.
 * @return {boolean} If the user is currently authenticated
 */
function isAuthenticated() {
  const token = getBearerToken();
  if (token) {
    try {
      const decoded = jwtDecode(token);
      return Date.now() / 1000 < decoded.exp;
    } catch (err) {
      return false;
    }
  }
  return false;
}

/**
 * Gets the Bearer Token from the session storage.
 * @return {String} JWT bearer token
 */
function getBearerToken() {
  return sessionStorage.getItem('bearerToken');
}

/**
 * Gets the logged in user' client name.
 * @return {String} The name of the client.
 */
function getClientName() {
  return sessionStorage.getItem('client_name');
}

/**
 * Gets the logged in user' client name.
 * @return {String} The name of the client.
 */
function getClientType() {
  return sessionStorage.getItem('clientType');
}

/**
 * Gets the data of the latest cohort for the loged in users client.
 * @return {String} The maximum cohort date for this client.
 */
function getLatestTransaction() {
  return sessionStorage.getItem('latest_transaction');
}

/**
 * Gets the logged in users username.
 * @return {String} The username of the logged in user.
 */
function getUsername() {
  return sessionStorage.getItem('username');
}

/**
 * Get the currently logged in users user id
 * @return {string} The users user id.
 */
function getUserId() {
  return sessionStorage.getItem('userId');
}

/**
 * Get the currently logged in users role (Administrator or User)
 * @return {string} The users role
 */
function getRole() {
  return sessionStorage.getItem('role');
}

/**
 * Gets the Client Logo URL from session storage.
 * @return {String} path to the clients logo.
 */
function getLogoURL() {
  return sessionStorage.getItem('logoURL');
}

export {
  verifyUser,
  register,
  registerInvitation,
  getUsername,
  getClientName,
  getUserId,
  getRole,
  getClientType,
  getLatestTransaction,
  sendResetToken,
  resetPassword,
  login,
  logout,
  isAuthenticated,
  setSessionInformation,
  getBearerToken,
  getLogoURL,
};
