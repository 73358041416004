import React, {useState} from 'react';
import './ForecastVersionRow.css';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  ListGroupItem,
} from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * A Data Card  component containing a header, icon, figure and a footer context
 * description. These are primarily used on the homepage.
 * @param {Object} props the data for the bar
 * @return {JSX}
 */
const ForecastVersionRow = (props) => {
  const [isActive, setActive] = useState(false);

  /**
   * Change the active state of this component and pass it's ID back to the
   * parent component.
   */
  function toggleActive() {
    props.toggleActive(props.forecast.versionId);
    setActive(!isActive);
  }

  /**
   * The render function for the component, returns the Data Card for the given
   * properties.
   * @return {JSX}
   */
  const {forecast} = props;
  return (
    <ListGroupItem active={isActive}>
      <p><b>Version Name: </b>{forecast.versionName}</p>
      <b>Created: </b>{moment(forecast.createdDate).fromNow()}
      <ButtonGroup className={'rightButton'}>
        <Button outline color="secondary" size="sm"
          href={'/forecast/results?forecast_version_id[]=' +
          forecast.versionId}>
          View
        </Button>
        {isActive ? (
            <Button outline color="secondary" size="sm"
              onClick={() => toggleActive()}>
              Remove from Collection
            </Button> ) : (
            <Button outline color="secondary" size="sm"
              onClick={() => toggleActive()}>
              Add to Collection
            </Button>
        )
        }
        <Button outline color="secondary" size="sm"
          href={'/forecast/new/' +
          forecast.versionId}>
          Modify
        </Button>
        <Button outline color="secondary" size="sm"
          onClick={() => props.handleDelete(forecast.versionId)}>
          Delete
        </Button>
      </ButtonGroup>
    </ListGroupItem>
  );
};
ForecastVersionRow.propTypes = {
  forecast: PropTypes.object,
  toggleActive: PropTypes.func,
  handleDelete: PropTypes.func,
};
export default ForecastVersionRow;
