import React from "react";
import CookieConsentBar from "../../components/CookieConsentBar";

import "./Policies.css";

const Policies = () => {
  return (
    <div>
      <h1 className={"policy"}>GivingInsight Policies</h1>
      <h2 className={"policy"}>Privacy Policy</h2>
      <p className={"policy"}>
        Wherever possible, GivingInsight determines how it
        collects, holds, uses and discloses personal information in accordance
        with the Australian Privacy Principles contained in the{" "}
        <a
          className={"policy"}
          href="https://www.legislation.gov.au/Series/C2004A03712"
        >
          Privacy Act 1998
        </a>
        . If you are an individual in the European Economic Area (EEA), we
        collect and process information about you only where we have legal bases
        for doing so under applicable EU laws. You can read our EEA privacy
        policy{" "}
        <a
          className={"policy"}
          href="https://altis.com.au/privacy-policy-eea/Policies.jsx"
        >
          here
        </a>
        .
      </p>
      <p className={"policy"}>
        Your privacy matters to us. This privacy statement sets out the basis on
        which we may use and process the information that you freely provide to
        us and consent for us to use. By using this website, you agree to this
        privacy statement. If you do not agree with this privacy statement,
        please do not use our website. We may change or modify this privacy
        statement at any time and without notice. Please check this page
        periodically to access our most up to date privacy statement.
      </p>
      <h3 className={"policy"}>Links to other websites</h3>
      <p className={"policy"}>
        Our website may, from time to time, contain links to other websites. If
        you follow a link to any of these websites, please note that we do not
        log visitors&apos; clicks from our website to them. Also, these websites
        have their own privacy statement, terms and conditions and we do not
        accept any responsibility or liability for these websites or the
        information you provide to them.
      </p>
      <h3 className={"policy"}>Information gathering</h3>
      <p className={"policy"}>
        We only collect information where you make it available to us, you may
        do this by allowing us to place cookies on your browser or hard drive,
        by accessing server logs or when you contact us through the information
        available on our website.
      </p>
      <h3 className={"policy"}>Cookies and web tracking tools</h3>
      <p className={"policy"}>
        Our website uses cookie to enhance the user experience. For more
        information on the cookie we are using and how to configure your browser
        if you do not wish to be tracked, please read{" "}
        <a
          className={"policy"}
          href={
            "https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information"
          }
        >
          here
        </a>
        .
      </p>
      <h3 className={"policy"}>Logs</h3>
      <p className={"policy"}>
        Our website captures any user browsing data such as login timestamp,
        which page they were browsing to improve our application user experience
        and schedule downtime maintenance that will cause the least impact to
        our users.
      </p>
      <h3 className={"policy"}>Emails from you to us</h3>
      <p className={"policy"}>
        When you email us to our service desk email address as mentioned in the
        customer service sections, we may keep a record of that correspondence,
        your e-mail address, and/or any other data submitted by means of such a
        message.
      </p>
      <h3 className={"policy"}>Payments</h3>
      <p className={"policy"}>
        There is no financial transaction processing on our site. We do not
        store credit card details nor do we share financial details with any 3rd
        parties.
      </p>
      <h3 className={"policy"}>Disclosure of information</h3>
      <p className={"policy"}>
        We hold the information you provide to us securely and in confidence. We
        will only disclose information to unrelated third parties without your
        consent if we are under a duty to disclose or share such information in
        order to comply with any legal obligation, or on the request of a legal
        or regulatory authority or in order to enforce or apply our Terms and
        Conditions. We do not sell or otherwise distribute or share any of your
        personal information, unless directed under a Duty of Disclosure
        requirement.
      </p>
      <h3 className={"policy"}>Your rights</h3>
      <p className={"policy"}>
        You have the right to access information held about you and you are free
        to exercise this right at any time. Any access request may be subject to
        a nominal fee to meet our costs in providing you with details of the
        information we hold about you.
      </p>
      <h3 className={"policy"}>Customer Service</h3>
      <p className={"policy"}>
        If you have any questions regarding our Terms of Use, please email us
        at:{" "}
        <a
          className={"policy"}
          href="mailto:info@givinginsight.com"
        >
          info@givinginsight.com
        </a>
      </p>
      <CookieConsentBar />
    </div>
  );
};

export default Policies;
